import {
  faArrowRight,
  faCircleNotch,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  ContentHeader,
  CustomInput,
  Dropdown,
  FontIcon,
  Loader,
  MultiSelect,
  NoData,
  Pagination,
  Search,
  SelectStoreModal,
  Status,
  Table,
  TableBody,
  TableHeader,
} from "components";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { updatePageNo } from "store/commonSlice";
import { FilterCreditNotes, PrintCreditNote } from "store/POS";
import {
  BUTTON_CONSTANTS,
  CREDIT_NOTE_CONSTANTS,
  displayValue,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  ORDER_CONSTANTS,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  POS_HEADER_CONSTANTS,
  ScrollToTop,
  STATUSES,
  to2Decimal,
  Toast,
  TOAST_CONSTANTS,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";
import {
  clearSelectedStore,
  getBranches,
} from "store/ManageBranches/branchSlice";

const CreditNoteList = ({ modal }: { modal: boolean }) => {
  const dispatch = useAppDispatch();

  const {
    branch: { branch: stores, selectedStore },
    common: {
      current_page,
      total_items,
      total_pages,
      page_size,
      status: commonStatus,
    },
    creditNote: { creditNote: creditNoteData, status: creditNoteStatus },
  } = useAppSelector((state) => state.root);

  const [creditNoteQuery, setCreditNoteQuery] = useState("");

  const [print, setPrint] = useState<number>();

  const [storeModal, setStoreModal] = useState<boolean>(false);

  const dropdownItems = [
    {
      path: `#`,
      icon: faArrowRight,
      label: POS_HEADER_CONSTANTS.CHANGE_STORE,
      dropdownClickHandler: () => {
        dispatch(clearSelectedStore());
        setStoreModal(true);
      },
      reverse: true,
    },
  ];

  const store_code = selectedStore
    ? stores.find((store) => store.id === selectedStore).store_code
    : null;

  const PropsRef = useRef<
    FormikProps<{
      credit_note_type: {
        value: string;
        label: string;
      }[];
      start_date: string;
      end_date: string;
    }>
  >();

  const initialValues = {
    credit_note_type: [CREDIT_NOTE_CONSTANTS.CREDIT_NOTE_TYPE_OPTIONS[0]],
    start_date: moment().subtract(1, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  };

  const update_page = useCallback(
    ({
      pageNo = current_page,
      query = creditNoteQuery,
      store = selectedStore,
      start_date = PropsRef.current?.values.start_date,
      end_date = PropsRef.current?.values.end_date,
      credit_note_type = PropsRef.current?.values?.credit_note_type?.[0]?.value,
    }: {
      pageNo: number;
      query?: string;
      store?: number;
      start_date?: string;
      end_date?: string;
      credit_note_type?: string;
    }) => {
      const filterOptions = {
        pageNo,
        query,
        store: selectedStore || store,
        type: credit_note_type,
        list: true,
      };

      start_date && (filterOptions[ORDER_CONSTANTS.START_DATE] = start_date);
      end_date && (filterOptions[ORDER_CONSTANTS.END_DATE] = end_date);

      if (selectedStore || store) {
        if (query) {
          if (
            query.includes("CRN") &&
            !query?.includes(
              stores.find((store) => store.id === selectedStore)?.store_code
            )
          ) {
            Toast(
              POS_HEADER_CONSTANTS.INVALID_STORE_CODE_MESSAGE,
              TOAST_CONSTANTS.ERROR
            );
            return;
          }
          filterOptions["query"] = query;
        }
        dispatch(FilterCreditNotes(filterOptions));
      }
    },
    [
      dispatch,
      creditNoteQuery,
      selectedStore,
      PropsRef.current?.values,
      current_page,
    ]
  );

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query: creditNoteQuery,
  });

  useEffect(() => {
    ScrollToTop();
    dispatch(getBranches({ active: true, edit: { setStoreModal } }));
    return () => {
      resetState();
    };
  }, [dispatch]);

  return (
    <div className={`${!modal ? "content-wrapper" : ""} text-sm`}>
      {!selectedStore && (
        <SelectStoreModal
          modal={storeModal}
          setModal={setStoreModal}
          update={(value) => {
            // update the page with the selected store
            update_page({
              pageNo: 1,
              store: value,
              query: creditNoteQuery,
              start_date: PropsRef.current?.values.start_date,
              end_date: PropsRef.current?.values.end_date,
              credit_note_type:
                PropsRef.current?.values?.credit_note_type?.[0]?.value,
            });
          }}
        />
      )}
      <div className="col-12 d-flex flex-column">
        <div className="row">
          <Container>
            {!modal && (
              <ContentHeader pageHeader={CREDIT_NOTE_CONSTANTS.HEADER} />
            )}
            <Card cardClassNames="p-0">
              <CardHeader>
                <Formik initialValues={initialValues} onSubmit={() => {}}>
                  {(props) => {
                    PropsRef.current = props;
                    return (
                      <Form>
                        <div className="row mb-2">
                          <div className={`col-2`}>
                            <MultiSelect
                              name={CREDIT_NOTE_CONSTANTS.CREDIT_NOTE_TYPE}
                              options={
                                CREDIT_NOTE_CONSTANTS.CREDIT_NOTE_TYPE_OPTIONS
                              }
                              select={false}
                              label={
                                CREDIT_NOTE_CONSTANTS.CREDIT_NOTE_TYPE_LABEL
                              }
                              onChangeHandler={(e, actions) => {
                                if (actions.action === "clear") {
                                  props.setFieldValue(
                                    CREDIT_NOTE_CONSTANTS.CREDIT_NOTE_TYPE,
                                    CREDIT_NOTE_CONSTANTS
                                      .CREDIT_NOTE_TYPE_OPTIONS[0]
                                  );
                                }
                                props.setFieldValue(
                                  CREDIT_NOTE_CONSTANTS.CREDIT_NOTE_TYPE,
                                  [e]
                                );
                                dispatch(updatePageNo(1));
                                e &&
                                  e.value &&
                                  update_page({
                                    pageNo: 1,
                                    query: creditNoteQuery,
                                    start_date: props.values.start_date,
                                    end_date: props.values.end_date,
                                    credit_note_type: e?.value,
                                  });
                              }}
                            />
                          </div>
                          <div className={`col-2`}>
                            <CustomInput
                              label={ORDER_CONSTANTS.START_DATE_LABEL}
                              // isRequired={true}
                              name={ORDER_CONSTANTS.START_DATE}
                              type={FORM_CONSTANTS.DATE}
                              max={
                                props?.values?.end_date ||
                                new Date().toISOString().split("T")[0]
                              }
                              onChangeHandler={(e) => {
                                props.setFieldValue(
                                  ORDER_CONSTANTS.START_DATE,
                                  e.target.value
                                );
                                dispatch(updatePageNo(1));
                                update_page({
                                  pageNo: 1,
                                  query: creditNoteQuery,
                                  start_date: e.target.value,
                                  end_date: props.values.end_date,
                                  credit_note_type:
                                    props.values?.credit_note_type?.[0]?.value,
                                });
                              }}
                            />
                          </div>
                          <div className={`col-2`}>
                            <CustomInput
                              label={ORDER_CONSTANTS.END_DATE_LABEL}
                              // isRequired={true}
                              name={ORDER_CONSTANTS.END_DATE}
                              type={FORM_CONSTANTS.DATE}
                              min={props?.values?.start_date}
                              max={new Date().toISOString().split("T")[0]}
                              onChangeHandler={(e) => {
                                props.setFieldValue(
                                  ORDER_CONSTANTS.END_DATE,
                                  e.target.value
                                );
                                dispatch(updatePageNo(1));
                                update_page({
                                  pageNo: 1,
                                  query: creditNoteQuery,
                                  start_date: props.values.start_date,
                                  end_date: e.target.value,
                                  credit_note_type:
                                    props.values?.credit_note_type?.[0]?.value,
                                });
                              }}
                            />
                          </div>
                          <div
                            className={`col-6 d-flex flex-row align-items-center mt-${
                              modal ? "4 pt-1" : "1"
                            }`}
                          >
                            {modal ? (
                              <Search
                                className="py-2"
                                placeholder="Search by Credit Note Number / Customer"
                                setQuery={setCreditNoteQuery}
                                searchByQuery={(value) => {
                                  update_page({
                                    pageNo: 1,
                                    query: value,
                                    start_date: props.values.start_date,
                                    end_date: props.values.end_date,
                                    credit_note_type:
                                      props.values?.credit_note_type?.[0]
                                        ?.value,
                                  });
                                }}
                              />
                            ) : (
                              <>
                                <Search
                                  className="py-4 mt-1"
                                  placeholder="Search by Credit Note Code / Customer Name"
                                  setQuery={setCreditNoteQuery}
                                  searchByQuery={(value) => {
                                    update_page({
                                      pageNo: 1,
                                      query: value,
                                      start_date: props.values.start_date,
                                      end_date: props.values.end_date,
                                      credit_note_type:
                                        props.values?.credit_note_type?.[0]
                                          ?.value,
                                    });
                                  }}
                                />
                                <Dropdown
                                  items={dropdownItems}
                                  dropdownBtnClassNames="btn-dark py-3"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                {/* <div className="col-6 offset-6">
                  <Search placeholder="Search ..." setQuery={setQuery} />
                </div> */}
              </CardHeader>
              <CardBody>
                <Table>
                  <TableHeader cols={CREDIT_NOTE_CONSTANTS.TABLE_COLS} />
                  <TableBody>
                    {creditNoteStatus === STATUSES.LOADING ? (
                      <tr>
                        <td
                          colSpan={CREDIT_NOTE_CONSTANTS.TABLE_COLS?.length}
                          className="text-center"
                        >
                          <Loader />
                        </td>
                      </tr>
                    ) : creditNoteData.length > 0 ? (
                      creditNoteData.map((creditNote: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>
                              {page_size * (current_page - 1) + index + 1}
                            </td>
                            <td>{creditNote?.credit_note_code}</td>
                            <td>{creditNote?.customer_name}</td>
                            <td>
                              {POS_CONSTANTS.RUPEE_SIGN}
                              {displayValue(
                                to2Decimal(creditNote?.refund_amount)
                              )}
                            </td>
                            <td>
                              {moment(creditNote?.created_at).format(
                                GENERAL_CONSTANTS.DATE_FORMAT
                              )}
                            </td>
                            <td>
                              <Status is_active={creditNote?.is_active} />
                            </td>
                            <td>
                              <Button
                                text={
                                  commonStatus.type ===
                                    POS_CART_CONSTANTS.PRINT_CREDIT_NOTE_LOADING_STATE &&
                                  commonStatus.state === STATUSES.LOADING &&
                                  print === index ? (
                                    <FontIcon
                                      icon={faCircleNotch}
                                      iconClassNames={
                                        "fa-solid fa-circle-notch fa-spin"
                                      }
                                    />
                                  ) : (
                                    <FontIcon icon={faPrint} />
                                  )
                                }
                                type={BUTTON_CONSTANTS.BUTTON}
                                isDisabled={
                                  commonStatus.type ===
                                    POS_CART_CONSTANTS.PRINT_CREDIT_NOTE_LOADING_STATE &&
                                  commonStatus.state === STATUSES.LOADING
                                }
                                btnClassNames="btn btn-info btn-sm ml-2"
                                onClickHandler={() => {
                                  setPrint(index);
                                  dispatch(
                                    PrintCreditNote(Number(creditNote.id))
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoData len={CREDIT_NOTE_CONSTANTS.TABLE_COLS.length} />
                    )}
                  </TableBody>
                </Table>
              </CardBody>
              <CardFooter>
                <Pagination
                  pageSize={page_size}
                  totalItems={total_items}
                  totalPages={total_pages}
                  currentPage={current_page}
                  prevClickHandler={prevClickHandler}
                  nextClickHandler={nextClickHandler}
                  pageChangeHandler={pageChangeHandler}
                />
              </CardFooter>
            </Card>
          </Container>
        </div>
      </div>
    </div>
  );
};

export { CreditNoteList };
