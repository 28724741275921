import React, { useEffect, useRef, useState } from "react";

import {
  ACCESS_CONSTANTS,
  Toast,
  TOAST_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";

import {
  BillDetails,
  POSCart,
  POSHeader,
  POSSidebar,
  SelectStoreModal,
} from "components";
import { getBranches } from "store/ManageBranches/branchSlice";
import { resetProductState } from "store/ManageProducts/productSlice";
import { ResetWholeCart } from "store/POS";
import { useLocation } from "react-router-dom";
import { resetMasterPasswordVerifed } from "store/commonSlice";

/**
 * The POS component represents the Point of Sale screen.
 * It displays the main POS screen, including the cart, product search, bill details, and other options.
 * It also handles store selection, cart clearing, and event listeners.
 */

const POS = () => {
  const {
    cart: { cart },
    common: { master_password_verified },
    branch: { selectedStore },
  } = useAppSelector((state) => state.root);

  const dispatch = useAppDispatch();

  const { state: navigationState } = useLocation();

  const [full, setFull] = useState<boolean>(false);

  const [storeModal, setStoreModal] = useState<boolean>(false);

  const [batchModal, setBatchModal] = useState<boolean>(false);

  const [printOptionsModal, setPrintOptionsModal] = useState<boolean>(false);

  const productSearchRef = useRef(null);

  function handleFocus(e: any) {
    if (
      e?.target?.id === "cartItemsTableParent" ||
      (e?.target?.localName === "div" &&
        typeof e.target.className === "string" &&
        e?.target?.className === "col-12")
    ) {
      productSearchRef?.current?.focus();
    }
  }

  useEffect(() => {
    if (navigationState === null && cart.cartItems.length > 0) {
      if (selectedStore !== cart.cartItems[0].store_id) {
        // Toast('Cart cleared since store was changed',TOAST_CONSTANTS.ERROR)
        dispatch(ResetWholeCart());
      }
    }
    addEventListener("click", handleFocus);

    dispatch(
      getBranches({ active: true, edit: { setStoreModal, fetchDetails: true } })
    );

    return () => {
      dispatch(
        resetMasterPasswordVerifed({ state: true, type: "Modify POS Entry" })
      );
      removeEventListener("click", handleFocus);
      dispatch(resetProductState());
    };
  }, []);

  return (
    <div className="content-wrapper row m-0 p-0 text-sm">
      {!selectedStore && (
        <SelectStoreModal modal={storeModal} setModal={setStoreModal} />
      )}
      {/* Main POS Screen */}
      <div
        className={`col-md-${
          window.mobileAndTabletCheck() ? "12" : "10"
        } d-flex flex-column`}
      >
        <div className="row">
          {/* Scan || Search by Product Code || Customer Input ||  Returns Scan Bill */}
          <div className="col-12" data-testid={"pos-header"}>
            <POSHeader
              focusRef={productSearchRef}
              batchModal={batchModal}
              setBatchModal={setBatchModal}
            />
          </div>
        </div>
        <div
          className="row overflow-y-scroll"
          style={{
            height: window.mobileAndTabletCheck()
              ? "auto"
              : `calc(100vh - 340px)`,
          }}
        >
          {/* CartItems Table */}
          <div
            data-testid={"pos-cart-table"}
            id="cartItemsTableParent"
            className="col-12"
            // onClick={() => productSearchRef.current.focus()}
          >
            <POSCart
              focusRef={productSearchRef}
              batchModal={batchModal}
              setBatchModal={setBatchModal}
            />
          </div>
        </div>
        <div className="row">
          {/* Bill Details and Other Options */}
          <div className="col-12" data-testid={"pos-bill-details"}>
            <BillDetails
              focusRef={productSearchRef}
              printOptionsModal={printOptionsModal}
              setPrintOptionsModal={setPrintOptionsModal}
            />
          </div>
        </div>
      </div>
      {/* Customer Details and Bill Details */}
      {!window.mobileAndTabletCheck() && (
        <div
          className="col-md-2 d-flex flex-column"
          data-testid={"pos-sidebar"}
        >
          <POSSidebar
            full={full}
            setFull={setFull}
            printOptionsModal={printOptionsModal}
            setPrintOptionsModal={setPrintOptionsModal}
          />
        </div>
      )}
    </div>
  );
};

export { POS };
