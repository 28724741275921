import {
  faAddressBook,
  faBars,
  faBasketShopping,
  faBuildingColumns,
  faCartShopping,
  faChartLine,
  faComment,
  faGrip,
  faHashtag,
  faIndianRupeeSign,
  faLinesLeaning,
  faPager,
  faPercent,
  faQuestion,
  faScaleUnbalanced,
  faShop,
  faSliders,
  faStore,
  faTachometerAlt,
  faToolbox,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import {
  AdvanceOrderDetail,
  AdvanceOrderList,
  Banks,
  BanksForm,
  BarcodeUtility,
  BranchForm,
  Branches,
  CMS_Dashboard,
  Cashier,
  CashierSearch,
  CreditNoteList,
  Customers,
  CustomersForm,
  Dashboard,
  DiscountForm,
  Discounts,
  Ecommerce_Category,
  Ecommerce_CategoryForm,
  Ecommerce_Dashboard,
  Ecommerce_OrderDetails,
  Ecommerce_Orders,
  Ecommerce_Products,
  Ecommerce_ProductsForm,
  Ecommerce_SubCategory,
  Ecommerce_SubCategoryForm,
  EmployeeForm,
  Employees,
  Error404,
  FAQForm,
  FAQs,
  HSNCodes,
  KPIReport,
  Label,
  Login,
  Menu,
  MenuForm,
  MultiplePay,
  OrderDetails,
  OrderPreview,
  OrdersList,
  POS,
  PageLoader,
  Pages,
  PagesForm,
  PaymentModes,
  PaymentTerms,
  Printer,
  ProductBrand,
  ProductCategory,
  ProductGroups,
  ProductSubCategory,
  ProductTax,
  Products,
  ProductsForm,
  ProductsGroupsForm,
  Roles,
  RolesForm,
  Sales,
  SiteManagement,
  Tastes,
  Testimonials,
  TestimonialsForm,
  UOM,
  VendorForm,
  Vendors,
  Widgets,
} from "pages";

import { PrivateRoutes } from "./PrivateRoutes";
import {
  CustomRouteProps,
  MENU_CONSTANTS,
  PAGES_CONSTANTS,
  ROLE_CONSTANTS,
  ROUTES,
  ROUTES_CONSTANTS,
} from "utils";
import { Navigate, createBrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "components";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

export const generatePermissionRoutes = (routePermissions) => {
  const EcommercePermission = {
    id: 0,
    module_name: "Ecommerce",
    perm_view: true,
    perm_add: true,
    perm_edit: true,
    perm_delete: true,
    perm_authorize: true,
  };

  const CMSPermissions =
    routePermissions?.find((p) => p.module_name === "Manage Category CMS")
      ?.perm_view ||
    routePermissions?.find((p) => p.module_name === "Manage Sub Category CMS")
      ?.perm_view ||
    routePermissions?.find((p) => p.module_name === "Manage Product CMS")
      ?.perm_view
      ? {
          id: 0,
          module_name: "CMS",
          perm_view: true,
          perm_add: true,
          perm_edit: true,
          perm_delete: true,
          perm_authorize: true,
        }
      : {
          id: 0,
          module_name: "CMS",
          perm_view: false,
          perm_add: false,
          perm_edit: false,
          perm_delete: false,
          perm_authorize: false,
        };

  const SalesPermission =
    routePermissions?.find((p) => p.module_name === "Manage Orders") ||
    routePermissions?.find((p) => p.module_name === "Cashier") ||
    routePermissions?.find((p) => p.module_name === "Manage Returns/Refunds")
      ? {
          id: 0,
          module_name: "Point Of sale",
          perm_view: true,
          perm_add: true,
          perm_edit: true,
          perm_delete: true,
          perm_authorize: true,
        }
      : {
          id: 0,
          module_name: "Point Of sale",
          perm_view: false,
          perm_add: false,
          perm_edit: false,
          perm_delete: false,
          perm_authorize: false,
        };

  const POSPermission = routePermissions?.find(
    (p) => p.module_name === "Manage Orders"
  )?.perm_add
    ? {
        id: 0,
        module_name: "Point Of sale",
        perm_view: true,
        perm_add: true,
        perm_edit: true,
        perm_delete: true,
        perm_authorize: true,
      }
    : {
        id: 0,
        module_name: "Point Of sale",
        perm_view: false,
        perm_add: false,
        perm_edit: false,
        perm_delete: false,
        perm_authorize: false,
      };

  const permissionRoute = (routePermissions, route_name, perm, route) => {
    const permission = routePermissions?.find(
      (p) => p.module_name === route_name
    );

    return permission && permission[perm] ? route : <PageLoader />;
  };

  const customRouterObject: CustomRouteProps[] = [
    {
      path: "*",
      element:
        routePermissions.length > 1 ? (
          <Error404 />
        ) : (
          !localStorage.getItem("accessToken") && <Navigate to={ROUTES.LOGIN} />
        ),
    },
    {
      path: ROUTES.LOGIN,
      element: <Login />,
    },
    {
      path: ROUTES.ADMIN,
      element: <PrivateRoutes />,
      children: [
        {
          path: ROUTES.DASHBOARD,
          route_name: "Dashboard",
          icon: faTachometerAlt,
          element: <Dashboard />,
          errorElement: <ErrorBoundary />,
        },
        {
          path: ROUTES.ROLES,
          route_name: "Manage Roles",
          icon: faAddressBook,
          permission: routePermissions?.find(
            (p) => p.module_name === "Manage Roles"
          ),
          errorElement: <ErrorBoundary />,
          children: [
            permissionRoute(
              routePermissions,
              "Manage Roles",
              ROLE_CONSTANTS.VIEW,
              {
                index: true,
                element: <Roles />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Roles",
              ROLE_CONSTANTS.ADD,
              {
                path: `${ROUTES_CONSTANTS.ADD}`,
                element: <RolesForm />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Roles",
              ROLE_CONSTANTS.EDIT,
              {
                path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                element: <RolesForm />,
              }
            ),
          ],
        },
        {
          path: ROUTES.EMPLOYEE,
          route_name: "Manage Employees",
          icon: faUser,
          permission: routePermissions?.find(
            (p) => p.module_name === "Manage Employee"
          ),
          errorElement: <ErrorBoundary />,
          children: [
            permissionRoute(
              routePermissions,
              "Manage Employee",
              ROLE_CONSTANTS.VIEW,
              {
                index: true,
                element: <Employees />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Employee",
              ROLE_CONSTANTS.ADD,
              {
                path: `${ROUTES_CONSTANTS.ADD}`,
                element: <EmployeeForm />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Employee",
              ROLE_CONSTANTS.EDIT,
              {
                path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                element: <EmployeeForm />,
              }
            ),
          ],
        },
        {
          path: ROUTES.BANK,
          route_name: "Manage Banks",
          icon: faBuildingColumns,
          permission: routePermissions?.find(
            (p) => p.module_name === "Manage Banks"
          ),
          errorElement: <ErrorBoundary />,
          children: [
            permissionRoute(
              routePermissions,
              "Manage Banks",
              ROLE_CONSTANTS.VIEW,
              {
                index: true,
                element: <Banks />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Banks",
              ROLE_CONSTANTS.ADD,
              {
                path: `${ROUTES_CONSTANTS.ADD}`,
                element: <BanksForm />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Banks",
              ROLE_CONSTANTS.EDIT,
              {
                path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                element: <BanksForm />,
              }
            ),
          ],
        },
        {
          path: ROUTES.CUSTOMER,
          route_name: "Manage Customers",
          icon: faUsers,
          permission: routePermissions?.find(
            (p) => p.module_name === "Manage Customer"
          ),
          errorElement: <ErrorBoundary />,
          children: [
            permissionRoute(
              routePermissions,
              "Manage Customer",
              ROLE_CONSTANTS.VIEW,
              {
                index: true,
                element: <Customers />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Customer",
              ROLE_CONSTANTS.ADD,
              {
                path: `${ROUTES_CONSTANTS.ADD}`,
                element: <CustomersForm />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Customer",
              ROLE_CONSTANTS.EDIT,
              {
                path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                element: <CustomersForm />,
              }
            ),
          ],
        },
        {
          path: ROUTES.BRANCh,
          route_name: "Manage Branches",
          icon: faShop,
          permission: routePermissions?.find(
            (p) => p.module_name === "Manage Branches/Store"
          ),
          errorElement: <ErrorBoundary />,
          children: [
            permissionRoute(
              routePermissions,
              "Manage Branches/Store",
              ROLE_CONSTANTS.VIEW,
              {
                index: true,
                element: <Branches />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Branches/Store",
              ROLE_CONSTANTS.ADD,
              {
                path: `${ROUTES_CONSTANTS.ADD}`,
                element: <BranchForm />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Branches/Store",
              ROLE_CONSTANTS.EDIT,
              {
                path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                element: <BranchForm />,
              }
            ),
          ],
        },
        {
          path: ROUTES.PRODUCT,
          route_name: "Manage Products",
          icon: faBasketShopping,
          subChildExists: true,
          permission: routePermissions?.find(
            (p) => p.module_name === "Manage Products"
          ),
          errorElement: <ErrorBoundary />,
          children: [
            permissionRoute(
              routePermissions,
              "Manage Products",
              ROLE_CONSTANTS.VIEW,
              {
                index: true,
                element: <Products />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Products",
              ROLE_CONSTANTS.ADD,
              {
                path: `${ROUTES_CONSTANTS.ADD}`,
                element: <ProductsForm />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Products",
              ROLE_CONSTANTS.EDIT,
              {
                path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                element: <ProductsForm />,
              }
            ),
            {
              path: ROUTES.CATEGORY,
              route_name: "Manage Category",
              icon: faCircle,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Product Category"
              ),
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage Product Category")
                ?.map((p) =>
                  p.perm_view ? (
                    <ProductCategory key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
            {
              path: ROUTES.SUBCATEGORY,
              route_name: "Manage Sub Category",
              icon: faCircle,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Product Sub Category"
              ),
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage Product Sub Category")
                ?.map((p) =>
                  p.perm_view ? (
                    <ProductSubCategory key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
            {
              path: ROUTES.BRAND,
              route_name: "Manage Brands",
              icon: faCircle,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Product Brands"
              ),
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage Product Brands")
                ?.map((p) =>
                  p.perm_view ? (
                    <ProductBrand key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
            {
              path: ROUTES.TAX,
              route_name: "Manage Tax",
              icon: faCircle,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Product Tax"
              ),
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage Product Tax")
                ?.map((p) =>
                  p.perm_view ? (
                    <ProductTax key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
            {
              path: "taste",
              route_name: "Manage Taste",
              icon: faCircle,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Taste"
              ),
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage Taste")
                ?.map((p) =>
                  p.perm_view ? (
                    <Tastes key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
            {
              path: ROUTES.GROUP,
              route_name: "Manage Product Groups",
              icon: faCircle,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Product Groups"
              ),
              children: [
                permissionRoute(
                  routePermissions,
                  "Manage Product Groups",
                  ROLE_CONSTANTS.VIEW,
                  {
                    index: true,
                    element: <ProductGroups />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage Product Groups",
                  ROLE_CONSTANTS.ADD,
                  {
                    path: `${ROUTES_CONSTANTS.ADD}`,
                    element: <ProductsGroupsForm />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage Product Groups",
                  ROLE_CONSTANTS.EDIT,
                  {
                    path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                    element: <ProductsGroupsForm />,
                  }
                ),
              ],
            },
            {
              path: ROUTES.HSNCODE,
              route_name: "Manage HSN Codes",
              icon: faHashtag,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage HSN Codes"
              ),
              errorElement: <ErrorBoundary />,
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage HSN Codes")
                ?.map((p) =>
                  p.perm_view ? (
                    <HSNCodes key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
            {
              path: ROUTES.UOM,
              route_name: "Manage UOM",
              icon: faScaleUnbalanced,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage UOM"
              ),
              errorElement: <ErrorBoundary />,
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage UOM")
                ?.map((p) =>
                  p.perm_view ? (
                    <UOM key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
          ],
        },
        {
          path: ROUTES.DISCOUNT,
          route_name: "Manage Discounts",
          icon: faPercent,
          permission: routePermissions?.find(
            (p) => p.module_name === "Manage Discount"
          ),
          children: [
            permissionRoute(
              routePermissions,
              "Manage Discount",
              ROLE_CONSTANTS.VIEW,
              {
                index: true,
                element: <Discounts />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Discount",
              ROLE_CONSTANTS.ADD,
              {
                path: `${ROUTES_CONSTANTS.ADD}`,
                element: <DiscountForm />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Discount",
              ROLE_CONSTANTS.EDIT,
              {
                path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                element: <DiscountForm />,
              }
            ),
          ],
        },
        {
          path: ROUTES.VENDOR,
          route_name: "Manage Vendors",
          icon: faStore,
          subChildExists: true,
          permission: routePermissions?.find(
            (p) => p.module_name === "Manage Vendor"
          ),
          errorElement: <ErrorBoundary />,
          children: [
            permissionRoute(
              routePermissions,
              "Manage Vendor",
              ROLE_CONSTANTS.VIEW,
              {
                index: true,
                element: <Vendors />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Vendor",
              ROLE_CONSTANTS.ADD,
              {
                path: `${ROUTES_CONSTANTS.ADD}`,
                element: <VendorForm />,
              }
            ),
            permissionRoute(
              routePermissions,
              "Manage Vendor",
              ROLE_CONSTANTS.EDIT,
              {
                path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                element: <VendorForm />,
              }
            ),
            {
              path: ROUTES.PAYMENT_MODE,
              route_name: "Manage Payment Modes",
              icon: faCircle,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Payment Mode"
              ),
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage Payment Mode")
                ?.map((p) =>
                  p.perm_view ? (
                    <PaymentModes key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
            {
              path: ROUTES.PAYMENT_TERM,
              route_name: "Manage Payment Terms",
              icon: faCircle,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Payment Term"
              ),
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage Payment Term")
                ?.map((p) =>
                  p.perm_view ? (
                    <PaymentTerms key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
          ],
        },
        {
          path: ROUTES.SALES,
          route_name: "Sales",
          icon: faIndianRupeeSign,
          permission: SalesPermission,
          errorElement: <ErrorBoundary />,
          subChildExists: true,
          children: [
            {
              index: true,
              // element: <Sales  />,
              element: POSPermission?.perm_add ? (
                <Navigate to={ROUTES.POS} />
              ) : routePermissions?.find((p) => p.module_name === "Cashier")
                  ?.perm_view ? (
                <Navigate to={ROUTES.CASHIER} />
              ) : routePermissions?.find(
                  (p) => p.module_name === "Manage Returns/Refunds"
                )?.perm_view ? (
                <Navigate to={ROUTES.ADVANCE} />
              ) : (
                <Sales />
              ),
            },
            permissionRoute(
              routePermissions,
              "Manage Orders",
              ROLE_CONSTANTS.ADD,
              {
                path: ROUTES.POS,
                icon: faCircle,
                route_name: "POS",
                element: <POS />,
                permission: POSPermission,
              }
            ),
            {
              path: ROUTES.CASHIER,
              icon: faCircle,
              route_name: "Cashier",
              permission: routePermissions?.find(
                (p) => p.module_name === "Cashier"
              ),
              errorElement: <ErrorBoundary />,
              children: [
                permissionRoute(
                  routePermissions,
                  "Cashier",
                  ROLE_CONSTANTS.VIEW,
                  {
                    index: true,
                    element: <CashierSearch />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Cashier",
                  ROLE_CONSTANTS.VIEW,
                  {
                    path: ROUTES.PENDINGORDER,
                    element: <Cashier />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Cashier",
                  ROLE_CONSTANTS.EDIT,
                  {
                    path: `${ROUTES.PENDINGORDER}/${ROUTES.ORDER}/:${ROUTES_CONSTANTS.ID}`,
                    element: <MultiplePay />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Cashier",
                  ROLE_CONSTANTS.EDIT,
                  {
                    path: `${ROUTES.PENDINGORDER}/${ROUTES.ADVANCE}/:${ROUTES_CONSTANTS.ID}`,
                    element: <MultiplePay />,
                  }
                ),
              ],
            },
            {
              path: ROUTES.ORDER,
              icon: faCircle,
              route_name: "Orders",
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Orders"
              ),
              children: [
                permissionRoute(
                  routePermissions,
                  "Manage Orders",
                  ROLE_CONSTANTS.VIEW,
                  {
                    index: true,
                    element: <OrdersList modal={false} />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage Orders",
                  ROLE_CONSTANTS.EDIT,
                  {
                    path: ":id",
                    route_name: "Order Details",
                    element: <OrderDetails />,
                  }
                ),
              ],
            },
            {
              path: ROUTES.ADVANCE,
              icon: faCircle,
              route_name: "Advance Orders",
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Returns/Refunds"
              ),
              children: [
                permissionRoute(
                  routePermissions,
                  "Manage Returns/Refunds",
                  ROLE_CONSTANTS.VIEW,
                  { index: true, element: <AdvanceOrderList /> }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage Returns/Refunds",
                  ROLE_CONSTANTS.EDIT,
                  {
                    path: ":id",
                    route_name: "Advance Details",
                    element: <AdvanceOrderDetail />,
                  }
                ),
              ],
            },
            {
              path: ROUTES.CREDITNOTE,
              icon: faCircle,
              route_name: "Credit Notes",
              element: <CreditNoteList modal={false} />,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Returns/Refunds"
              ),
            },
          ],
        },
        {
          path: ROUTES.REPORT,
          route_name: "Reports",
          icon: faChartLine,
          permission: routePermissions?.find(
            (p) => p.module_name === "Manage Reports"
          ),
          errorElement: <ErrorBoundary />,
          subChildExists: true,
          children: [
            permissionRoute(
              routePermissions,
              "Manage Reports",
              ROLE_CONSTANTS.VIEW,
              {
                index: true,
                element: <Navigate to={ROUTES.KPI} />,
              }
            ),
            {
              path: ROUTES.KPI,
              icon: faCircle,
              route_name: "KPI",
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Reports"
              ),
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage Reports")
                ?.map((p) =>
                  p.perm_view ? (
                    <KPIReport key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
          ],
        },
        {
          path: ROUTES.UTILITIES,
          route_name: "Utilities",
          icon: faToolbox,
          permission: routePermissions?.find(
            (p) => p.module_name === "Manage Utilities"
          ),
          errorElement: <ErrorBoundary />,
          subChildExists: true,
          children: [
            permissionRoute(
              routePermissions,
              "Manage Utilities",
              ROLE_CONSTANTS.VIEW,
              {
                index: true,
                element: <Navigate to={ROUTES.BARCODE} />,
              }
            ),
            {
              path: ROUTES.LABEL,
              route_name: "Manage Labels",
              icon: faCircle,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Labels"
              ),
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage Labels")
                ?.map((p) =>
                  p.perm_view ? (
                    <Label key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
            {
              path: ROUTES.PRINTER,
              route_name: "Manage Printer",
              icon: faCircle,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Printer"
              ),
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage Printer")
                ?.map((p) =>
                  p.perm_view ? (
                    <Printer key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
            {
              path: ROUTES.BARCODE,
              route_name: "Barcode Utility",
              icon: faCircle,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Barcode Generation"
              ),
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage Barcode Generation")
                ?.map((p) =>
                  p.perm_view ? (
                    <BarcodeUtility key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
          ],
        },
        {
          path: "/admin/ecommerce",
          // route_name: "Online Store",
          // icon: faCartShopping,
          permission: EcommercePermission,
          errorElement: <ErrorBoundary />,
          subChildExists: true,
          ecommerce: true,
          children: [
            {
              index: true,
              element: <Ecommerce_Dashboard />,
            },
            {
              path: "menu",
              icon: faBars,
              route_name: MENU_CONSTANTS.HEADER,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Menu"
              ),
              errorElement: <ErrorBoundary />,
              children: [
                permissionRoute(
                  routePermissions,
                  "Manage Menu",
                  ROLE_CONSTANTS.VIEW,
                  {
                    index: true,
                    element: <Menu />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage Menu",
                  ROLE_CONSTANTS.ADD,
                  {
                    path: `${ROUTES_CONSTANTS.ADD}`,
                    element: <MenuForm />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage Menu",
                  ROLE_CONSTANTS.EDIT,
                  {
                    path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                    element: <MenuForm />,
                  }
                ),
              ],
            },
            {
              path: "orders",
              icon: faCartShopping,
              route_name: "Orders",
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Ecommerce Orders"
              ),
              children: [
                permissionRoute(
                  routePermissions,
                  "Manage Ecommerce Orders",
                  ROLE_CONSTANTS.VIEW,
                  {
                    index: true,
                    element: <Ecommerce_Orders />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage Ecommerce Orders",
                  ROLE_CONSTANTS.ADD,
                  {
                    path: "preview",
                    element: <OrderPreview />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage Ecommerce Orders",
                  ROLE_CONSTANTS.EDIT,
                  {
                    path: ":id",
                    route_name: "Order Details",
                    element: <Ecommerce_OrderDetails />,
                  }
                ),
              ],
            },
            {
              path: "cms",
              route_name: "Product CMS",
              icon: faLinesLeaning,
              permission: CMSPermissions,
              errorElement: <ErrorBoundary />,
              subChildExists: true,
              children: [
                {
                  index: true,
                  element: <CMS_Dashboard />,
                },
                {
                  path: ROUTES.CATEGORY,
                  route_name: "Category",
                  icon: faCircle,
                  permission: routePermissions?.find(
                    (p) => p.module_name === "Manage Category CMS"
                  ),
                  children: [
                    permissionRoute(
                      routePermissions,
                      "Manage Category CMS",
                      ROLE_CONSTANTS.VIEW,
                      {
                        index: true,
                        element: <Ecommerce_Category />,
                      }
                    ),
                    permissionRoute(
                      routePermissions,
                      "Manage Category CMS",
                      ROLE_CONSTANTS.ADD,
                      {
                        path: `${ROUTES_CONSTANTS.ADD}`,
                        element: <Ecommerce_CategoryForm />,
                      }
                    ),
                    permissionRoute(
                      routePermissions,
                      "Manage Category CMS",
                      ROLE_CONSTANTS.EDIT,
                      {
                        path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                        element: <Ecommerce_CategoryForm />,
                      }
                    ),
                  ],
                },
                {
                  path: ROUTES.SUBCATEGORY,
                  route_name: "Sub Category",
                  icon: faCircle,
                  permission: routePermissions?.find(
                    (p) => p.module_name === "Manage Sub Category CMS"
                  ),
                  children: [
                    permissionRoute(
                      routePermissions,
                      "Manage Sub Category CMS",
                      ROLE_CONSTANTS.VIEW,
                      {
                        index: true,
                        element: <Ecommerce_SubCategory />,
                      }
                    ),
                    permissionRoute(
                      routePermissions,
                      "Manage Sub Category CMS",
                      ROLE_CONSTANTS.ADD,
                      {
                        path: `${ROUTES_CONSTANTS.ADD}`,
                        element: <Ecommerce_SubCategoryForm />,
                      }
                    ),
                    permissionRoute(
                      routePermissions,
                      "Manage Sub Category CMS",
                      ROLE_CONSTANTS.EDIT,
                      {
                        path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                        element: <Ecommerce_SubCategoryForm />,
                      }
                    ),
                  ],
                },
                {
                  path: "products",
                  route_name: "Products",
                  icon: faCircle,
                  permission: routePermissions?.find(
                    (p) => p.module_name === "Manage Product CMS"
                  ),
                  children: [
                    permissionRoute(
                      routePermissions,
                      "Manage Product CMS",
                      ROLE_CONSTANTS.VIEW,
                      {
                        index: true,
                        element: <Ecommerce_Products />,
                      }
                    ),
                    permissionRoute(
                      routePermissions,
                      "Manage Product CMS",
                      ROLE_CONSTANTS.ADD,
                      {
                        path: `${ROUTES_CONSTANTS.ADD}`,
                        element: <Ecommerce_ProductsForm />,
                      }
                    ),
                    permissionRoute(
                      routePermissions,
                      "Manage Product CMS",
                      ROLE_CONSTANTS.EDIT,
                      {
                        path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                        element: <Ecommerce_ProductsForm />,
                      }
                    ),
                  ],
                },
              ],
            },
            {
              path: "faq",
              icon: faQuestion,
              route_name: "FAQs",
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage FAQs"
              ),
              children: [
                permissionRoute(
                  routePermissions,
                  "Manage FAQs",
                  ROLE_CONSTANTS.VIEW,
                  {
                    index: true,
                    element: <FAQs />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage FAQs",
                  ROLE_CONSTANTS.ADD,
                  {
                    path: `${ROUTES_CONSTANTS.ADD}`,
                    element: <FAQForm />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage FAQs",
                  ROLE_CONSTANTS.EDIT,
                  {
                    path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                    element: <FAQForm />,
                  }
                ),
              ],
            },
            {
              path: "testimonials",
              icon: faComment,
              route_name: "Testimonials",
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Testimonials"
              ),
              children: [
                permissionRoute(
                  routePermissions,
                  "Manage Testimonials",
                  ROLE_CONSTANTS.VIEW,
                  {
                    index: true,
                    element: <Testimonials />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage Testimonials",
                  ROLE_CONSTANTS.ADD,
                  {
                    path: `${ROUTES_CONSTANTS.ADD}`,
                    element: <TestimonialsForm />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage Testimonials",
                  ROLE_CONSTANTS.EDIT,
                  {
                    path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                    element: <TestimonialsForm />,
                  }
                ),
              ],
            },
            // {
            //   path: "email-template",
            //   icon: faEnvelopeOpenText,
            //   route_name: "Email Templates",
            //   permission: routePermissions?.find(
            //     (p) => p.module_name === "Manage Email Templates"
            //   ),
            //   children: [
            //     permissionRoute(
            //       routePermissions,
            //       "Manage Email Templates",
            //       ROLE_CONSTANTS.VIEW,
            //       {
            //         index: true,
            //         element: <EmailTemplates />,
            //       }
            //     ),
            //     permissionRoute(
            //       routePermissions,
            //       "Manage Email Templates",
            //       ROLE_CONSTANTS.ADD,
            //       {
            //         path: `${ROUTES_CONSTANTS.ADD}`,
            //         element: <EmailTemplateForm />,
            //       }
            //     ),
            //     permissionRoute(
            //       routePermissions,
            //       "Manage Email Templates",
            //       ROLE_CONSTANTS.EDIT,
            //       {
            //         path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
            //         element: <EmailTemplateForm />,
            //       }
            //     ),
            //   ],
            // },
            {
              path: "widgets",
              route_name: "Widgets",
              icon: faGrip,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Widgets"
              ),
              element: routePermissions
                ?.filter((p) => p.module_name === "Manage Widgets")
                ?.map((p) =>
                  p.perm_view ? (
                    <Widgets key={p.module_name} />
                  ) : (
                    <PageLoader key={p.module_name} />
                  )
                ),
            },
            {
              path: "pages",
              icon: faPager,
              route_name: PAGES_CONSTANTS.HEADER,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Pages"
              ),
              children: [
                permissionRoute(
                  routePermissions,
                  "Manage Pages",
                  ROLE_CONSTANTS.EDIT,
                  {
                    index: true,
                    element: <Pages />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage Pages",
                  ROLE_CONSTANTS.EDIT,
                  {
                    path: `${ROUTES_CONSTANTS.ADD}`,
                    element: <PagesForm />,
                  }
                ),
                permissionRoute(
                  routePermissions,
                  "Manage Pages",
                  ROLE_CONSTANTS.EDIT,
                  {
                    path: `${ROUTES_CONSTANTS.EDIT}/:${ROUTES_CONSTANTS.ID}`,
                    element: <PagesForm />,
                  }
                ),
              ],
            },
            {
              path: "site-management",
              icon: faSliders,
              route_name: "Site Management",
              element: <SiteManagement />,
              permission: routePermissions?.find(
                (p) => p.module_name === "Manage Site Management"
              ),
            },
          ],
        },
      ],
    },
  ];

  const customRoutes = createBrowserRouter(customRouterObject, {
    basename: "/",
    future: {
      v7_normalizeFormMethod: true,
    },
  });

  return {
    customRouterObject,
    customRoutes,
  };
};
