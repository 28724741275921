import { createSlice } from "@reduxjs/toolkit";
import {
  addCreditNotePayments,
  delCreditNote,
  delCreditNotePayments,
  filterCreditNotes,
  getCreditNotes,
  getIndividualCreditNote,
  printCreditNote,
  setCreditNote,
} from "apis/restApis";
import { FormikHelpers } from "formik";
import {
  resetMasterPasswordVerifed,
  setCommonStatus,
  setControllerState,
  updateValues,
} from "store/commonSlice";
import { AppDispatch, RootState } from "store/store";
import {
  POSAdvance,
  POSCreditNote,
  POS_CART_CONSTANTS,
  STATUSES,
  TOAST_CONSTANTS,
  Toast,
  base64ToArrayBuffer,
  calculateAllValues,
  printBill,
} from "utils";
import { ResetWholeCart, setReturn } from "./cartSlice";
import { NavigateFunction } from "react-router-dom";
import { setCreditNote as setCartCreditNote } from "store/POS/cartSlice";
import { FetchCurrentOrderDetails } from "./orderSlice";

const initialState = {
  creditNote: [] as POSCreditNote[] | POSAdvance[],
  currentCreditNote: {} as POSAdvance,
  status: STATUSES.IDLE as string,
  error: null as any,
};

const creditNoteSlice = createSlice({
  name: "creditNote",
  initialState,
  reducers: {
    getAllCreditNote: (state, action) => {
      state.creditNote = action.payload;
    },
    addCreditNote: (state, action) => {
      state.creditNote.push(action.payload);
    },
    setcurrentCreditNote: (state, action) => {
      state.currentCreditNote = action.payload;
    },
    setCreditStatus: (state, action) => {
      state.status = action.payload;
    },
    setCreditError: (state, action) => {
      state.error = action.payload;
    },
    resetCreditNoteState: (state) => {
      state.creditNote = [] as POSCreditNote[];
      state.status = STATUSES.IDLE;
      state.error = null;
      state.currentCreditNote = {} as POSAdvance;
    },
  },
});

export const {
  getAllCreditNote,
  addCreditNote,
  setcurrentCreditNote,

  setCreditStatus,
  setCreditError,
  resetCreditNoteState,
} = creditNoteSlice.actions;

export default creditNoteSlice.reducer;

export function FetchAllCreditNote(
  // selectedStore: number,
  pageNo = 1,
  query = ""
) {
  return async function FetchAllCreditNotethunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(setCreditStatus(STATUSES.LOADING));
    getCreditNotes(pageNo, query)
      .then((response: any) => {
        dispatch(updateValues(response));
        dispatch(getAllCreditNote(response.result.results));
      })
      .catch((error: any) => {
        dispatch(setCreditError(error.message));
        dispatch(setCreditStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setCreditStatus(STATUSES.IDLE));
      });
  };
}

interface AddNewCreditNoteProps {
  focusRef?: React.MutableRefObject<any>;
  creditNote: any;
  actions?: FormikHelpers<any>;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setRefundItems?: React.Dispatch<React.SetStateAction<any[]>>;
  extra?: boolean;
}

export function AddNewCreditNote({
  focusRef,
  creditNote,
  actions,
  setModal,
  setRefundItems,
  extra = false,
}: AddNewCreditNoteProps) {
  return async function AddNewCreditNotethunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(setCreditStatus(STATUSES.LOADING));
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "addCreditNote" })
    );

    try {
      const response: any = await setCreditNote(creditNote, extra);

      if (!setModal && !actions) {
        dispatch(setReturn(false));
      }
      if (setRefundItems) {
        setRefundItems([]);
      }
      if (
        creditNote.credit_type === "return" ||
        creditNote.credit_type === "cash"
      ) {
        dispatch(FetchCurrentOrderDetails(Number(creditNote.order)));
      }
      dispatch(addCreditNote(response.result));
      // dispatch(PrintCreditNote( Number(response.result.id)))

      try {
        const creditNoteResponse = await printCreditNote(
          Number(response.result.id)
        );

        printBill(creditNoteResponse);
        // Toast(`${response.message}`, "success");
        dispatch(
          setCommonStatus({
            state: STATUSES.IDLE,
            type: POS_CART_CONSTANTS.PRINT_CREDIT_NOTE_LOADING_STATE,
          })
        );
      } catch (error) {
        dispatch(setCreditError(error.message));
        dispatch(setCreditStatus(STATUSES.ERROR));
      }

      focusRef && focusRef.current.focus();
      Toast(`${response.message}`, "success");
      actions && actions.resetForm();
      setModal(false);
      dispatch(ResetWholeCart());
      dispatch(
        setCommonStatus({ state: STATUSES.IDLE, type: "addCreditNote" })
      );
      dispatch(setCreditStatus(STATUSES.IDLE));
    } catch (error) {
      dispatch(setCreditError(error.message));
      dispatch(setCreditStatus(STATUSES.ERROR));
    } finally {
      dispatch(resetMasterPasswordVerifed({ state: false, type: "" }));
    }
  };
}

export function FetchCreditNoteDetails(id: number, setCurrentCustomer?: any) {
  return async function FetchCreditNoteDetailsthunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(setCreditStatus(STATUSES.LOADING));
    getIndividualCreditNote(id)
      .then((response: any) => {
        dispatch(setcurrentCreditNote(response.result));
        // dispatch(getIndividualCustomer(Number(response?.customer?.id)));
        setCurrentCustomer && setCurrentCustomer(response.result?.customer?.id);
      })
      .catch((error: any) => {
        dispatch(setCreditError(error.message));
        dispatch(setCreditStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setCreditStatus(STATUSES.IDLE));
      });
  };
}

interface IUpdateCreditNote {
  store: number;
  code?: string;
  query?: string;
  setQuery?: React.Dispatch<React.SetStateAction<string>>;
  type?: string;
  pageNo?: number;
  payment_status?: any;
  navigate?: NavigateFunction;
  cashier?: boolean;
  start_date?: string;
  end_date?: string;
  list?: boolean;
}

export function FilterCreditNotes({
  store,
  code,
  query,
  setQuery,
  type,
  pageNo,
  navigate,
  payment_status,
  cashier,
  start_date,
  end_date,
  list = false,
}: IUpdateCreditNote) {
  return async function FilterCreditNotesthunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    const {
      cart: { cart },
      common: { controller: abortController },
    }: RootState["root"] = getState().root;

    if (abortController) {
      abortController.abort();
    }

    const controller = new AbortController();
    dispatch(setControllerState(controller));

    dispatch(setCreditStatus(STATUSES.LOADING));
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "fetchCreditNotes" })
    );
    filterCreditNotes(
      store,
      code,
      query,
      type,
      pageNo,
      payment_status,
      start_date,
      end_date,
      controller
    )
      .then((response: any) => {
        if (list) {
          dispatch(updateValues(response));
          dispatch(getAllCreditNote(response.result.results));
          dispatch(
            setCommonStatus({ state: STATUSES.IDLE, type: "fetchCreditNotes" })
          );
          dispatch(setCreditStatus(STATUSES.IDLE));
          return;
        }
        if (response.result.credit_type === "advance" && !type) {
          if (
            (response.result.payments && response.result.payments.length > 0) ||
            response.result.refund_amount === 0
          ) {
            dispatch(
              setCartCreditNote({
                creditNote: [
                  {
                    ...response.result,
                    refund_amount: response.result.refund_amount,
                    value: response.result.id,
                    label: response.result.credit_note_code,
                  },
                ],
                applyCredit: response.result.refund_amount,
                // applyCredit: 0,
              })
            );
            // dispatch(setAdvance(true));

            // dispatch(SetAdvanceOrder(response.result));
            Toast(`${response.message}`, "success");
          } else {
            Toast(
              `Please pay first to redeem this credit note`,
              TOAST_CONSTANTS.ERROR
            );
          }
        } else if (
          response.result.credit_type === "advance" &&
          code.length > 0
        ) {
          if (cashier) {
            if (response.result.payments.length > 0) {
              Toast(
                `This advance order has already been paid for`,
                TOAST_CONSTANTS.ERROR
              );
            } else {
              navigate(
                "/admin/sales/cashier/pending-orders/advance/" +
                  response.result.id +
                  "/"
              );
              dispatch(setcurrentCreditNote(response.result));
            }
          } else {
            navigate("/admin/sales/advance/" + response.result.id);
            dispatch(setcurrentCreditNote(response.result));
          }
        } else if (
          response.result.credit_type === "return" ||
          response.result.credit_type === "credit"
        ) {
          if (cart.cartItems.length === 0) {
            Toast(`Please add items to the cart`, TOAST_CONSTANTS.ERROR);
          } else {
            const { final_amount_to_pay } = calculateAllValues(cart);
            dispatch(
              setCartCreditNote({
                creditNote: [
                  {
                    ...response.result,
                    refund_amount: response.result.refund_amount,
                    value: response.result.id,
                    label: response.result.credit_note_code,
                  },
                ],
                applyCredit:
                  response.result.refund_amount > final_amount_to_pay
                    ? final_amount_to_pay
                    : response.result.refund_amount,
              })
            );
          }
        }
        setQuery && setQuery("");
        type && dispatch(updateValues(response));
        type && dispatch(getAllCreditNote(response.result.results));
        dispatch(
          setCommonStatus({ state: STATUSES.IDLE, type: "fetchCreditNotes" })
        );
        dispatch(setCreditStatus(STATUSES.IDLE));
      })
      .catch((error: any) => {
        if (error.message === "canceled") {
          dispatch(setCreditStatus(STATUSES.LOADING));
          return;
        }
        Toast(`${error.message}`, TOAST_CONSTANTS.ERROR);

        dispatch(setCreditError(error.message));
        dispatch(setCreditStatus(STATUSES.ERROR));
      });
  };
}

export function AddCreditNotePayment(
  creditPayments: any,
  navigate: NavigateFunction,
  edit?: boolean
) {
  return async function AddCreditNotePaymentthunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(setCreditStatus(STATUSES.LOADING));
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "addCreditNote" })
    );
    addCreditNotePayments(creditPayments, edit)
      .then((response: any) => {
        navigate(-1);
        Toast(`${response.message}`, "success");
        dispatch(setcurrentCreditNote({} as POSAdvance));
        // dispatch(setCreditStatus(STATUSES.LOADING));
        dispatch(setCreditStatus(STATUSES.IDLE));
        dispatch(
          setCommonStatus({ state: STATUSES.IDLE, type: "addCreditNote" })
        );
      })
      .catch((error: any) => {
        dispatch(setCreditError(error.message));
        dispatch(setCreditStatus(STATUSES.ERROR));
      });
  };
}

export function DeleteCreditNotePayment(id: number) {
  return async function DeleteCreditNotePaymentthunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(setCreditStatus(STATUSES.LOADING));
    delCreditNotePayments(id)
      .then((response: any) => {
        dispatch(FetchCreditNoteDetails(id));
      })
      .catch((error: any) => {
        dispatch(setCreditError(error.message));
        dispatch(setCreditStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setCreditStatus(STATUSES.IDLE));
      });
  };
}

export function DeleteCreditNote(id: number) {
  return async function DeleteCreditNotethunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(setCreditStatus(STATUSES.LOADING));
    delCreditNote(id)
      .then((response: any) => {
        dispatch(FetchAllCreditNote());
      })
      .catch((error: any) => {
        dispatch(setCreditError(error.message));
        dispatch(setCreditStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setCreditStatus(STATUSES.IDLE));
      });
  };
}

export function PrintCreditNote(id: number) {
  return async function PrintCreditNotethunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(
      setCommonStatus({
        state: STATUSES.LOADING,
        type: POS_CART_CONSTANTS.PRINT_CREDIT_NOTE_LOADING_STATE,
      })
    );
    printCreditNote(id)
      .then((response: any) => {
        printBill(response);
        // Toast(`${response.message}`, "success");
        dispatch(
          setCommonStatus({
            state: STATUSES.IDLE,
            type: POS_CART_CONSTANTS.PRINT_CREDIT_NOTE_LOADING_STATE,
          })
        );
      })
      .catch((error: any) => {
        dispatch(setCreditError(error.message));
        dispatch(setCreditStatus(STATUSES.ERROR));
      });
  };
}
