import { Modal } from "components/core-components";
import { CreditNoteList } from "pages";
import React from "react";
import { updatePageNo } from "store/commonSlice";
import { useAppDispatch } from "utils";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreditNoteModal = ({ modal, setModal }: IProps) => {
  const dispatch = useAppDispatch();

  return (
    <Modal
      modalSize="xl"
      centered={false}
      title="Credit Notes"
      modalClassNames="w-100"
      showModal={modal}
      onClickHandler={() => {
        dispatch(updatePageNo(1));
        setModal(false);
      }}
    >
      <CreditNoteList modal={modal} />
    </Modal>
  );
};

export { CreditNoteModal };
