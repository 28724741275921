import React, { useMemo } from "react";

import { ErrorMessage, Field, FieldArray } from "formik";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  FontIcon,
  MultiSelect,
  Table,
  TableBody,
  TableHeader,
} from "components";

import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  PRODUCTS_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { faAdd, faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteProductNutritionMapping } from "store/ManageProducts/productSlice";
import { getUOMs } from "store/uomSlice";
import { FetchNutrients } from "store/ManageProducts/nutrientSlice";

const ProductNutritionDetailsForm = ({ props }: any) => {
  const dispatch = useAppDispatch();

  const {
    nutrients: { nutrients: nutrientData, status: nutrientStatus },
    uom: { uom: uomData, status: uomStatus },
  } = useAppSelector((state) => state.root);

  return (
    <div className="col-md-12 table-responsive">
      <Card>
        <CardHeader>
          <h5 className="card-title">{PRODUCTS_CONSTANTS.CARD_TITLE_2}</h5>
        </CardHeader>
        <CardBody>
          <FieldArray name={PRODUCTS_CONSTANTS.PRODUCT_NUTRITIONS}>
            {({ push, remove }) => (
              <Table>
                <TableHeader cols={PRODUCTS_CONSTANTS.NUTRITION_COLS} />
                <TableBody>
                  {props.values?.nutrition_details &&
                  props.values?.nutrition_details.length > 0
                    ? props.values?.nutrition_details?.map((_, index) => (
                        <tr key={index}>
                          <td className="col-md-4 align-middle">
                            <MultiSelect
                              name={`${PRODUCTS_CONSTANTS.PRODUCT_NUTRITIONS}[${index}].${PRODUCTS_CONSTANTS.NUTRIENT_NAME}`}
                              onFocusHandler={() => {
                                nutrientData.length === 0 &&
                                  dispatch(FetchNutrients(true));
                              }}
                              options={nutrientData
                                .map((item) => ({
                                  value: item.id,
                                  label: item.nutrient_name,
                                }))
                                ?.filter(
                                  (item) =>
                                    !props?.values?.nutrition_details?.some(
                                      (value) =>
                                        value?.nutrient?.[0]?.label ===
                                        item.label
                                    )
                                )}
                              isLoading={nutrientStatus === STATUSES.LOADING}
                            />
                            <ErrorMessage
                              name={`${PRODUCTS_CONSTANTS.PRODUCT_NUTRITIONS}[${index}].${PRODUCTS_CONSTANTS.NUTRIENT_NAME}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </td>
                          <td className="col-md-4 align-middle">
                            <Field
                              as={CustomInput}
                              step={0.001}
                              name={`${PRODUCTS_CONSTANTS.PRODUCT_NUTRITIONS}[${index}].${PRODUCTS_CONSTANTS.NUTRIENT_NUMERIC_VALUE}`}
                              type={FORM_CONSTANTS.NUMBER}
                              placeholder={
                                PRODUCTS_CONSTANTS.NUTRIENT_VALUE_PLACEHOLDER
                              }
                            />
                            <ErrorMessage
                              name={`${PRODUCTS_CONSTANTS.PRODUCT_NUTRITIONS}[${index}].${PRODUCTS_CONSTANTS.NUTRIENT_NUMERIC_VALUE}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </td>
                          <td className="col-md-4 align-middle">
                            <MultiSelect
                              select={false}
                              name={`${PRODUCTS_CONSTANTS.PRODUCT_NUTRITIONS}[${index}].${PRODUCTS_CONSTANTS.NUTRIENT_UOM}`}
                              onFocusHandler={() => {
                                uomData.length === 0 && dispatch(getUOMs(true));
                              }}
                              options={uomData.map((item) => ({
                                value: item.id,
                                label: item.uom_name,
                              }))}
                              isLoading={uomStatus === STATUSES.LOADING}
                            />
                            <ErrorMessage
                              name={`${PRODUCTS_CONSTANTS.PRODUCT_NUTRITIONS}[${index}].${PRODUCTS_CONSTANTS.NUTRIENT_UOM}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </td>
                          <td className="col-md-2 align-middle">
                            {nutrientData?.length - 1 !== index ? (
                              <Button
                                btnClassNames={
                                  "btn btn-secondary table-button mr-3"
                                }
                                text={<FontIcon icon={faCopy} />}
                                isDisabled={
                                  props.values?.nutrition_details?.[index]
                                    .uom[0] === undefined
                                }
                                type={BUTTON_CONSTANTS.BUTTON}
                                onClickHandler={() => {
                                  const newRow = {
                                    nutrient: "",
                                    nutrition_numeric_value: 0,
                                    uom: [],
                                    new: true,
                                  };

                                  newRow.nutrition_numeric_value =
                                    props.values?.nutrition_details?.[
                                      index
                                    ]?.nutrition_numeric_value;

                                  newRow.uom = [
                                    {
                                      value:
                                        props.values?.nutrition_details?.[index]
                                          .uom[0].value,
                                      label:
                                        props.values?.nutrition_details?.[index]
                                          .uom[0].label,
                                    },
                                  ];

                                  push(newRow);
                                }}
                              />
                            ) : null}

                            <Button
                              text={<FontIcon icon={faTrash} />}
                              type={BUTTON_CONSTANTS.BUTTON}
                              isDisabled={
                                index === 0 &&
                                props.values?.nutrition_details?.length === 1
                              }
                              btnClassNames={"btn btn-danger table-button mr-3"}
                              onClickHandler={() => {
                                remove(index);
                                if (
                                  !props.values?.nutrition_details?.[index].new
                                ) {
                                  dispatch(
                                    deleteProductNutritionMapping(
                                      props.values?.nutrition_details?.[index]
                                        .id
                                    )
                                  );
                                }
                              }}
                            />
                            {props.values?.nutrition_details?.length - 1 ===
                              index && nutrientData?.length - 1 !== index ? (
                              <Button
                                text={<FontIcon icon={faAdd} />}
                                type={BUTTON_CONSTANTS.BUTTON}
                                btnClassNames={"btn btn-primary table-button"}
                                onClickHandler={() =>
                                  push({
                                    nutrient: "",
                                    nutrition_numeric_value: 0,
                                    uom: "",
                                    new: true,
                                  })
                                }
                              />
                            ) : null}
                          </td>
                        </tr>
                      ))
                    : null}
                </TableBody>
              </Table>
            )}
          </FieldArray>
        </CardBody>
      </Card>
    </div>
  );
};

export { ProductNutritionDetailsForm };
