import {
  ILabelState,
  IOrder,
  IPrinterState,
  setupInterceptorsTo,
  TGetParams,
} from "utils";
import { REQUEST_CONTENT_TYPE, REQUEST_METHOD } from "utils/constants";
import { makeApiPublicRequest, makeApiRequest } from "utils/helper";
import {
  EcommerceAddress,
  TGetAuthorizedEmployee,
  TVerifyMasterPasswordOTP,
} from "../utils/types";

//////////////////////////////////////////////////////////////////////////////////
// User Authentication

//For user authentication
export const userAuth = async (requestBodyParam: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/account/login/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = requestBodyParam;

  // Make a request
  return makeApiPublicRequest(requestURL, requestMethod, requestBody);
};

//Refresh expired tokens
export const refreshAuthToken = async (requestBodyParam: {
  refresh_token: string;
}) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/account/user/refresh/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = requestBodyParam;

  // Make a request
  return makeApiPublicRequest(requestURL, requestMethod, requestBody);
};

export const userPermissions = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/account/user/permissions/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Roles
//update and delete remaining

//Fetches all the Role from server
export const getRole = async (url: string) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + url;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Add the Role to server
export const setRole = async (roleParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/permissions/user/roles/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = roleParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Role from server and returns the updated data
export const updateRole = async (id: number, roleParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/permissions/user/roles/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = roleParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Role from server and returns the updated data
export const delRole = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/permissions/user/roles/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Modules

//Fetches all the Modules from server
export const getModule = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/permissions/modules/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};
//////////////////////////////////////////////////////////////////////////////////
//Employee

//Fetches all the Employee from server
export const getEmployee = async (url: string) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + url;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getSpecificEmployee = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/account/user/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Fetches only the active Employee from server
export const setEmployee = async (employeeParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/account/user/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = employeeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Employee from server and returns the updated data
export const updateEmployee = async (id: number, employeeParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/account/user/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = employeeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Employee from server and returns the updated data
export const delEmployee = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/account/user/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delEmployeeStoreMapping = async (deleteParams: {
  store_id: number;
  user_id: number;
}) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/account/user/store/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = deleteParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getAuthorizedEmployee = async (
  authParams: TGetAuthorizedEmployee,
  pageNo: number,
  query?: string
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/account/user/master/password/?store_id=${
      authParams.store_id
    }&action=${authParams.action}${
      authParams.module_id ? `&module_id=${authParams.module_id}` : ""
    }${
      authParams.sub_module_id
        ? `&sub_module_id=${authParams.sub_module_id}`
        : ""
    }&page=${pageNo}${query ? `&query=${query}` : ""}`;

  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

export const generateMasterPasswordOTP = async (params: {
  employee_id: any;
  module: number;
  sub_module: number;
  extra_id: number;
}) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + `/api/account/user/master/password/otp/`;

  const requestMethod = REQUEST_METHOD.POST;

  const requestBody = params;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const verifyMasterPasswordOTP = async (
  otp: number
  // verifyParams: TVerifyMasterPasswordOTP
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/account/user/master/password/otp/verification/`;

  const requestMethod = REQUEST_METHOD.POST;

  const requestBody = { otp: otp };

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Banks

//Fetches all the Bank from server
export const getBank = async (url) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + url;

  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches ponly the specific Bank from server
export const getSpecificBank = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/payment/banks/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active Bank from server
export const getActiveBank = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/payment/banks/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Bank to server
export const setBank = async (bankParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/payment/banks/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = bankParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Bank from server and returns the updated data
export const updateBank = async (id: number, bankParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/payment/banks/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = bankParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Bank from server and returns the updated data
export const delBank = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/payment/banks/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Account Type

export const getAccountType = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/payment/banks/account/type/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Account Type to server
export const setAccountType = async (accountTypeParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/payment/banks/account/type/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = accountTypeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Branch.

//Fetches all the Branch from server
export const getBranch = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/store/manage/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the specific Branch from server
export const getSpecificBranch = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/store/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches active Branch from server
export const getActiveBranch = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/store/manage/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Branch to server
export const setBranch = async (branchParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/store/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = branchParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Branch from server and returns the updated data
export const updateBranch = async (id: number, branchParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/store/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = branchParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Branch from server and returns the updated data
export const delBranch = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/store/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Fetches the current ecommerce store
export const getEcommerceStore = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/store/manage/ecommerce_store/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Outlet Type
//update and delete remaining

//Fetches all the Outlet Type from server
export const getOutletType = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/store/manage/outlet/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches active Outlet Type from server
export const getActiveOutletType = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/store/manage/outlet/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Outlet Type to server
export const setOutletType = async (outletTypeParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/store/manage/outlet/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = outletTypeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Product Brand

//Fetches all the Brand from server
export const getBrand = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/product/brands/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the specific Brand from server
export const getSpecificBrand = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/brands/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active Brand from server
export const getActiveBrand = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/brands/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Brand to server
export const setBrand = async (brandParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/product/brands/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = brandParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Brand from server and returns the updated data
export const updateBrand = async (id: number, brandParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/brands/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = brandParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Brand from server and returns the updated data
export const delBrand = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/brands/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//UOM

//Fetches all the UOM from server
export const getUOM = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/product/uom/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the specific UOM from server
export const getSpecificUOM = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/uom/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active UOM from server
export const getActiveUOM = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/uom/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the UOM to server
export const setUOM = async (uomParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/product/uom/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = uomParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) UOM from server and returns the updated data
export const updateUOM = async (id: number, uomParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/uom/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = uomParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) UOM from server and returns the updated data
export const delUOM = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/uom/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Payment Terms

//Fetches all the Payment Terms from server
export const getPaymentTerm = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/payment/terms/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active Payment Terms from server
export const getActivePaymentTerm = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/payment/terms/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Payment Terms to server
export const setPaymentTerm = async (paymentTermsParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/payment/terms/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = paymentTermsParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Payment Terms from server and returns the updated data
export const updatePaymentTerm = async (
  id: number,
  paymentTermsParams: object
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/payment/terms/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = paymentTermsParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Payment Terms from server and returns the updated data
export const delPaymentTerm = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/payment/terms/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Payment Mode
//completed

//Fetches all the Payment Mode from server
export const getPaymentMode = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/vendor/payment/mode/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Fetches only the active Payment Mode from server
export const getActivePaymentMode = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/payment/mode/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Payment Mode to server
export const setPaymentMode = async (paymentModeParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/payment/mode/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = paymentModeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Payment Mode from server and returns the updated data
export const delPaymentMode = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/payment/mode/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Payment Mode from server and returns the updated data
export const updatePaymentMode = async (
  id: number,
  paymentModeParams: object
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/payment/mode/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = paymentModeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

// //////////////////////////////////////////////////////////////////////////////////
// //Store Department
// //completed

// //Fetches all the Store Department from server
// export const getStoreDept = async () => {
//   // Prepare for request
//   const requestURL = process.env.REACT_APP_BASE_URL + "/api/department/manage/";
//   const requestMethod = REQUEST_METHOD.GET;

//   // Make a request
//   return makeApiRequest(requestURL, requestMethod, {});
// };

// //Add the Store Department to server
// export const setStoreDept = async (storeDeptParams: object) => {
//   // Prepare for request
//   const requestURL = process.env.REACT_APP_BASE_URL + "/api/department/manage/";
//   const requestMethod = REQUEST_METHOD.POST;
//   const requestBody = storeDeptParams;

//   // Make a request
//   return makeApiRequest(requestURL, requestMethod, requestBody);
// };

// //Updates the selected(id) Store Department from server and returns the updated data
// export const updateStoreDept = async (id: number, storeDeptParams: object) => {
//   // Prepare for request
//   const requestURL =
//     process.env.REACT_APP_BASE_URL + "/api/department/manage/" + id + "/";
//   const requestMethod = REQUEST_METHOD.PUT;
//   const requestBody = storeDeptParams;

//   // Make a request
//   return makeApiRequest(requestURL, requestMethod, requestBody);
// };

// //delete the selected(id) Store Department from server and returns the updated data
// export const delStoreDept = async (id: number) => {
//   // Prepare for request
//   const requestURL =
//     process.env.REACT_APP_BASE_URL + "/api/department/manage/" + id + "/";
//   const requestMethod = REQUEST_METHOD.DELETE;
//   const requestBody = {};

//   // Make a request
//   return makeApiRequest(requestURL, requestMethod, requestBody);
// };

//////////////////////////////////////////////////////////////////////////////////
//HSN Code

//Fetches all the HSN Code from server
export const getHSNCode = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/product/hsncodes/?${query ? `query=${query}&` : ""}page=${pageNo}`;

  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active HSN Code from server
export const getActiveHSNCode = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/hsncodes/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the HSN Code to server
export const setHSNCode = async (hsnCodeParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/product/hsncodes/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = hsnCodeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) HSN Code from server and returns the updated data
export const updateHSNCode = async (id: number, hsnCodeParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/hsncodes/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = hsnCodeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) HSN Code from server and returns the updated data
export const delHSNCode = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/hsncodes/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Delete the category from server
export const delHSNCategory = async (delHSNParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/hsncodes/category/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = delHSNParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Product Tax

//Fetches all the TAX from server
export const getProductTax = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/product/tax/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active TAX from server
export const getActiveProductTax = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/tax/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the TAX to server
export const setProductTax = async (productTaxParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/product/tax/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = productTaxParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) TAX from server and returns the updated data
export const updateProductTax = async (
  id: number,
  productTaxParams: object
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/tax/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = productTaxParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) TAX from server and returns the updated data
export const delProductTax = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/tax/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes selected HSN from server and returns the updated data
export const delHSNProductTax = async (deleteParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/tax/mapping/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = deleteParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Location

//Fetches all the CITIES from server
export const getCities = async (state) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/location/city/${state ? state + "/" : ""}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches all the STATE from server
export const getStates = async (country) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/location/state/${country ? country + "/" : ""}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches all the COUNTRY from server
export const getCountries = async () => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/location/country/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//////////////////////////////////////////////////////////////////////////////////
//Product Category
//remaining

//Fetches all the Product Category from server
export const getCategory = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/category/manage/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the specific Product Category from server
export const getSpecificCategory = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/category/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active Product Category from server
export const getActiveCategory = async (cms: boolean = false) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/category/manage/active/" +
    `${cms ? "?cms=1" : ""}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Product Category to server
export const setCategory = async (categoryParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/category/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = categoryParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Product Category from server and returns the updated data
export const delCategory = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/category/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Product Category from server and returns the updated data
export const updateCategory = async (id: number, categoryParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/category/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = categoryParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Product Sub-Category

//Fetches all the Product Sub-Category from server
export const getSubCategory = async (
  pageNo = 1,
  query = "",
  category?: number,
  cms?: boolean
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/category/sub/category/?${query ? `query=${query}&` : ""}${
      category ? "category[]=" + category + "&" : ""
    }page=${pageNo}` +
    `${cms ? `&cms=1` : ""}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the specific Product Sub-Category from server
export const getSpecificSubCategory = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/category/sub/category/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active Product Sub-Category from server
export const getActiveSubCategory = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/category/sub/category/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Product Sub-Category to server
export const setSubCategory = async (subCategoryParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/category/sub/category/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = subCategoryParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Product Sub-Category from server and returns the updated data
export const updateSubCategory = async (
  id: number,
  subCategoryParams: object
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/category/sub/category/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = subCategoryParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Product Sub-Category from server
export const delSubCategory = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/category/sub/category/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delCatSubCategory = async (deleteParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/category/sub/category/mapping/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = deleteParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Customers

//Fetches all the Customers from server
export const getCustomer = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/customer/manage/?${query ? `query=${query}&` : ""}page=${pageNo}`;

  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the specific Customers from server
export const getSpecificCustomer = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/customer/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active Customers from server
export const getActiveCustomer = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/customer/manage/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Customers to server
export const setCustomer = async (customerParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/customer/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = customerParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Customers from server and returns the updated data
export const updateCustomer = async (id: number, customerParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/customer/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = customerParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getCustomerOrderDetail = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/orders/manage/customer/order/" +
    id +
    "/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Deletes the selected(id) Customers from server and returns the updated data
export const delCustomer = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/customer/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Products

//Fetches all the Products from server
export const getProduct = async (
  pageNo: number = 1,
  query: string = "",
  brand: number = 0,
  extra?: string
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/product/manage/?${query ? `query=${query}&` : ""}page=${pageNo}${
      brand ? `&brand[]=${brand}` : ""
    }` +
    `${extra ? `&${extra}` : ""}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

export const getProductFormSupportingData = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/product/manage/supporting/data/${id ? `?product=${id}` : ""}`;
  const requestMethod = REQUEST_METHOD.GET;
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the specific Products from server
export const getSpecificProduct = async (id: number, store?: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/product/manage/${id}/${store ? `?store[]=${store}` : ""}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active Products from server
export const getActiveProduct = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/product/manage/active/?${
      query ? `query=${query}&` : ""
    }page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the Products for the specified Store from server
export const getStoreSpecificProduct = async ({
  pageNo,
  query,
  store,
  pos,
  product,
  product_group,
  avarya_filter,
  controller,
}: {
  pageNo: number;
  query: string;
  store?: number;
  pos: boolean;
  product?: number;
  product_group?: number;
  avarya_filter: boolean;
  controller?: AbortController;
}) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + `/api/product/manage/search/`;
  // `/api/product/manage/filter/?${query ? `query=${query}&` : ""}${
  //   store ? `store[]=${store}&` : ""
  // }${product ? `product=${product}&` : ""}${
  //   product_group ? `product_group=${product_group}&` : ""
  // }${pos ? `pos=1` : `page=${pageNo}`}${avarya_filter ? "&label=1" : ""}`;

  const requestMethod = REQUEST_METHOD.POST;

  const requestBody = {
    query: query,
    pos: pos ? 1 : 0,
    store: [store],
    label: avarya_filter ? 1 : 0,
    product: product,
    product_group: product_group,
  };

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody, controller);
};

//Fetches the Product for the specified product_code and store from server
export const getScannedProduct = async (
  product_code: string,
  selected_store: number,
  pos = false,
  avarya_filter: boolean = false,
  format: boolean = false
) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + `/api/product/manage/search/`;
  // `/api/product/manage/filter/?${pos ? `pos=1&` : ""}code=${product_code}${
  //   selected_store ? `&store[]=${selected_store}` : ""
  // }${format ? "&formatdata=1" : ""}${avarya_filter ? "&label=1" : ""}`;

  const requestMethod = REQUEST_METHOD.POST;

  const requestBody = {
    pos: pos ? 1 : 0,
    store: [selected_store],
    label: avarya_filter ? 1 : 0,
    code: product_code,
  };

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Add the Products to server
export const setProduct = async (productParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/product/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = productParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Products from server and returns the updated data
export const updateProduct = async (id: number, productParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = productParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Products from server and returns the updated data
export const delProduct = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Delete nutritionDetails and product Mapping
export const delProductNutritionMapping = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/product/manage/nutrition/details/" +
    id +
    "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delProductTaxMapping = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/manage/tax/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delProductBatchMapping = async (
  id: number,
  add: boolean = false
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/manage/batch/" + id + "/";
  const requestMethod = add ? REQUEST_METHOD.POST : REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Product Group

//Fetches all the Product Group from server
export const getProductGroup = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/product/group/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetch selected Product Group from server
export const getSpecificProductGroup = async (
  id: number,
  selectedStore?: number
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/product/group/" +
    id +
    `/${selectedStore ? `?store[]=${selectedStore}&pos=1` : ""}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only active Product Group from server
export const getActiveProductGroup = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/group/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Product Group to server
export const setProductGroup = async (productGroupParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/product/group/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = productGroupParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Product Group from server and returns the updated data
export const updateProductGroup = async (
  id: number,
  productGroupParams: object
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/group/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = productGroupParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Product Group from server and returns the updated data
export const delProductGroup = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/group/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delProductGroupBarcodeMapping = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/group/barcode/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delProductGroupProductMapping = async (delobject: {
  product: number;
  product_group: number;
}) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/group/product/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = delobject;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const fetchProductGroupHamperName = async () => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/group/name/";

  const requestMethod = REQUEST_METHOD.GET;

  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Product Ingredients

export const getProductIngredients = async (query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/product/manage/ingredient/${query ? `?${query}` : ""}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

export const getActiveProductIngredients = async (query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/product/manage/ingredient/active/${query ? `?${query}` : ""}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

export const setProductIngredients = async (
  productIngredientsParams: object
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/ingredient/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = productIngredientsParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateProductIngredients = async (
  id: number,
  productIngredientsParams: object
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/ingredient/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = productIngredientsParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delProductIngredients = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/ingredient/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delProductIngredientsMapping = async (deleteParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/manage/ingredient/product/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = deleteParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Product Nutrients
export const getProductNutrients = async (active = false) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/product/manage/nutrient/${active ? "active/" : ""}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//////////////////////////////////////////////////////////////////////////////////
//Barcode Type

//Fetches all the Barcode Type from server
export const getBarcodeType = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/manage/barcode/type/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetch only the active Barcode type from server
export const getActiveBarcodeType = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/manage/barcode/type/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Barcode type to server
export const setBarcodeType = async (barcodeTypeParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/barcode/type/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = barcodeTypeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Fetch only the individual Barcode type from server
export const getSingleBarcodeType = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/barcode/type/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Delete Product Barcode mapping
export const delProductBarcodeMapping = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/manage/barcode/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = { id };

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const generateNewBarcode = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/manage/barcode/gen/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = { product_id: id };

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Discounts

//Fetches all the Discounts from server
export const getDiscount = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/discount/manage/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Fethces only the specific Discounts from server
export const getSpecificDiscount = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Fetches only the active Discounts from server
export const getActiveDiscount = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/manage/active/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Add the Discounts to server
export const setDiscount = async (discountParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/discount/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = discountParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Discounts from server and returns the updated data
export const updateDiscount = async (id: number, discountParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = discountParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Discounts from server and returns the updated data
export const delDiscount = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delDiscountStoreMapping = async (delParams: {
  discount: number;
  store: number;
}) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/manage/store/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = delParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delDiscountCategoryMapping = async (delParams: {
  discount: number;
  category: number;
}) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/manage/category/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = delParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delDiscountSubCategoryMapping = async (delParams: {
  discount: number;
  sub_category: number;
}) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/manage/sub_category/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = delParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delDiscountBrandMapping = async (delParams: {
  discount: number;
  brand: number;
}) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/manage/brand/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = delParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delDiscountProductMapping = async (delParams: {
  discount: number;
  product: number;
}) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/manage/product/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = delParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getCustomerEligibility = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/customer/eligibility/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getActiveCustomerEligibility = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/discount/customer/eligibility/active/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getCouponUsage = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/coupon/usage/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getActiveCouponUsage = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/coupon/usage/active/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};
//DiscountType

//Fetches all the Discount Type from server
export const getDiscountType = async () => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/discount/type/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Fetches only the active Discount Type from server
export const getActiveDiscountType = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/type/active/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Add the Discount Type to server
export const setDiscountType = async (discountTypeParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/discount/type/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = discountTypeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Discount Type from server and returns the updated data
export const updateDiscountType = async (
  id: number,
  discountTypeParams: object
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/type/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = discountTypeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Discount Type from server and returns the updated data
export const delDiscountType = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/type/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Discount Applicable Type

//Fetches all the Discount Applicable Type from server
export const getDiscountApplicableType = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/applicable/type/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Fetches only the active Discount Applicable Type from server
export const getActiveDiscountApplicableType = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/applicable/type/active/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Add the Discount Applicable Type to server
export const setDiscountApplicableType = async (
  discountApplicableTypeParams: object
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/discount/applicable/type/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = discountApplicableTypeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Discount Applicable Type from server and returns the updated data
export const updateDiscountApplicableType = async (
  id: number,
  discountApplicableTypeParams: object
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/discount/applicable/type/" +
    id +
    "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = discountApplicableTypeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Discount Applicable Type from server and returns the updated data
export const delDiscountApplicableType = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/discount/applicable/type/" +
    id +
    "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Vendors

//Fetches all the Vendors from server
export const getVendor = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/vendor/manage/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getSpecificVendor = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Fetches only the active Vendors from server
export const setVendor = async (vendorParams: object) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/vendor/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = vendorParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Vendors from server and returns the updated data
export const updateVendor = async (id: number, vendorParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = vendorParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Vendors from server and returns the updated data
export const delVendor = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Delete the selected Contact for vendor
export const delVendorContact = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/contact/" + id + "/";

  const requestMethod = REQUEST_METHOD.DELETE;

  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Delete the selected Address for vendor
export const delVendorAddress = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/location/" + id + "/";

  const requestMethod = REQUEST_METHOD.DELETE;

  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Delete the selected Store for vendor
export const delSpecificStore = async (delParams: {
  vendor: number;
  store: number;
}) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/vendor/store/";

  const requestMethod = REQUEST_METHOD.DELETE;

  const requestBody = delParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Delete a single Product from vendor
export const delVendorStoreSpecificProduct = async (delParams: {
  store: number;
  product: number;
}) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/vendor/products/";

  const requestMethod = REQUEST_METHOD.DELETE;

  const requestBody = delParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Delete Alias in Product Type
export const delProductSpecificAlias = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/product/alias/" + id + "/";

  const requestMethod = REQUEST_METHOD.DELETE;

  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Delete Order Days in Products
export const delSpecificOrderDeliveryDays = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/order/delivery/" + id + "/";

  const requestMethod = REQUEST_METHOD.DELETE;

  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Address Type

//Fetches all the Address Type from server
export const getAddressType = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/address/type/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Add the Address Type to server
export const setAddressType = async (addressTypeParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/address/type/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = addressTypeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Supplier Type

//Fetches all the Supplier Type from server
export const getSupplierType = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/supplier/type/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Fetches only the active Supplier Type from server
export const getActiveSupplierType = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/supplier/type/active/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Add the Supplier Type to server
export const setSupplierType = async (supplierTypeParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/supplier/type/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = supplierTypeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Delivery Policy

//Fetches all the Delivery Policy from server
export const getDeliveryPolicy = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/delivery/policy/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Fetches only the active Delivery Policy from server
export const getActiveDeliveryPolicy = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/delivery/policy/active/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Add the Delivery Policy to server
export const setDeliveryPolicy = async (deliveryPolicyParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/vendor/delivery/policy/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = deliveryPolicyParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Printers

//Fetches all the Printers from server
export const getPrinters = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/printer/manage/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the specific Printers from server
export const getSpecificPrinter = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/printer/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active Printers from server
export const getActivePrinters = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/printer/manage/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Printers to server
export const setPrinter = async (printerParams: IPrinterState) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/printer/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = printerParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Printers from server and returns the updated data
export const updatePrinter = async (
  id: number,
  printerParams: IPrinterState
) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/printer/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = printerParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Printers from server and returns the updated data
export const delPrinter = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/printer/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//////////////////////////////////////////////////////////////////////////////////
//Labels

//Fetches all the Labels from server
export const getLabels = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/label/manage/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the specific Labels from server
export const getSpecificLabel = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/label/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active Labels from server
export const getActiveLabels = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/label/manage/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Add the Labels to server
export const setLabel = async (labelParams: ILabelState) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/label/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = labelParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Updates the selected(id) Labels from server and returns the updated data
export const updateLabel = async (id: number, labelParams: ILabelState) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/label/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = labelParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Deletes the selected(id) Labels from server and returns the updated data
export const delLabel = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/label/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Delete the selected printer for label
export const delLabelPrinterMapping = async (delParams: {
  label: number;
  printer: number;
}) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/label/manage/printer/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = delParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Generate Barcode for the Products  with given store and batch

export const generateBarcode = async (generateBarcodeParams: object) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/barcode/generator/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = generateBarcodeParams;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Print Type

//Fetches all the Print Type from server
export const getPrintType = async () => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/label/print/type/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active Print Type from server
export const getActivePrintType = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/label/print/type/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Print Size

//Fetches all the Print Size from server
export const getPrintSize = async () => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/label/print/size/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active Print Size from server
export const getActivePrintSize = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/label/print/size/active/";
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//////////////////////////////////////////////////////////////////////////////////
//Label Template

//Fetches all the Label Template from server
export const getLabelTemplate = async () => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + `/api/label/template/`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//Fetches only the active Label Template from server
export const getActiveLabelTemplate = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + `/api/label/template/active/`;
  const requestMethod = REQUEST_METHOD.GET;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, {});
};

//--------------------------------------------------------------------------------

//Fetches all the HoldBills from server
export const getHoldBill = async (pageNo = 1, query = "", store) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/hold/bill/manage/?${query ? `query=${query}&` : ""}page=${pageNo}${
      store ? `&store[]=${store}` : ""
    }`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setHoldBill = async (holdBill: any) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/hold/bill/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = holdBill;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delHoldBill = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/hold/bill/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getOrder = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/orders/manage/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getSpecificOrder = async (id: number, store?: number) => {
  // Prepare for request
  // const requestURL =
  //   process.env.REACT_APP_BASE_URL + "/api/orders/manage/" + id + "/";
  const requestURL = `${
    process.env.REACT_APP_BASE_URL
  }/api/orders/manage/${id}/${store ? `?store_id=${store}` : ""}`;

  const requestMethod = REQUEST_METHOD.GET;

  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setOrder = async (order: IOrder) => {
  // Prepare for request
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/orders/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = order;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateOrder = async (order: IOrder) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/orders/manage/" + order.id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = order;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const cancelOrder = async (id: number, extra: boolean = false) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/orders/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(
    requestURL,
    requestMethod,
    requestBody,
    undefined,
    "json",
    REQUEST_CONTENT_TYPE.APP_JSON,
    extra ? id : undefined
  );
};

export const getOrderNotificationLogs = async (id: number) => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/orders/whatsapp/log/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const addOrderPayment = async (order: any, edit: boolean = false) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/orders/manage/payment/";
  const requestMethod = edit ? REQUEST_METHOD.PUT : REQUEST_METHOD.POST;
  const requestBody = order;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const editOrderPayment = async (order: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/orders/manage/payment/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = order;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delOrderPayment = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + `/api/orders/manage/payment/${id}/`;
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const printLastBill = async ({
  store,
  bill,
}: {
  store?: number;
  bill?: number;
}) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/orders/manage/last/bill/?${store ? `store=${store}` : ""}${
      bill ? `bill=${bill}` : ""
    }`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const lastOrderDetails = async (store: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/orders/manage/last/bill/details/?store=${store}`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const filterOrders = async (
  pageNo = 1,
  query = "",
  store: number,
  code?: string,
  status?: string,
  order?: number,
  type?: number,
  delivery_type?: string,
  start_date?: string,
  end_date?: string,
  controller?: AbortController
) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/orders/manage/filter/?${query ? `query=${query}&` : ""}${
      type ? `payment_type[]=${type}&` : ""
    }page=${pageNo}${store ? `&store[]=${store}` : ""}${
      code ? `&code=${code}` : ""
    }${status ? `&order_status=${status}` : ""}${
      order ? `&order=${order}` : ""
    }${delivery_type ? `&delivery_type=${delivery_type}` : ""}${
      start_date ? `&start_date=${start_date}` : ""
    }${end_date ? `&end_date=${end_date}` : ""}`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};
  return makeApiRequest(requestURL, requestMethod, requestBody, controller);
};

//Fetches all the Payment Modes for POS from server
export const getActivePOSPaymentMode = async () => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL + `/api/orders/payment/mode/active/`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

//Fetches all the Payment Types from server
export const getPOSPaymentType = async (pageNo = 1, query = "") => {
  // Prepare for request
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/orders/payment/type/active/?${
      query ? `query=${query}&` : ""
    }page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getCreditNotes = async (pageNo = 1, query = "") => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/returns/manage/?${query ? `query=${query}&` : ""}page=${pageNo}`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getIndividualCreditNote = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/returns/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const printCreditNote = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/returns/manage/print/" + id + "/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setCreditNote = async (creditNoteParams: any, extra) => {
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/returns/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = creditNoteParams;

  // Make a request
  return makeApiRequest(
    requestURL,
    requestMethod,
    requestBody,
    undefined,
    "json",
    REQUEST_CONTENT_TYPE.APP_JSON,
    extra ? creditNoteParams.order : undefined
  );
};

export const filterCreditNotes = async (
  store: number,
  code?: string,
  query?: string,
  type?: string,
  pageNo = 1,
  payment_status?: any,
  start_date?: string,
  end_date?: string,
  controller?: AbortController
) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/returns/manage/filter/?${store ? `store[]=${store}` : ""}${
      code ? `&code=${code}` : ""
    }${query ? `&query=${query}` : ""}${
      type !== undefined ? `&type=${type}&page=${pageNo}` : ""
    }${payment_status !== undefined ? `&paid=${payment_status}` : ""}${
      start_date ? `&start_date=${start_date}` : ""
    }${end_date ? `&end_date=${end_date}` : ""}`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};
  return makeApiRequest(requestURL, requestMethod, requestBody, controller);
};

export const delCreditNote = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/returns/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const addCreditNotePayments = async (
  creditNote: any,
  edit: boolean = false
) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/returns/manage/payments/";
  const requestMethod = edit ? REQUEST_METHOD.PUT : REQUEST_METHOD.POST;
  const requestBody = creditNote;

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delCreditNotePayments = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + `/api/returns/manage/payments/${id}/`;
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  // Make a request
  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getKPIReport = async ({ url }: { url: string }) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + `/api/reports/manage/kpi/report/${url}`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  // Make a request
  const bearerToken = localStorage.getItem("accessToken");
  const requestHeaders = {
    "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
    Authorization: "Bearer " + bearerToken,
  };
  // return makeApiRequest(requestURL, requestMethod, requestBody, null, "blob");
  return setupInterceptorsTo({
    url: requestURL,
    headers: requestHeaders,
    method: requestMethod,
    data: requestBody,
    responseType: "blob",
  });
};

export const sendPayLaterReminder = async (order_id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `/api/orders/manage/pay/later/${order_id}/`;
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

///==================== Ecommerce Masters ====================///

export const getMenus = async ({
  id,
  active,
  pageNo = 1,
  query = "",
}: TGetParams) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/menu/manage/" +
    `${active ? "active/" : ""}` +
    `${id ? `${id}/` : ""}` +
    `?page=${pageNo}${query ? `&query=${query}` : ""}`;

  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setMenu = async (menu: any) => {
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/menu/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = menu;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateMenu = (id: number, menuParams: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/menu/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = menuParams;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delMenu = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/menu/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delMenuItem = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/menu/manage/menuitem/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getMenuTypes = async () => {
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/menu/menutype/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getFAQs = async ({
  id,
  active,
  pageNo = 1,
  query = "",
}: TGetParams) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/faq/manage/" +
    `${active ? "active/" : ""}` +
    `${id ? `${id}/` : ""}` +
    `?page=${pageNo}${query ? `&query=${query}` : ""}`;

  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setFAQ = async (faq: any) => {
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/faq/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = faq;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateFAQ = (id: number, faqParams: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/faq/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = faqParams;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delFAQ = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/faq/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getTestimonial = async ({
  id,
  active,
  pageNo = 1,
  query = "",
}: TGetParams) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/testimonial/manage/" +
    `${active ? "active/" : ""}` +
    `${id ? `${id}/` : ""}` +
    `?page=${pageNo}${query ? `&query=${query}` : ""}`;

  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setTestimonial = async (testimonial: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/testimonial/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = testimonial;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateTestimonial = (id: number, testimonialParams: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/testimonial/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = testimonialParams;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delTestimonial = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/testimonial/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getEmailTemplates = async ({
  id,
  active,
  pageNo = 1,
  query = "",
}: TGetParams) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/email_templates/manage/" +
    `${active ? "active/" : ""}` +
    `${id ? `${id}/` : ""}` +
    `?page=${pageNo}${query ? `&query=${query}` : ""}`;

  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setEmailTemplate = async (emailtemplate: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/email_templates/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = emailtemplate;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateEmailTemplate = (id: number, emailtemplateParams: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/email_templates/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = emailtemplateParams;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delEmailTemplate = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/email_templates/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getTastes = async ({
  id,
  active,
  pageNo = 1,
  query = "",
}: TGetParams) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/taste/manage/" +
    `${active ? "active/" : ""}` +
    `${id ? `${id}/` : ""}` +
    `?page=${pageNo}${query ? `&query=${query}` : ""}`;

  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setTaste = async (taste: any) => {
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/taste/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = taste;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateTaste = (id: number, tasteParams: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/taste/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = tasteParams;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delTaste = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/taste/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getSiteDetails = async () => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/site_management/manage/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setSiteDetails = async (siteDetails: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/site_management/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = siteDetails;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateSiteDetails = async (siteDetails: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/site_management/manage/update/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = siteDetails;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getSiteEmailDetails = async () => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/site_management/manage/email_settings/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setSiteEmailDetails = async (siteEmailDetails: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/site_management/manage/email_settings/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = siteEmailDetails;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delSiteEmailDetails = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/site_management/manage/email_settings/" +
    `${id}/`;
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getSiteSMSDetails = async () => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/site_management/manage/sms_details/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setSiteSMSDetails = async (siteSMSDetails: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/site_management/manage/sms_details/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = siteSMSDetails;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delSiteSMSDetails = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/site_management/manage/sms_details/" +
    `${id}/`;
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getSitePaymenetDetails = async () => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/site_management/manage/payment_details/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setSitePaymentDetails = async (sitePaymentDetails: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/site_management/manage/payment_details/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = sitePaymentDetails;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delSitePaymentDetails = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/site_management/manage/payment_details/" +
    `${id}/`;
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getSiteShippingDetails = async () => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/site_management/manage/shipping_details/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setSiteShippingDetails = async (siteShippingDetails: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/site_management/manage/shipping_details/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = siteShippingDetails;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delSiteShippingDetails = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/site_management/manage/shipping_details/" +
    `${id}/`;
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getMenuSettings = async () => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/menu/manage/menu_setting/";
  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateMenuSettings = async (menuSettings: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/menu/manage/menu_setting/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = menuSettings;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const uploadImageToS3 = async (params: {
  folder_name: string;
  file_name: string;
}) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/utils/image/upload/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = params;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delImageFromS3 = async (params: { file_name: string }) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/utils/image/delete/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = params;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getWidgets = async ({
  id,
  active,
  pageNo = 1,
  query = "",
}: TGetParams) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/widgets/manage/" +
    `${active ? "active/" : ""}` +
    `${id ? `${id}/` : ""}` +
    `?page=${pageNo}${query ? `&query=${query}` : ""}`;

  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setWidget = async (widget: any) => {
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/widgets/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = widget;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateWidget = (id: number, widgetParams: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/widgets/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = widgetParams;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delWidget = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/widgets/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delWidgetContent = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/widgets/manage/content_cms/" +
    id +
    "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getPages = async ({
  id,
  active,
  pageNo = 1,
  query = "",
}: TGetParams) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/pages/manage/" +
    `${active ? "active/" : ""}` +
    `${id ? `${id}/` : ""}` +
    `?page=${pageNo}${query ? `&query=${query}` : ""}`;

  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setPage = async (page: any) => {
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/pages/manage/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = page;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updatePage = (id: number, pageParams: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/pages/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = pageParams;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delPage = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/pages/manage/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getCMSCategories = async ({
  id,
  active,
  pageNo = 1,
  query = "",
}: TGetParams) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/category/cms/" +
    `${active ? "active/" : ""}` +
    `${id ? `${id}/` : ""}` +
    `?page=${pageNo}${query ? `&query=${query}` : ""}`;

  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setCMSCategory = async (category: any) => {
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/category/cms/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = category;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateCMSCategory = (id: number, categoryParams: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/category/cms/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = categoryParams;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delCMSCategory = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/category/cms/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getCMSSubCategories = async ({
  id,
  active,
  pageNo = 1,
  query = "",
  extra,
}: TGetParams) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/category/cms/sub/category/" +
    `${
      active
        ? "active/"
        : `${id ? `${id}/` : ""}` +
          `?page=${pageNo}${query ? `&query=${query}` : ""}`
    }` +
    `${extra ? `${active ? "?" : "&"}${extra}` : ""}`;

  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setCMSSubCategory = async (category: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/category/cms/sub/category/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = category;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateCMSSubCategory = (id: number, categoryParams: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/category/cms/sub/category/" +
    id +
    "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = categoryParams;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delCMSSubCategory = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/category/cms/sub/category/" +
    id +
    "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const getCMSProducts = async ({
  id,
  active,
  pageNo = 1,
  query = "",
}: TGetParams) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/product/manage/cms/" +
    `${active ? "active/" : ""}` +
    `${id ? `${id}/` : ""}` +
    `?page=${pageNo}${query ? `&query=${query}` : ""}`;

  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const setCMSProduct = async (product: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/manage/cms/";
  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = product;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateCMSProduct = (id: number, productParams: any) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/manage/cms/" + id + "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = productParams;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delCMSProduct = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + "/api/product/manage/cms/" + id + "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delCMSProductMediaMapping = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/product/manage/cms/media_detail/" +
    id +
    "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delCMSProductWeightMapping = async (
  id: number,
  is_active: boolean = false
) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/product/manage/cms/weight/" +
    id +
    "/";
  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = { is_active: is_active ? 1 : 0 };

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const delCMSProductTasteMapping = async (id: number) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    "/api/product/manage/cms/taste/" +
    id +
    "/";
  const requestMethod = REQUEST_METHOD.DELETE;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

/////////////////////ECOMMERCE ORDERS////////////////////////

export const getEcommerceOrders = async ({
  id,
  active,
  pageNo = 1,
  query = "",
  edit,
}: TGetParams) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL +
    `${id ? "/api/orders/order/" : "/api/orders/all/orders/"}` +
    `${active ? "active/" : ""}` +
    `${
      id
        ? `${id}/`
        : `?page=${pageNo}${query ? `&query=${query}` : ""}${
            edit?.order_status ? `&order_status=${edit?.order_status}` : ""
          }${
            edit?.payment_status
              ? `&payment_status=${edit?.payment_status}`
              : ""
          }${edit.start_date ? `&start_date=${edit.start_date}` : ""}${
            edit.end_date ? `&end_date=${edit.end_date}` : ""
          }${
            edit.international !== undefined
              ? `&international=${edit.international}`
              : ""
          }`
    }`;

  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const addEcommerOrder = async ({ data }: any) => {
  const requestURL = process.env.REACT_APP_BASE_URL + "/api/orders/all/orders/";

  const requestMethod = REQUEST_METHOD.POST;
  const requestBody = data;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const printEcommerceBill = async ({ bill_id }: { bill_id: number }) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + `/api/orders/order/invoice/${bill_id}`;

  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const printEcommerceShippingLabel = async ({
  bill_id,
}: {
  bill_id: number;
}) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + `/api/orders/shipping/label/${bill_id}`;

  const requestMethod = REQUEST_METHOD.GET;
  const requestBody = {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const updateOrderStatus = async ({
  bill_id,
  data,
}: {
  bill_id: number;
  data: any;
}) => {
  const requestURL =
    process.env.REACT_APP_BASE_URL + `/api/orders/order/update/${bill_id}/`;

  const requestMethod = REQUEST_METHOD.PUT;
  const requestBody = data;

  return makeApiRequest(requestURL, requestMethod, requestBody);
};

export const manageCustomerAddresses = async ({
  customer_id,
  data,
}: {
  customer_id: number;
  data?: EcommerceAddress;
}) => {
  const requestURL = `${process.env.REACT_APP_BASE_URL}/api/orders/customer/address/${customer_id}/`;

  const requestMethod = data ? REQUEST_METHOD.POST : REQUEST_METHOD.GET;
  const requestBody = data || {};

  return makeApiRequest(requestURL, requestMethod, requestBody);
};
