import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  FontIcon,
  IconButton,
  Loader,
} from "components";
import { ErrorMessage, FieldArray } from "formik";
import React, { useEffect, useMemo } from "react";

import {
  SITE_MANAGEMENT_CONSTANTS,
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  useAppSelector,
  STATUSES,
} from "utils";

const SMSDetails = ({
  props,
  arrayHelperRef,
  setCurrentItemToDelete,
  setConfirmModal,
}) => {
  const {
    root: {
      auth: { permissions },
    },
    ecommerce: {
      siteManagement: { status },
    },
  } = useAppSelector((state) => state);

  const siteManagementPermission = useMemo(
    () =>
      permissions?.find(
        (perm) => perm.module_name === "Manage Site Management"
      ),
    [permissions?.length]
  );

  return (
    <div className="col-12">
      <Card>
        <CardHeader>
          <div className="row align-items-center">
            <div className="col-4">
              <h3 className="card-title">
                {SITE_MANAGEMENT_CONSTANTS.SMS_HEADER}
              </h3>
            </div>
            <div className="col-8 text-end">
              <IconButton
                icon={faAdd}
                btnClassNames="btn btn-outline-primary"
                btnText={SITE_MANAGEMENT_CONSTANTS.SMS_HEADER}
                onClickHandler={() => {
                  arrayHelperRef?.push({
                    sms_name: "",
                    sms_key: "",
                    new: true,
                  });
                }}
                type={BUTTON_CONSTANTS.BUTTON}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <FieldArray
            name="sms_details"
            render={(arrayHelpers) => {
              arrayHelperRef = arrayHelpers;
              return status === STATUSES.LOADING && !props.isSubmitting ? (
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              ) : (
                props.values.sms_details &&
                  props.values.sms_details.length > 0 &&
                  props.values.sms_details.map((sms, index) => (
                    <div key={index}>
                      <div className="row align-items-center">
                        <div className="col-11 d-flex flex-row">
                          <div className="col-6">
                            <CustomInput
                              isDisabled={sms?.id}
                              type={FORM_CONSTANTS.TEXT}
                              name={`${SITE_MANAGEMENT_CONSTANTS.SMS_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.SMS_NAME}`}
                              label={SITE_MANAGEMENT_CONSTANTS.SMS_NAME_LABEL}
                              placeholder={
                                SITE_MANAGEMENT_CONSTANTS.SMS_NAME_PLACEHOLDER
                              }
                            />
                            <ErrorMessage
                              name={`${SITE_MANAGEMENT_CONSTANTS.SMS_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.SMS_NAME}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="col-6">
                            <CustomInput
                              isDisabled={sms?.id}
                              type={FORM_CONSTANTS.TEXT}
                              name={`${SITE_MANAGEMENT_CONSTANTS.SMS_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.SMS_KEY}`}
                              label={SITE_MANAGEMENT_CONSTANTS.SMS_KEY_LABEL}
                              placeholder={
                                SITE_MANAGEMENT_CONSTANTS.SMS_KEY_PLACEHOLDER
                              }
                            />
                            <ErrorMessage
                              name={`${SITE_MANAGEMENT_CONSTANTS.SMS_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.SMS_KEY}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </div>
                        <div className="col-1 align-items-center">
                          <Button
                            type={BUTTON_CONSTANTS.BUTTON}
                            isDisabled={
                              !siteManagementPermission?.perm_delete ||
                              props.values.sms_details.length === 1
                            }
                            text={<FontIcon icon={faTrash} />}
                            btnClassNames={"btn btn-danger mt-2"}
                            onClickHandler={() => {
                              if (sms?.id) {
                                setConfirmModal(true);
                                setCurrentItemToDelete({
                                  id: sms.id,
                                  removeIndex: () => {
                                    arrayHelpers.remove(index);
                                  },
                                  type: SITE_MANAGEMENT_CONSTANTS.TABS[2],
                                });
                              } else {
                                arrayHelpers.remove(index);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))
              );
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export { SMSDetails };
