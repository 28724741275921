import {
  faArrowRight,
  faCircleNotch,
  faEllipsisVertical,
  faEye,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  ContentHeader,
  CustomMultiSelect,
  Dropdown,
  FontIcon,
  Loader,
  NoData,
  Pagination,
  Search,
  SelectStoreModal,
  Table,
  TableBody,
  TableHeader,
  TableTools,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  clearSelectedStore,
  getBranches,
} from "store/ManageBranches/branchSlice";
import {
  FilterCreditNotes,
  PrintCreditNote,
  setcurrentCreditNote,
} from "store/POS";
import { updatePageNo } from "store/commonSlice";
import {
  ADVANCE_ORDERS_CONSTANTS,
  BUTTON_CONSTANTS,
  financialYear,
  FORM_CONSTANTS,
  formatDate,
  GENERAL_CONSTANTS,
  POS_HEADER_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

const AdvanceOrderList = () => {
  const dispatch = useAppDispatch();

  const {
    auth: { permissions: permissionData },
    branch: { branch: stores, selectedStore },
    common: {
      current_page,
      total_items,
      total_pages,
      page_size,
      status: commonStatus,
    },
    creditNote: { creditNote: creditNoteData, status: creditNoteStatus },
  } = useAppSelector((state) => state.root);
  const navigate = useNavigate();

  const [query, setQuery] = useState(GENERAL_CONSTANTS.EMPTY_STRING);

  const [print, setPrint] = useState<number>();

  const [storeModal, setStoreModal] = useState<boolean>(
    GENERAL_CONSTANTS.FALSE
  );

  const [paymentStatus, setPaymentStatus] = useState<any>(
    localStorage.getItem(ADVANCE_ORDERS_CONSTANTS.PAYMENT_STATUS) ||
      GENERAL_CONSTANTS.ALL
  );

  const [startDate, setStartDate] = useState(
    localStorage.getItem(ADVANCE_ORDERS_CONSTANTS.START_DATE) ||
      new Date().toISOString().split("T")[0]
  );

  const [endDate, setEndDate] = useState(
    localStorage.getItem(ADVANCE_ORDERS_CONSTANTS.END_DATE) ||
      new Date().toISOString().split("T")[0]
  );

  const store_code = selectedStore
    ? stores.find((store) => store.id === selectedStore).store_code
    : null;

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    update_page(1, selectedStore, paymentStatus, event.target.value, endDate);
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    update_page(1, selectedStore, paymentStatus, startDate, event.target.value);
    setEndDate(event.target.value);
  };

  const dropdownItems = [
    {
      path: `#`,
      icon: faArrowRight,
      label: POS_HEADER_CONSTANTS.CHANGE_STORE,
      dropdownClickHandler: () => {
        dispatch(clearSelectedStore());
        setStoreModal(true);
      },
      reverse: true,
    },
  ];

  const update_page = useCallback(
    (
      pageNo: number,
      store: number = selectedStore,
      payment_status: string = paymentStatus,
      start_Date: string = startDate,
      end_Date: string = endDate
    ) => {
      const filterOptions = {
        store: selectedStore || store,
        type: ADVANCE_ORDERS_CONSTANTS.CREDIT_NOTE_TYPE,
        pageNo,
        cashier: GENERAL_CONSTANTS.FALSE,
      };

      if (selectedStore || store) {
        if (query) {
          filterOptions[
            ADVANCE_ORDERS_CONSTANTS.ADVANCE_ORDER_CODE
          ] = `ADC/${store_code}/${financialYear(new Date())}/${query}`;
          filterOptions[GENERAL_CONSTANTS.NAVIGATE] = navigate;
        } else {
          start_Date &&
            (filterOptions[ADVANCE_ORDERS_CONSTANTS.START_DATE] = start_Date);
          end_Date &&
            (filterOptions[ADVANCE_ORDERS_CONSTANTS.END_DATE] = end_Date);
          payment_status !== GENERAL_CONSTANTS.ALL &&
            (filterOptions[
              ADVANCE_ORDERS_CONSTANTS.PAYMENT_STATUS
            ] = payment_status);
        }
        dispatch(FilterCreditNotes(filterOptions));
      }
    },
    [dispatch, query, selectedStore, paymentStatus, startDate, endDate]
  );

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  const renderCreditNotes = () => {
    return creditNoteData && creditNoteData.length > 0 ? (
      creditNoteData.map((credit, creditIndex) => {
        return (
          <tr key={creditIndex}>
            <td>{page_size * (current_page - 1) + creditIndex + 1}</td>
            <td>{credit?.credit_note_code}</td>
            <td>{credit?.customer_name}</td>
            <td>{formatDate(credit?.created_at)}</td>
            <td>{credit?.delivery_date}</td>
            <td>{credit?.delivery_type?.toUpperCase()}</td>
            <td>₹{credit?.refund_amount}</td>
            <td>
              <Button
                text={<FontIcon icon={faEye} />}
                // isDisabled={!ordersPermission?.perm_edit}
                type={BUTTON_CONSTANTS.BUTTON}
                btnClassNames={"btn btn-success btn-sm mr-2"}
                onClickHandler={() => {
                  localStorage.setItem(
                    ADVANCE_ORDERS_CONSTANTS.PAYMENT_STATUS,
                    paymentStatus
                  );
                  localStorage.setItem(
                    ADVANCE_ORDERS_CONSTANTS.END_DATE,
                    endDate
                  );
                  localStorage.setItem(
                    ADVANCE_ORDERS_CONSTANTS.START_DATE,
                    startDate
                  );
                  navigate(
                    {
                      pathname: `${ADVANCE_ORDERS_CONSTANTS.ADVANCE_ORDER_DETAIL_ROUTE}${credit?.id}`,
                    },
                    { replace: true }
                  );
                }}
              />
              <Button
                text={
                  commonStatus?.state === STATUSES.LOADING &&
                  commonStatus?.type ===
                    ADVANCE_ORDERS_CONSTANTS.LOADING_STATE &&
                  print === creditIndex ? (
                    <FontIcon
                      icon={faCircleNotch}
                      iconClassNames={"fa-solid fa-circle-notch fa-spin"}
                    />
                  ) : (
                    <FontIcon icon={faPrint} />
                  )
                }
                isDisabled={
                  commonStatus?.state === STATUSES.LOADING &&
                  commonStatus?.type === ADVANCE_ORDERS_CONSTANTS.LOADING_STATE
                }
                type={BUTTON_CONSTANTS.BUTTON}
                btnClassNames="btn btn-info"
                onClickHandler={() => {
                  setPrint(creditIndex);
                  dispatch(PrintCreditNote(credit.id));
                }}
              />
            </td>
          </tr>
        );
      })
    ) : (
      <NoData len={ADVANCE_ORDERS_CONSTANTS.ADVANCE_ORDERS_LIST.length} />
    );
  };

  useEffect(() => {
    dispatch(getBranches({ active: true, edit: { setStoreModal } }));
    return () => {
      resetState();
    };
  }, [dispatch]);

  return (
    <div className="content-wrapper text-sm">
      {!selectedStore && (
        <SelectStoreModal
          modal={storeModal}
          setModal={setStoreModal}
          update={(value) => {
            update_page(1, value);
          }}
        />
      )}
      <ContentHeader
        pageHeader={ADVANCE_ORDERS_CONSTANTS.ADVANCE_ORDER_HEADER}
      />
      <Container>
        <Card cardClassNames="p-0">
          <CardHeader>
            <div className="d-flex flex-row px-0 container-fluid mb-2">
              <div className="col">
                <label htmlFor="start-date">
                  {ADVANCE_ORDERS_CONSTANTS.START_DATE_LABEL}:
                </label>
                <input
                  className="form-control"
                  type={FORM_CONSTANTS.DATE}
                  id="start-date"
                  max={new Date().toISOString().split("T")[0]}
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
              <div className="col">
                <label htmlFor="end-date">
                  {ADVANCE_ORDERS_CONSTANTS.END_DATE_LABEL}:
                </label>
                <input
                  className="form-control"
                  type={FORM_CONSTANTS.DATE}
                  id="end-date"
                  min={startDate}
                  // max={new Date().toISOString().split("T")[0]}
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
              <div className="col">
                <CustomMultiSelect
                  label={ADVANCE_ORDERS_CONSTANTS.FILTER_BY_PAID_UNPAID}
                  defaultValue={ADVANCE_ORDERS_CONSTANTS.PAYMENT_STATUS_OPTIONS.find(
                    (item) => item.value.toString() === paymentStatus
                  )}
                  options={ADVANCE_ORDERS_CONSTANTS.PAYMENT_STATUS_OPTIONS}
                  onChangeHandler={(e) => {
                    dispatch(updatePageNo(1));
                    e && update_page(1, selectedStore, e.value);
                    e && setPaymentStatus(e.value);
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="col-12">
                <div className="d-flex flex-row">
                  <Search
                    placeholder="Search by Invoice Code ..."
                    setQuery={setQuery}
                    prepend={
                      store_code
                        ? `ADC/${store_code}/${financialYear(new Date())}/`
                        : ""
                    }
                  />
                  {stores?.length > 1 && (
                    <div className="d-inline mt-1">
                      <Dropdown items={dropdownItems} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Table>
              <TableHeader
                cols={ADVANCE_ORDERS_CONSTANTS.ADVANCE_ORDERS_LIST}
              />
              <TableBody>
                {creditNoteStatus === STATUSES.LOADING ? (
                  <tr>
                    <td
                      colSpan={
                        ADVANCE_ORDERS_CONSTANTS.ADVANCE_ORDERS_LIST.length
                      }
                      className="text-center"
                    >
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  renderCreditNotes()
                )}
              </TableBody>
            </Table>
          </CardBody>
          <CardFooter>
            <Pagination
              loading={creditNoteStatus === STATUSES.LOADING}
              pageSize={page_size}
              totalItems={total_items}
              totalPages={total_pages}
              currentPage={current_page}
              prevClickHandler={prevClickHandler}
              nextClickHandler={nextClickHandler}
              pageChangeHandler={pageChangeHandler}
            />
          </CardFooter>
        </Card>
      </Container>
    </div>
  );
};

export { AdvanceOrderList };
