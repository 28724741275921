import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Formik, Form, FormikProps } from "formik";

import {
  ContentHeader,
  Button,
  Container,
  Loader,
  ConfirmModal,
} from "components";

import {
  ProductBatchWisePricingForm,
  ProductDetailsForm,
  ProductMSQDetailsForm,
  ProductNutritionDetailsForm,
} from "./";

import {
  useAppDispatch,
  useAppSelector,
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  PRODUCTS_CONSTANTS,
  ProductSchema,
  STATUSES,
  filterNullElements,
  getNewValues,
  handleOptionDelete,
  ScrollToTop,
  Toast,
  ACCESS_CONSTANTS,
} from "utils";

import {
  FetchSupportingData,
  addNewProduct,
  deleteProductTaxMapping,
  setProductToEdit,
  updateExistingProduct,
} from "store/ManageProducts/productSlice";

import { ScrollToFieldError } from "components/ScollToFieldError";
import { getBranches } from "store/ManageBranches/branchSlice";
import { deleteProductIngredientMapping } from "store/ManageProducts/productIngredients";
import { setCommonStatus } from "store/commonSlice";
import { deleteTasteMapping } from "store/ManageProducts/tasteSlice";
import { FetchNutrients } from "store/ManageProducts/nutrientSlice";

const ProductsForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    auth: { permissions },
    common: { status: commonStatus },
    branch: { branch: branchData },
    nutrients: { nutrients },
    product: { productToEdit, status: productStatus },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();
  const PropsRef = useRef<FormikProps<any>>();

  const ActionsRef = useRef<any>();

  const [confirmModal, setConfirmModal] = useState(false);

  const [deleteField, setDeleteField] = useState<
    "ingredients" | "tax" | "taste"
  >("ingredients");

  const [initialData, setInitialData] = useState({
    ingredients: [],
    tax: [],
    taste: [],
  });

  const avaryaBrandEditAccess = permissions.find(
    (item) => item.module_name === "Avarya Product Filter"
  );

  const initialValues = {
    print_name: "",
    expiration_days: 0,

    product_code: "",
    barcode_type: [],

    net_weight: 0,
    uom: [],

    jain_friendly: [],
    taste: [],

    category: [],
    hsn_code: [],

    sub_category: [],
    tax: [],

    selling_uom: [],
    brand: [],

    ingredients: "",
    description: "",

    is_active: [{ value: 1, label: "Active" }],

    nutrition_details:
      nutrients &&
      nutrients.map((item) => ({
        nutrient: [{ value: item.id, label: item.nutrient_name }],
        nutrition_numeric_value: 0,
        uom: [],
        new: true,
      })),

    price: [],

    minimum_selling_quantity: [],

    bulk_msq_Mon: "",
    bulk_msq_Wed: "",
    bulk_msq_Tue: "",
    bulk_msq_Thu: "",
    bulk_msq_Fri: "",
    bulk_msq_Sat: "",
    bulk_msq_Sun: "",
  };

  const handleDelete = (data) => {
    const updateValues = { ...data };

    updateValues.price.map((item) => {
      delete item?.new;
      item.store_prices.map((store) => delete store?.new);
      delete item?.bulk_mrp;
      delete item?.bulk_selling_discount;
      delete item?.bulk_selling_price;
      delete item?.bulk_minimum_selling_quantity;
      delete item?.bulk_selling_discount_type;
      delete item?.bulk_ceil;
    });
    delete updateValues?.bulk_mrp;
    delete updateValues?.bulk_selling_discount;
    delete updateValues?.bulk_selling_price;
    delete updateValues?.bulk_minimum_selling_quantity;
    delete updateValues?.bulk_selling_discount_type;
    delete updateValues?.bulk_ceil;

    delete updateValues?.bulk_msq_Mon;
    delete updateValues?.bulk_msq_Wed;
    delete updateValues?.bulk_msq_Tue;
    delete updateValues?.bulk_msq_Thu;
    delete updateValues?.bulk_msq_Fri;
    delete updateValues?.bulk_msq_Sat;
    delete updateValues?.bulk_msq_Sun;

    // updateValues.barcode_mapping.map((item) => delete item?.new);
    updateValues.nutrition_details.map((item) => delete item?.new);

    updateValues.minimum_selling_quantity.map((item) => delete item?.days);
    return updateValues;
  };

  const handleSubmit = (values, actions) => {
    // const barcodeMappingData = filterNullElements(
    //   values.barcode_mapping.map((barcodeItem) =>
    //     barcodeItem.barcode !== ""
    //       ? {
    //           ...barcodeItem,
    //           product_code: barcodeItem?.product_code,
    //           // barcode_type: barcodeItem?.barcode_type?.[0]?.value,
    //         }
    //       : null
    //   )
    // );

    const nutritionData = filterNullElements(
      values.nutrition_details.map((item, index) =>
        item.nutrition_numeric_value !== 0 &&
        item.nutrition_numeric_value !== ""
          ? {
              ...values?.nutrition_details?.[index],
              nutrient: item?.nutrient?.[0]?.value,
              nutrition_numeric_value: item?.nutrition_numeric_value,
              uom: item.uom.length > 0 ? item?.uom?.[0]?.value : null,
            }
          : null
      )
    );

    const priceData = values.price.map((item, index) => ({
      ...values?.price?.[index],
      batch_name: item?.batch_name,
      store_prices: branchData.map((branch, branchIndex) => ({
        ...values?.price?.[index]?.store_prices?.[branchIndex],
        store: branch?.id,
        mrp: values?.price?.[index]?.store_prices?.[branchIndex]?.mrp
          ? values?.price?.[index]?.store_prices?.[branchIndex]?.mrp
          : 0,
        selling_discount: values?.price?.[index]?.store_prices?.[branchIndex]
          ?.selling_discount
          ? values?.price?.[index]?.store_prices?.[branchIndex]
              ?.selling_discount
          : 0,
        selling_price: values?.price?.[index]?.store_prices?.[branchIndex]
          ?.selling_price
          ? values?.price?.[index]?.store_prices?.[branchIndex]?.selling_price
          : 0,
        minimum_selling_quantity: values?.price?.[index]?.store_prices?.[
          branchIndex
        ].minimum_selling_quantity
          ? values?.price?.[index]?.store_prices?.[branchIndex]
              ?.minimum_selling_quantity
          : 0,
        selling_discount_type: values?.price?.[index]?.store_prices?.[
          branchIndex
        ].selling_discount_type
          ? values?.price?.[index]?.store_prices?.[branchIndex]
              ?.selling_discount_type[0]?.value
          : null,
        ceil: values?.price?.[index]?.store_prices?.[branchIndex]?.ceil ? 1 : 0,
      })),
    }));

    const MSQData = branchData.map((item, index) => ({
      ...values?.minimum_selling_quantity?.[index],
      store: item?.id,
      monday: values?.minimum_selling_quantity?.[index]?.days?.Mon
        ? values?.minimum_selling_quantity?.[index]?.days?.Mon
        : 0,
      tuesday: values?.minimum_selling_quantity?.[index]?.days?.Tue
        ? values?.minimum_selling_quantity?.[index]?.days?.Tue
        : 0,
      wednesday: values?.minimum_selling_quantity?.[index]?.days?.Wed
        ? values?.minimum_selling_quantity?.[index]?.days?.Wed
        : 0,
      thursday: values?.minimum_selling_quantity?.[index]?.days?.Thu
        ? values?.minimum_selling_quantity?.[index]?.days?.Thu
        : 0,
      friday: values?.minimum_selling_quantity?.[index]?.days?.Fri
        ? values?.minimum_selling_quantity?.[index]?.days?.Fri
        : 0,
      saturday: values?.minimum_selling_quantity?.[index]?.days?.Sat
        ? values?.minimum_selling_quantity?.[index]?.days?.Sat
        : 0,
      sunday: values?.minimum_selling_quantity?.[index]?.days?.Sun
        ? values?.minimum_selling_quantity?.[index]?.days?.Sun
        : 0,
    }));

    let modifiedData = {
      ...values,
      new_product_code: values?.new_product_code || values?.product_code,
      brand: values?.brand?.[0]?.value,
      category: values?.category?.[0]?.value,
      sub_category: values?.sub_category?.[0]?.value,
      hsn_code: values?.hsn_code?.[0]?.value,
      uom: values?.uom?.[0]?.value,
      selling_uom: values?.selling_uom?.[0]?.value,
      tax: values?.tax?.map((item) => item?.value),
      jain_friendly: values?.jain_friendly?.[0]?.value,
      taste: values?.taste?.map((item) => Number(item.value)),
      barcode_type: values?.barcode_type?.[0]?.value,
      nutrition_details: nutritionData,
      is_active: id !== undefined ? values?.is_active?.[0]?.value : 1,
      price: priceData,
      minimum_selling_quantity: MSQData,
    };

    if (id) {
      modifiedData = {
        ...modifiedData,
        taste: getNewValues(values.taste, initialData.taste),
        tax: getNewValues(values.tax, initialData.tax),
      };
    }

    const dataToSend = handleDelete(modifiedData);

    if (
      dataToSend?.brand !== ACCESS_CONSTANTS.AVARYA_BRAND_ID &&
      dataToSend?.barcode_type !== PRODUCTS_CONSTANTS.UPC_BARCODE_ID
    ) {
      Toast(
        "Avarya & Meddler Barcode Type is not applicable to Non-Avarya brand products",
        "error"
      );
      return;
    } else if (
      dataToSend?.brand === ACCESS_CONSTANTS.AVARYA_BRAND_ID &&
      dataToSend?.barcode_type === PRODUCTS_CONSTANTS.UPC_BARCODE_ID
    ) {
      Toast(
        "UPC Barcode Type is not applicable to Avarya brand products",
        "error"
      );
      return;
    }

    if (
      dataToSend?.brand === ACCESS_CONSTANTS.AVARYA_BRAND_ID &&
      !(avaryaBrandEditAccess?.perm_add || avaryaBrandEditAccess?.perm_edit)
    ) {
      Toast("You do not have access to add Avarya Products", "error");
      actions.resetForm();
      actions.setSubmitting(false);
      navigate(-1);
    } else {
      if (id) {
        dispatch(
          updateExistingProduct(
            Number(id),
            dataToSend,
            actions,
            navigate,
            () => {
              ScrollToTop();
            }
          )
        );
      } else {
        dispatch(addNewProduct(dataToSend, actions, navigate));
      }
    }
  };

  useEffect(() => {
    ScrollToTop();
    dispatch(getBranches({ active: true }));
    dispatch(FetchNutrients(true));
    dispatch(
      FetchSupportingData({
        id: Number(id),
        editOptions: {
          edit: id !== undefined,
          setInitialData,
          navigate,
          props: PropsRef.current,
        },
      })
    );

    return () => {
      dispatch(setProductToEdit(null));
      PropsRef.current.resetForm();
    };
  }, []);

  return (
    <div className="content-wrapper">
      <ContentHeader
        editValue={productToEdit?.print_name}
        pageHeader={
          id ? PRODUCTS_CONSTANTS.EDIT_HEADER : PRODUCTS_CONSTANTS.ADD_HEADER
        }
      />
      <Container>
        <div className="col-12">
          {commonStatus.state === STATUSES.LOADING && (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}
        </div>
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            initialValues={
              id && productToEdit !== null ? productToEdit : initialValues
            }
            validationSchema={ProductSchema(
              id &&
                productToEdit !== null &&
                productToEdit?.brand?.[0]?.value ===
                  ACCESS_CONSTANTS.AVARYA_BRAND_ID
            )}
            onSubmit={handleSubmit}
          >
            {(props) => {
              PropsRef.current = props;
              return (
                <Form>
                  <ScrollToFieldError />
                  <div className="row">
                    {/* Product Details */}
                    <ProductDetailsForm
                      props={PropsRef.current}
                      actionsRef={ActionsRef}
                      setDeleteField={setDeleteField}
                      setConfirmModal={setConfirmModal}
                    />
                    {/* Barcode Mapping */}
                    {/* {(props.values.barcode_type?.[0]?.value ===
                      PRODUCTS_CONSTANTS.UPC_BARCODE_ID ||
                      id !== undefined) && (
                      <ProductBarcodeMappingForm props={props} />
                    )} */}
                    {/* Nutrition Details */}
                    <ProductNutritionDetailsForm props={props} />
                    {/* Batch-wise Pricing Details */}
                    <ProductBatchWisePricingForm props={props} />
                    {/* MSQ Details*/}
                    <ProductMSQDetailsForm props={props} />
                    {/* Submit */}
                    <div className="col-md-6">
                      <Button
                        type={BUTTON_CONSTANTS.SUBMIT}
                        text={FORM_CONSTANTS.SUBMIT}
                        btnClassNames={"btn btn-primary align-self-center w-25"}
                        loading={productStatus === STATUSES.LOADING}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={""}
        message={"Are you sure you want to delete the option ?"}
        confirmClick={() => {
          handleOptionDelete(
            deleteField,
            PropsRef.current,
            ActionsRef.current,
            (value) => {
              switch (deleteField) {
                case "ingredients":
                  dispatch(
                    deleteProductIngredientMapping({
                      product: Number(id),
                      ingredient: value,
                    })
                  );
                  break;

                case "tax":
                  dispatch(deleteProductTaxMapping(value));
                  break;

                case "taste":
                  dispatch(deleteTasteMapping(value));
                  break;
              }
            },
            (value) => {
              setInitialData((prev) => {
                return {
                  ...prev,
                  [deleteField]:
                    ActionsRef.current.action !== "clear"
                      ? prev[deleteField].filter((item) => item.value !== value)
                      : [],
                };
              });
            },
            (value) => {
              dispatch(
                setCommonStatus({
                  state: value,
                  type: "deleteProductMapping",
                })
              );
            }
          );
          setConfirmModal(false);
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { ProductsForm };
