import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  ContentHeader,
  CustomInput,
  MultiSelect,
} from "components";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { getBranches } from "store/ManageBranches/branchSlice";
import { GenerateKPIReport } from "store/Reports";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  KPIReportSchema,
  ORDER_CONSTANTS,
  REPORTS_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";

const KPIReport = () => {
  const dispatch = useAppDispatch();

  const {
    branch: { branch: branchData, selectedStore, status: branchStatus },
    kpiReport: { status: kpiReportStatus, error: kpiReportError },
  } = useAppSelector((state) => state.root);

  const getStoreOptions = useMemo(() => {
    return branchData && branchData.length > 0
      ? branchData.map((item) => ({
          value: item.id,
          label: item.store_name,
        }))
      : [];
  }, [branchData]);

  useEffect(() => {
    dispatch(getBranches({ active: true }));
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <Container>
        <div className="col-12 d-flex flex-column">
          <ContentHeader pageHeader={REPORTS_CONSTANTS.KPI_HEADER} />
          <Card cardClassNames="p-0">
            <CardHeader>
              <Formik
                initialValues={{
                  start_date: moment().subtract(1, "days").format("YYYY-MM-DD"),
                  end_date: moment
                    .min(moment(), moment().add(1, "M").subtract(1, "d"))
                    .format("YYYY-MM-DD"),
                  selected_store:
                    getStoreOptions.length === 1
                      ? [
                          {
                            value: getStoreOptions[0].value,
                            label: getStoreOptions[0].label,
                          },
                        ]
                      : [],
                }}
                onSubmit={(values) => {
                  dispatch(
                    GenerateKPIReport({
                      ...values,
                      selected_store: values.selected_store.map(
                        (item) => item.value
                      ),
                    })
                  );
                }}
                validationSchema={KPIReportSchema}
              >
                {(props) => {
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-md-4">
                          <CustomInput
                            label={ORDER_CONSTANTS.START_DATE_LABEL}
                            // isRequired={true}
                            name={ORDER_CONSTANTS.START_DATE}
                            type={FORM_CONSTANTS.DATE}
                            max={new Date().toISOString().split("T")[0]}
                            onChangeHandler={(e) => {
                              props.setFieldValue(
                                ORDER_CONSTANTS.START_DATE,
                                e.target.value
                              );
                              props.setFieldValue(
                                ORDER_CONSTANTS.END_DATE,
                                moment
                                  .min(
                                    moment(),
                                    moment(e.target.value)
                                      .add(1, "M")
                                      .subtract(1, "d")
                                  )
                                  .format("YYYY-MM-DD")
                              );
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <CustomInput
                            label={ORDER_CONSTANTS.END_DATE_LABEL}
                            // isRequired={true}
                            name={ORDER_CONSTANTS.END_DATE}
                            type={FORM_CONSTANTS.DATE}
                            min={props?.values?.start_date}
                            max={moment
                              .min(
                                moment(),
                                moment(props?.values?.start_date)
                                  .add(1, "M")
                                  .subtract(1, "d")
                              )
                              .format("YYYY-MM-DD")}
                            onChangeHandler={(e) => {
                              props.setFieldValue(
                                ORDER_CONSTANTS.END_DATE,
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <MultiSelect
                            select={false}
                            label={REPORTS_CONSTANTS.SELECT_STORE_LABEL}
                            name={REPORTS_CONSTANTS.SELECT_STORE}
                            options={getStoreOptions}
                            placeholder={REPORTS_CONSTANTS.SELECT_STORE_LABEL}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 offset-8 text-end">
                          <Button
                            btnClassNames="btn btn-primary"
                            type={BUTTON_CONSTANTS.SUBMIT}
                            loading={kpiReportStatus === STATUSES.LOADING}
                            isDisabled={!props.isValid || !props.dirty}
                            text={REPORTS_CONSTANTS.GENERATE}
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </CardHeader>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export { KPIReport };
