import React from "react";
import { Button } from "./Button";
import { FontIcon } from "./FontIcon";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  type?: "button" | "submit" | "reset" | undefined;
  btnClassNames?: string;
  isDisabled?: boolean;
  loading?: boolean;
  onClickHandler?: () => void;
  onBlurHandler?: () => void;
  onKeyDownHandler?: () => void;
  icon?: IconDefinition;
  iconSize?: "2xs" | "xs" | "sm" | "lg" | "xl" | "2xl";
  btnText?: string;
  btnTextSize?: string;
  align?: "center" | "left" | "right";
  testId?: string;
}

const IconButton = ({
  btnText,
  btnTextSize,
  type,
  testId,
  btnClassNames,
  isDisabled,
  onClickHandler,
  onBlurHandler,
  onKeyDownHandler,
  icon,
  iconSize,
  loading,
}: IProps) => {
  return (
    <Button
      testId={testId}
      type={type}
      onClickHandler={onClickHandler}
      isDisabled={isDisabled}
      btnClassNames={`${btnClassNames} rounded-lg`}
      onBlurHandler={onBlurHandler}
      onKeyDownHandler={onKeyDownHandler}
      text={
        <div className={`d-flex flex-row align-items-center`}>
          {loading ? (
            <span
              className="spinner-border spinner-border-sm mx-2 px-1"
              aria-hidden="true"
            />
          ) : (
            <FontIcon icon={icon} iconClassNames="col-1" iconSize={iconSize} />
          )}
          <h6
            className={`col-11 ${
              loading ? "ml-1" : ""
            } ${btnTextSize} text-start mt-2`}
          >
            {btnText}
            {/* {loading && (
              <span
                className="spinner-border spinner-border-sm mr-2"
                aria-hidden="true"
              />
            )}
            <span role="status">{loading ? `${btnText}...` : btnText}</span> */}
          </h6>
        </div>
      }
    />
  );
};

export { IconButton };
