import { faAdd, faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  FontIcon,
  MultiSelect,
  NoData,
  Table,
  TableBody,
  TableHeader,
} from "components";
import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FormikProps,
} from "formik";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteProductGroupProductMapping } from "store/ManageProducts/productGroupSlice";
import {
  clearProducts,
  getIndividualProduct,
  getProducts,
  getProductsForSpecificStore,
  getProductsOnScan,
} from "store/ManageProducts/productSlice";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  PRODUCT_GROUPS_CONSTANTS,
  ProductGroupItemEdit,
  STATUSES,
  calculateSellingPrice,
  constructProductOptions,
  // constructProductOptions,
  containsNumbers,
  debounce,
  displayValue,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { array } from "yup";
import { POS_CONSTANTS } from "../../../utils/constants";
import { updatePageNo } from "store/commonSlice";

const ProductTableDetails = ({ props, batches, setBatches, branchData }) => {
  const dispatch = useAppDispatch();

  const {
    product: { products: productData, status: productStatus },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();

  const [query, setQuery] = useState("");

  const ArrayHelperRef = useRef<FieldArrayRenderProps>();

  // const getProductOptions: any[] = useMemo(() => {
  //   return constructProductOptions(productData);
  // }, [productData?.length]);
  // const getProductOptions = useMemo(() => {
  //   return productData && productData.length > 0
  //     ? productData.map((item, index) => ({
  //         value: item.id,
  //         label: item.print_name,
  //       }))
  //     : [];
  // }, [productData]);

  const getProductOptions = useMemo(() => {
    return constructProductOptions(productData);
  }, [productData?.length]);

  function AddProduct(selectedProduct) {
    //Batches for each product to display options in dropdown
    setBatches((prevState) => {
      return [
        ...prevState,
        selectedProduct && selectedProduct.price
          ? selectedProduct.price.map((item) => ({
              value: item.id,
              label: item.batch_name,
            }))
          : [],
      ];
    });

    const prices = () => {
      let prices = [];
      for (let i = 0; i < selectedProduct.price.length; i++) {
        for (let j = 0; j < selectedProduct.price[i].store_prices.length; j++) {
          prices.push({
            store: selectedProduct.price[i].store_prices[j].store,
            batch: selectedProduct.price[i].id,
            selling_price:
              selectedProduct.price[i].store_prices[j].selling_price,
            mrp: selectedProduct.price[i].store_prices[j].mrp,
          });
        }
      }
      return prices;
    };

    const net_weight = selectedProduct.net_weight
      ? `${selectedProduct.net_weight} ${
          selectedProduct?.uom ? selectedProduct?.uom?.uom_code : ""
        }`
      : "";
    //Add product to the list
    ArrayHelperRef.current.unshift({
      product: selectedProduct?.print_name ? selectedProduct.print_name : "",
      product_id: selectedProduct?.id ? selectedProduct.id : "",
      net_weight: net_weight,
      selling_price: to2Decimal(
        selectedProduct.price[0].store_prices[0].selling_price
      ),
      batch:
        selectedProduct.price.length === 1
          ? selectedProduct.price.map((item) => ({
              value: item.id,
              label: item.batch_name,
            }))
          : "",
      batches: selectedProduct.price.map((item) => ({
        value: item.id,
        label: item.batch_name,
      })),
      total_amount:
        selectedProduct.price.length === 1
          ? `${POS_CONSTANTS.RUPEE_SIGN} ${to2Decimal(
              Number(
                selectedProduct.quantity !== undefined
                  ? (selectedProduct.price[0].store_prices[0].selling_price *
                      selectedProduct.quantity) /
                      1000
                  : selectedProduct.price[0].store_prices[0].selling_price
              )
            )}`
          : 0,
      quantity:
        selectedProduct.quantity !== undefined
          ? selectedProduct.quantity / 1000
          : 1,
      prices: prices(),
      new: true,
    });
    setQuery("");
  }

  function handlePriceChange(props: any) {
    const prices = [];

    const products = props.values.products;

    let check = false;

    if (products.length > 0) {
      for (let i = 0; i < products.length; i++) {
        for (let j = 0; j < products[i].prices.length; j++) {
          if (products[i].prices[j].batch === products[i]?.batch[0]?.value) {
            check = true;
            prices.push({
              batch: products[i].prices[j].batch,
              store: products[i].prices[j].store,
              total: products[i].prices[j].selling_price * products[i].quantity,
            });
          }
        }
      }
    }

    for (let i in props.values.products) {
      if (props.values.products[i].batch?.[0]?.label?.length > 0) {
        const findtest = prices.find(
          (item) => item.batch === props.values.products[i].batch[0].value
        );
        props.setFieldValue(
          `${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRODUCTS}[${i}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_TOTAL}`,
          `${POS_CONSTANTS.RUPEE_SIGN} ${to2Decimal(findtest.total)}`
        );
      } else {
        props.setFieldValue(
          `${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRODUCTS}[${i}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_TOTAL}`,
          `${POS_CONSTANTS.RUPEE_SIGN} ${to2Decimal(0)}`
        );
      }
    }

    const total = {};

    for (let t of prices) {
      if (total[t.store]) {
        total[t.store] += t.total;
      } else {
        total[t.store] = t.total;
      }
    }

    const bulkSellingPrice = calculateSellingPrice(
      check ? to2Decimal(Object.values(total)[0]) : 0,
      props.values?.bulk_selling_discount,
      props.values?.bulk_selling_discount_type?.[0]?.value,
      props.values?.bulk_ceil
    );

    const newPrice = branchData.map((item, index) => ({
      ...props.values.store_price[index],
      store: item.store_name,
      mrp: check ? to2Decimal(Object.values(total)[index]) : 0,
      selling_discount: props.values?.store_price?.[index]?.selling_discount,
      selling_price: calculateSellingPrice(
        check ? to2Decimal(Object.values(total)[index]) : 0,
        props.values?.store_price?.[index]?.selling_discount,
        props.values?.store_price?.[index]?.selling_discount_type?.[0]?.value,
        props.values?.store_price?.[index]?.ceil
      ),
      selling_discount_type: props.values?.bulk_selling_discount_type,
      ceil: props.values?.bulk_ceil,
    }));

    props.setFieldValue("store_price", newPrice);
    props.setFieldValue(
      "bulk_mrp",
      check ? to2Decimal(Object.values(total)[0]) : 0
    );
    props.setFieldValue("bulk_selling_price", to2Decimal(bulkSellingPrice));
  }

  const searchProducts = (value) => {
    const queryToSearch = value.trim();
    if (!containsNumbers(queryToSearch) && queryToSearch.length >= 3) {
      dispatch(getProducts(false, 1, queryToSearch, setQuery));
      // dispatch(
      //   getProductsForSpecificStore({
      //     pageNo: 1,
      //     query: queryToSearch,
      //     pos: false,
      //   })
      // );
    } else if (containsNumbers(queryToSearch) && queryToSearch.length > 3) {
      dispatch(
        getProductsOnScan({
          code: queryToSearch,
          pos: false,
          productGroup: true,
          addProduct: AddProduct,
        })
      );
      dispatch(updatePageNo(1));
      dispatch(clearProducts());
    }
  };

  const optimizedSearchProducts = useCallback(debounce(searchProducts), []);

  const productCols = FORM_CONSTANTS.NAME_QUANTITY;

  return (
    <div className="col-md-12 table-responsive">
      <Card>
        <CardHeader>
          <h5 className="card-title">
            {PRODUCT_GROUPS_CONSTANTS.CARD_TITLE_2}
          </h5>
        </CardHeader>
        <CardBody>
          <FieldArray
            name={PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRODUCTS}
            render={(arrayHelpers) => {
              ArrayHelperRef.current = arrayHelpers;
              return (
                <div className="row" text-sm>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6">
                        <MultiSelect
                          select={false}
                          name="selected_product"
                          label="Select Product"
                          options={getProductOptions}
                          inputValue={query ? query : ""}
                          onKeyDownHandler={(e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                              optimizedSearchProducts(query);
                            }
                          }}
                          onInputChangeHandler={(e) => {
                            optimizedSearchProducts(e);
                            setQuery(e);
                          }}
                          onMenuCloseHandler={() => {
                            setQuery("");
                            dispatch(clearProducts());
                          }}
                          onChangeHandler={(e, actions) => {
                            if (e && e.value) {
                              dispatch(
                                getIndividualProduct({
                                  id: e.value,
                                  addProduct: AddProduct,
                                  barcode: true,
                                })
                              );
                              setQuery("");
                              dispatch(clearProducts());
                              props.setFieldValue("selected_product", []);
                            }
                          }}
                          isLoading={productStatus === STATUSES.LOADING}
                        />
                        <ErrorMessage
                          name="selected_product"
                          component="div"
                          className="field-error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <Table testId="product-details">
                      <TableHeader cols={productCols} />
                      <TableBody>
                        {props.values.products &&
                        props.values.products.length > 0 ? (
                          props.values.products.map(
                            (product: ProductGroupItemEdit, index) => (
                              <tr key={index}>
                                <td className="col-md-3 text-wrap">
                                  <CustomInput
                                    name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRODUCTS}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_NAME}`}
                                    type={FORM_CONSTANTS.TEXT}
                                    isDisabled={true}
                                  />
                                </td>
                                <td className="col-md-3">
                                  <MultiSelect
                                    select={false}
                                    name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRODUCTS}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_BATCH}`}
                                    options={
                                      props.values.products[index].batches
                                    }
                                    onBlurHandler={() => {
                                      handlePriceChange(props);
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRODUCTS}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_BATCH}`}
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </td>
                                <td className="col-md-2">
                                  <CustomInput
                                    name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRODUCTS}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_NET_WEIGHT}`}
                                    type={FORM_CONSTANTS.TEXT}
                                    isDisabled={true}
                                  />
                                  <ErrorMessage
                                    name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRODUCTS}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_NET_WEIGHT}`}
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </td>
                                <td className="col-md-3">
                                  <Field
                                    as={CustomInput}
                                    min={0}
                                    step={0.001}
                                    name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRODUCTS}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_QUANTITY}`}
                                    type={FORM_CONSTANTS.NUMBER}
                                    placeholder={
                                      PRODUCT_GROUPS_CONSTANTS.PRODUCT_QUANTITY_PLACEHOLDER
                                    }
                                    onBlurHandler={(e) => {
                                      handlePriceChange(props);
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRODUCTS}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_QUANTITY}`}
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </td>
                                <td className="col-md-2">
                                  <CustomInput
                                    name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRODUCTS}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_TOTAL}`}
                                    type={FORM_CONSTANTS.TEXT}
                                    value={
                                      props.values.products[index].total_amount
                                    }
                                    isDisabled={true}
                                  />
                                  <ErrorMessage
                                    name={`${PRODUCT_GROUPS_CONSTANTS.PRODUCT_GROUP_PRODUCTS}[${index}].${PRODUCT_GROUPS_CONSTANTS.PRODUCT_TOTAL}`}
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </td>
                                <td className="col-md-1 text-center align-middle">
                                  <Button
                                    testId={`delete-${index}`}
                                    text={<FontIcon icon={faTrash} />}
                                    type={BUTTON_CONSTANTS.BUTTON}
                                    btnClassNames={
                                      "btn btn-danger table-button mr-3"
                                    }
                                    onClickHandler={() => {
                                      if (!props.values.products[index].new) {
                                        dispatch(
                                          deleteProductGroupProductMapping({
                                            product:
                                              props.values.products[index]
                                                .product_id,
                                            product_group: Number(id),
                                          })
                                        );
                                      }
                                      arrayHelpers.remove(index);

                                      setBatches((prevState) => {
                                        return prevState.filter(
                                          (item, i) => i !== index
                                        );
                                      });

                                      const updatedProps = {
                                        ...props,
                                        values: {
                                          ...props.values,
                                          products: props.values.products.filter(
                                            (item, i) => i !== index
                                          ),
                                        },
                                      };
                                      if (updatedProps.length === 0) {
                                        setBatches([]);
                                      }
                                      handlePriceChange(updatedProps);
                                    }}
                                  />
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <NoData len={productCols.length} />
                        )}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              );
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export { ProductTableDetails };
