import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Formik, Form, ErrorMessage } from "formik";

import { ScrollToTop, useAppDispatch, useAppSelector } from "utils/helper";
import {
  addNewBranch,
  checkEcommerceStore,
  getBranches,
  getIndividualBranch,
  getOutletTypes,
  setEcommerceStoreDetails,
  updateExistingBranch,
} from "store/ManageBranches/branchSlice";

import {
  ConfirmModal,
  ContentHeader,
  CustomInput,
  Loader,
  SelectStatus,
} from "components";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  MultiSelect,
} from "components/core-components";

import { BranchSchema } from "utils/schema";
import {
  BANK_CONSTANTS,
  BRANCH_CONSTANTS,
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  STATUSES,
} from "utils/constants";
import {
  fetchCity,
  fetchState,
  getCity,
  getCountry,
  getState,
} from "store/locationSlice";

const BranchForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const PropsRef = useRef(null);

  const {
    branch: {
      branch: branchData,
      branchToEdit,
      outletTypes: outletTypeData,
      ecommerce_store_details,
      status: branchStatus,
    },
    location: { cities, states, countries },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();

  const initialValues = {
    store_name: "",
    store_code: "",
    contact_name: "",
    contact_number: "",
    whatsapp_number: "",
    email: "",

    gstn: "",
    pan: "",
    fssai_number: "",

    outlet_type: [],

    address: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      country: "",
      pincode: 0,
    },
    purchase_billing_address: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      country: "",
      pincode: 0,
    },

    bank_name: "",
    account_no: "",
    account_holder_name: "",

    // New

    new_barcode: [
      {
        value: 0,
        label: GENERAL_CONSTANTS.NO,
      },
    ],
    advance_order_notification_enabled: [
      {
        value: 0,
        label: GENERAL_CONSTANTS.NO,
      },
    ],

    store_gmap: "",
    menu_link: "",
    show_on_ecommerce: [
      {
        value: 0,
        label: GENERAL_CONSTANTS.NO,
      },
    ],

    markup: 0,
    store_price_copy: [],
    is_ecommerce: [
      {
        value: 0,
        label: GENERAL_CONSTANTS.NO,
      },
    ],

    embeded_map_link: "",
    address_locality: "",
    latitude: "",
    longitude: "",

    is_active: [
      {
        value: 1,
        label: GENERAL_CONSTANTS.ACTIVE,
      },
    ],
  };

  const [confirmModal, setConfirmModal] = useState(false);

  const [addressFilled, setAddressFilled] = useState(
    id &&
      branchToEdit?.address?.address_line_1 !== "" &&
      branchToEdit?.address?.address_line_2 !== ""
  );

  const [purchaseAddressFilled, setPurchaseAddressFilled] = useState(
    id &&
      branchToEdit?.purchase_billing_address?.address?.address_line_1 !== "" &&
      branchToEdit?.purchase_billing_address?.address?.address_line_2 !== ""
  );

  const getBranchOptions = useMemo(() => {
    return branchData && branchData?.length > 0
      ? branchData.map((item) => ({
          value: item.id,
          label: item.store_name,
        }))
      : [];
  }, [branchData?.length]);

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      ...values,
      outlet_type: values?.outlet_type?.[0]?.value,
      is_ecommerce: values?.is_ecommerce?.[0]?.value,
      show_on_ecommerce: values?.show_on_ecommerce?.[0]?.value,
      advance_order_notification_enabled:
        values?.advance_order_notification_enabled?.[0]?.value,
      new_barcode: values?.new_barcode?.[0]?.value,
      is_active: values.is_active?.[0]?.value,
      markup: values?.markup,
      latitude: Number(values?.latitude),
      longitude: Number(values?.longitude),
      store_price_copy: values?.store_price_copy
        ? values?.store_price_copy?.[0]?.value
        : null,
      address: addressFilled
        ? {
            ...values.address,
            city: values.address?.city?.[0]?.value
              ? values.address?.city?.[0]?.value
              : FORM_CONSTANTS.DEFAULT_CITY[0].value,
            state: values.address?.state?.[0]?.value
              ? values.address?.state?.[0]?.value
              : FORM_CONSTANTS.DEFAULT_STATE[0].value,
            country: values.address?.country?.[0]?.value
              ? values.address?.country?.[0]?.value
              : FORM_CONSTANTS.DEFAULT_COUNTRY[0].value,
          }
        : {},
      purchase_billing_address: purchaseAddressFilled
        ? {
            ...values.purchase_billing_address,
            city: values.address?.city?.[0]?.value
              ? values.address?.city?.[0]?.value
              : FORM_CONSTANTS.DEFAULT_CITY[0].value,
            state: values.address?.state?.[0]?.value
              ? values.address?.state?.[0]?.value
              : FORM_CONSTANTS.DEFAULT_STATE[0].value,
            country: values.address?.country?.[0]?.value
              ? values.address?.country?.[0]?.value
              : FORM_CONSTANTS.DEFAULT_COUNTRY[0].value,
          }
        : {},
    };

    if (id) {
      dispatch(updateExistingBranch(Number(id), dataToSend, actions, navigate));
    } else {
      dispatch(addNewBranch(dataToSend, actions, navigate));
    }
  };

  useEffect(() => {
    ScrollToTop();
    const getFormData = async () => {
      await Promise.all([
        id && dispatch(getIndividualBranch(Number(id), true)),
        id === undefined && dispatch(getBranches({ active: true })),
        dispatch(getOutletTypes(true)),
        dispatch(getCountry()),
      ]);
    };
    getFormData();

    return () => {
      dispatch(setEcommerceStoreDetails(null));
    };
  }, []);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader
          editValue={branchToEdit?.store_name}
          pageHeader={
            id ? BRANCH_CONSTANTS.EDIT_HEADER : BRANCH_CONSTANTS.ADD_HEADER
          }
        />
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            initialValues={id ? branchToEdit : initialValues}
            validationSchema={BranchSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              PropsRef.current = props;
              return (
                <Form>
                  {branchStatus === STATUSES.LOADING && (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  )}
                  <div className="row">
                    {/* Branch Details: */}
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h3 className="card-title">
                            {BRANCH_CONSTANTS.CARD_TITLE_1}
                          </h3>
                        </CardHeader>
                        <CardBody>
                          <div className="row">
                            <div className="col-md-6">
                              <CustomInput
                                label={BRANCH_CONSTANTS.BRANCH_NAME_LABEL}
                                isRequired={true}
                                name={BRANCH_CONSTANTS.BRANCH_NAME}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  BRANCH_CONSTANTS.BRANCH_NAME_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.BRANCH_NAME}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                label={BRANCH_CONSTANTS.BRANCH_EMAIL_LABEL}
                                isRequired={true}
                                name={BRANCH_CONSTANTS.BRANCH_EMAIL}
                                type={FORM_CONSTANTS.EMAIL}
                                placeholder={
                                  BRANCH_CONSTANTS.BRANCH_EMAIL_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.BRANCH_EMAIL}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <CustomInput
                                label={BRANCH_CONSTANTS.BRANCH_CODE_LABEL}
                                isRequired={true}
                                name={BRANCH_CONSTANTS.BRANCH_CODE}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  BRANCH_CONSTANTS.BRANCH_CODE_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.BRANCH_CODE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            {id !== undefined && (
                              <div className="col-md-6">
                                <SelectStatus
                                  props={props}
                                  name={BRANCH_CONSTANTS.BRANCH_STATUS}
                                  label={BRANCH_CONSTANTS.BRANCH_STATUS_LABEL}
                                />
                              </div>
                            )}
                            <div className="col-md-6">
                              <CustomInput
                                label={BRANCH_CONSTANTS.CONTACT_NAME_LABEL}
                                isRequired={true}
                                name={BRANCH_CONSTANTS.CONTACT_NAME}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  BRANCH_CONSTANTS.CONTACT_NAME_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.CONTACT_NAME}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                label={BRANCH_CONSTANTS.CONTACT_NUMBER_LABEL}
                                isRequired={true}
                                name={BRANCH_CONSTANTS.CONTACT_NUMBER}
                                type={FORM_CONSTANTS.NUMBER}
                                placeholder={
                                  BRANCH_CONSTANTS.CONTACT_NUMBER_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.CONTACT_NUMBER}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                label={BRANCH_CONSTANTS.WHATSAPP_NUMBER_LABEL}
                                isRequired={true}
                                name={BRANCH_CONSTANTS.WHATSAPP_NUMBER}
                                type={FORM_CONSTANTS.NUMBER}
                                placeholder={
                                  BRANCH_CONSTANTS.WHATSAPP_NUMBER_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.WHATSAPP_NUMBER}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <CustomInput
                                label={BRANCH_CONSTANTS.GSTN_LABEL}
                                isRequired={true}
                                name={BRANCH_CONSTANTS.GSTN}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={BRANCH_CONSTANTS.GSTN_PLACEHOLDER}
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.GSTN}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                label={BRANCH_CONSTANTS.PAN_LABEL}
                                name={BRANCH_CONSTANTS.PAN}
                                isRequired={true}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={BRANCH_CONSTANTS.PAN_PLACEHOLDER}
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.PAN}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                label={
                                  BRANCH_CONSTANTS.BRANCH_OUTLET_TYPE_LABEL
                                }
                                isRequired={true}
                                name={BRANCH_CONSTANTS.BRANCH_OUTLET_TYPE}
                                options={outletTypeData.map((item) => ({
                                  value: item.id,
                                  label: item.outlet_type,
                                }))}
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.BRANCH_OUTLET_TYPE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                name={BRANCH_CONSTANTS.FSSAI}
                                label={BRANCH_CONSTANTS.FSSAI_LABEL}
                                isRequired={true}
                                type={FORM_CONSTANTS.NUMBER}
                                placeholder={BRANCH_CONSTANTS.FSSAI_PLACEHOLDER}
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.FSSAI}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <MultiSelect
                                placeholder={
                                  BRANCH_CONSTANTS.IS_ECOMMERCE_PLACEHOLDER
                                }
                                disabled={Boolean(
                                  props.values?.is_ecommerce?.[0]?.value
                                )}
                                select={false}
                                isRequired={true}
                                name={BRANCH_CONSTANTS.IS_ECOMMERCE}
                                label={BRANCH_CONSTANTS.IS_ECOMMERCE_LABEL}
                                options={BANK_CONSTANTS.UPI_AVAILABLE_OPTIONS}
                                onChangeHandler={(e) => {
                                  if (Boolean(e?.value)) {
                                    dispatch(
                                      checkEcommerceStore(
                                        props,
                                        setConfirmModal
                                      )
                                    );
                                  }
                                }}
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.IS_ECOMMERCE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>

                            <div className="col-md-6">
                              <MultiSelect
                                disabled={Boolean(
                                  props.values?.is_ecommerce?.[0]?.value
                                )}
                                placeholder={
                                  BRANCH_CONSTANTS.SHOW_ECOMMERCE_PLACEHOLDER
                                }
                                select={false}
                                isRequired={true}
                                name={BRANCH_CONSTANTS.SHOW_ECOMMERCE}
                                label={BRANCH_CONSTANTS.SHOW_ECOMMERCE_LABEL}
                                options={BANK_CONSTANTS.UPI_AVAILABLE_OPTIONS}
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.SHOW_ECOMMERCE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                label={BRANCH_CONSTANTS.EMBEDED_LINK_LABEL}
                                isRequired={Boolean(
                                  props.values?.show_on_ecommerce?.[0]?.value
                                )}
                                name={BRANCH_CONSTANTS.EMBEDED_LINK}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  BRANCH_CONSTANTS.EMBEDED_LINK_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.EMBEDED_LINK}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                name={BRANCH_CONSTANTS.MENU_LINK}
                                label={BRANCH_CONSTANTS.MENU_LINK_LABEL}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  BRANCH_CONSTANTS.MENU_LINK_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.MENU_LINK}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                isRequired={true}
                                name={
                                  BRANCH_CONSTANTS.ENABLE_ADVANCE_ORDER_NOTIFICATIONS
                                }
                                label={
                                  BRANCH_CONSTANTS.ENABLE_ADVANCE_ORDER_NOTIFICATIONS_LABEL
                                }
                                options={BANK_CONSTANTS.UPI_AVAILABLE_OPTIONS}
                              />
                              <ErrorMessage
                                name={
                                  BRANCH_CONSTANTS.ENABLE_ADVANCE_ORDER_NOTIFICATIONS
                                }
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>{" "}
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                isRequired={true}
                                name={BRANCH_CONSTANTS.ENABLE_NEW_PRODUCT_CODE}
                                label={
                                  BRANCH_CONSTANTS.ENABLE_NEW_PRODUCT_CODE_LABEL
                                }
                                options={BANK_CONSTANTS.UPI_AVAILABLE_OPTIONS}
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.ENABLE_NEW_PRODUCT_CODE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            {id === undefined && (
                              <>
                                <div className="col-md-6">
                                  <MultiSelect
                                    placeholder={
                                      BRANCH_CONSTANTS.COPY_STORE_PLACEHOLDER
                                    }
                                    select={false}
                                    isRequired={false}
                                    name={BRANCH_CONSTANTS.COPY_STORE}
                                    label={BRANCH_CONSTANTS.COPY_STORE_LABEL}
                                    isLoading={
                                      branchStatus === STATUSES.LOADING
                                    }
                                    onFocusHandler={() => {
                                      branchData.length === 0 &&
                                        dispatch(getBranches({ active: true }));
                                    }}
                                    options={getBranchOptions}
                                  />
                                  <ErrorMessage
                                    name={BRANCH_CONSTANTS.COPY_STORE}
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                                {props.values?.store_price_copy?.[0]?.value && (
                                  <div className="col-md-6">
                                    <CustomInput
                                      label={BRANCH_CONSTANTS.MARKUP_LABEL}
                                      isRequired={true}
                                      name={BRANCH_CONSTANTS.MARKUP}
                                      type={FORM_CONSTANTS.NUMBER}
                                      step="0.01"
                                      min="0"
                                      placeholder={
                                        BRANCH_CONSTANTS.MARKUP_PLACEHOLDER
                                      }
                                    />
                                    <ErrorMessage
                                      name={BRANCH_CONSTANTS.MARKUP}
                                      component={FORM_CONSTANTS.ERROR_PARENT}
                                      className={FORM_CONSTANTS.ERROR}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h3 className="card-title">
                            {BRANCH_CONSTANTS.CARD_TITLE_5}
                          </h3>
                        </CardHeader>
                        <CardBody>
                          <div className="row">
                            <div className="col-md-6">
                              <CustomInput
                                name={BRANCH_CONSTANTS.STORE_GMAP}
                                isRequired={Boolean(
                                  props.values?.show_on_ecommerce?.[0]?.value
                                )}
                                label={BRANCH_CONSTANTS.STORE_GMAP_LABEL}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  BRANCH_CONSTANTS.STORE_GMAP_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.STORE_GMAP}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>

                            <div className="col-md-6">
                              <CustomInput
                                label={BRANCH_CONSTANTS.ADDRESS_LOCALITY_LABEL}
                                isRequired={Boolean(
                                  props.values?.show_on_ecommerce?.[0]?.value
                                )}
                                name={BRANCH_CONSTANTS.ADDRESS_LOCALITY}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  BRANCH_CONSTANTS.ADDRESS_LOCALITY_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.ADDRESS_LOCALITY}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <CustomInput
                                label={BRANCH_CONSTANTS.LATITUDE_LABEL}
                                isRequired={Boolean(
                                  props.values?.show_on_ecommerce?.[0]?.value
                                )}
                                name={BRANCH_CONSTANTS.LATITUDE}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  BRANCH_CONSTANTS.LATITUDE_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.LATITUDE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                label={BRANCH_CONSTANTS.LONGITUDE_LABEL}
                                isRequired={Boolean(
                                  props.values?.show_on_ecommerce?.[0]?.value
                                )}
                                name={BRANCH_CONSTANTS.LONGITUDE}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  BRANCH_CONSTANTS.LONGITUDE_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.LONGITUDE}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    {/* Branch Address: */}
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h3 className="card-title">
                            {BRANCH_CONSTANTS.CARD_TITLE_2}
                          </h3>
                        </CardHeader>
                        <CardBody>
                          <div className="row">
                            <div className="col-12">
                              <CustomInput
                                name={`${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.ADDRESS_LINE_1}`}
                                label={BRANCH_CONSTANTS.ADDRESS_LINE_1_LABEL}
                                isRequired={true}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  BRANCH_CONSTANTS.ADDRESS_LINE_1_PLACEHOLDER
                                }
                                onChangeHandler={(e) => {
                                  props.setFieldValue(
                                    `${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.ADDRESS_LINE_1}`,
                                    e.target.value
                                  );
                                  setAddressFilled(
                                    props.values.address.address_line_1 !==
                                      "" &&
                                      props.values.address.address_line_2 !== ""
                                  );
                                }}
                              />
                              <ErrorMessage
                                name={`${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.ADDRESS_LINE_1}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <CustomInput
                                name={`${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.ADDRESS_LINE_2}`}
                                label={BRANCH_CONSTANTS.ADDRESS_LINE_2_LABEL}
                                isRequired={true}
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  BRANCH_CONSTANTS.ADDRESS_LINE_2_PLACEHOLDER
                                }
                                onChangeHandler={(e) => {
                                  props.setFieldValue(
                                    `${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.ADDRESS_LINE_2}`,
                                    e.target.value
                                  );
                                  setAddressFilled(
                                    props.values.address.address_line_1 !==
                                      "" ||
                                      props.values.address.address_line_2 !== ""
                                  );
                                }}
                              />
                              <ErrorMessage
                                name={`${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.ADDRESS_LINE_2}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                label={BRANCH_CONSTANTS.BRANCH_COUNTRY_LABEL}
                                isRequired={true}
                                disabled={!addressFilled}
                                name={`${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.BRANCH_COUNTRY}`}
                                options={countries.map((country) => ({
                                  value: country.id,
                                  label: country.country,
                                }))}
                                onChangeHandler={(e) => {
                                  e
                                    ? dispatch(getState(e.value))
                                    : dispatch(fetchState([]));
                                  props.setFieldValue("address.country", [e]);
                                }}
                              />
                              <ErrorMessage
                                name={`${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.BRANCH_COUNTRY}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                disabled={!addressFilled}
                                label={BRANCH_CONSTANTS.BRANCH_STATE_LABEL}
                                isRequired={true}
                                name={`${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.BRANCH_STATE}`}
                                options={states.map((state) => ({
                                  value: state.id,
                                  label: state.state,
                                }))}
                                onChangeHandler={(e) => {
                                  e
                                    ? dispatch(getCity(e.value))
                                    : dispatch(fetchCity([]));
                                  props.setFieldValue("address.state", [e]);
                                }}
                              />
                              <ErrorMessage
                                name={`${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.BRANCH_STATE}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <MultiSelect
                                select={false}
                                label={BRANCH_CONSTANTS.BRANCH_CITY_LABEL}
                                isRequired={true}
                                disabled={!addressFilled}
                                name={`${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.BRANCH_CITY}`}
                                options={cities.map((city) => ({
                                  value: city.id,
                                  label: city.city,
                                }))}
                              />
                              <ErrorMessage
                                name={`${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.BRANCH_CITY}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                name={`${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.BRANCH_PINCODE}`}
                                label={BRANCH_CONSTANTS.BRANCH_PINCODE_LABEL}
                                isRequired={true}
                                isDisabled={!addressFilled}
                                type={FORM_CONSTANTS.NUMBER}
                                placeholder={
                                  BRANCH_CONSTANTS.BRANCH_PINCODE_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={`${BRANCH_CONSTANTS.LOCATION}.${BRANCH_CONSTANTS.BRANCH_PINCODE}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    {/* Branch Billing Address: */}
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h5 className="card-title">
                            {BRANCH_CONSTANTS.CARD_TITLE_4}
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div className="row">
                            <div className="row">
                              <div className="col-12">
                                <CustomInput
                                  name={`${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.ADDRESS_LINE_1}`}
                                  label={
                                    BRANCH_CONSTANTS.BILLING_ADDRESS_LINE_1_LABEL
                                  }
                                  isRequired={true}
                                  type={FORM_CONSTANTS.TEXT}
                                  placeholder={
                                    BRANCH_CONSTANTS.BILLING_ADDRESS_LINE_1_PLACEHOLDER
                                  }
                                  onChangeHandler={(e) => {
                                    props.setFieldValue(
                                      `${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.ADDRESS_LINE_1}`,
                                      e.target.value
                                    );
                                    setPurchaseAddressFilled(
                                      props.values.purchase_billing_address
                                        .address_line_1 !== "" &&
                                        props.values.purchase_billing_address
                                          .address_line_2 !== ""
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name={`${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.ADDRESS_LINE_1}`}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <CustomInput
                                  name={`${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.ADDRESS_LINE_2}`}
                                  label={
                                    BRANCH_CONSTANTS.BILLING_ADDRESS_LINE_2_LABEL
                                  }
                                  isRequired={true}
                                  type={FORM_CONSTANTS.TEXT}
                                  placeholder={
                                    BRANCH_CONSTANTS.BILLING_ADDRESS_LINE_2_PLACEHOLDER
                                  }
                                  onChangeHandler={(e) => {
                                    props.setFieldValue(
                                      `${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.ADDRESS_LINE_2}`,
                                      e.target.value
                                    );
                                    setPurchaseAddressFilled(
                                      props.values.purchase_billing_address
                                        .address_line_1 !== "" ||
                                        props.values.purchase_billing_address
                                          .address_line_2 !== ""
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name={`${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.ADDRESS_LINE_2}`}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <MultiSelect
                                  select={false}
                                  label={BRANCH_CONSTANTS.BILLING_COUNTRY_LABEL}
                                  isRequired={true}
                                  disabled={!purchaseAddressFilled}
                                  name={`${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.BRANCH_COUNTRY}`}
                                  options={countries.map((country) => ({
                                    value: country.id,
                                    label: country.country,
                                  }))}
                                  onChangeHandler={(e) => {
                                    e
                                      ? dispatch(getState(e.value))
                                      : dispatch(fetchState([]));
                                    props.setFieldValue(
                                      "purchase_billing_address.country",
                                      [e]
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name={`${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.BRANCH_COUNTRY}`}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div className="col-md-6">
                                <MultiSelect
                                  select={false}
                                  label={BRANCH_CONSTANTS.BILLING_STATE_LABEL}
                                  isRequired={true}
                                  disabled={!purchaseAddressFilled}
                                  name={`${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.BRANCH_STATE}`}
                                  options={states.map((state) => ({
                                    value: state.id,
                                    label: state.state,
                                  }))}
                                  onChangeHandler={(e) => {
                                    e
                                      ? dispatch(getCity(e.value))
                                      : dispatch(fetchCity([]));
                                    props.setFieldValue(
                                      "purchase_billing_address.state",
                                      [e]
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name={`${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.BRANCH_STATE}`}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <MultiSelect
                                  select={false}
                                  label={BRANCH_CONSTANTS.BILLING_CITY_LABEL}
                                  isRequired={true}
                                  disabled={!purchaseAddressFilled}
                                  name={`${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.BRANCH_CITY}`}
                                  options={cities.map((city) => ({
                                    value: city.id,
                                    label: city.city,
                                  }))}
                                />
                                <ErrorMessage
                                  name={`${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.BRANCH_CITY}`}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div className="col-md-6">
                                <CustomInput
                                  name={`${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.BRANCH_PINCODE}`}
                                  label={BRANCH_CONSTANTS.BILLING_PINCODE_LABEL}
                                  isDisabled={!purchaseAddressFilled}
                                  isRequired={true}
                                  type={FORM_CONSTANTS.NUMBER}
                                  placeholder={
                                    BRANCH_CONSTANTS.BILLING_PINCODE_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={`${BRANCH_CONSTANTS.BILLING_ADDRESS}.${BRANCH_CONSTANTS.BRANCH_PINCODE}`}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <Input
                                  inputClassNames="mr-2"
                                  id="sameAddId"
                                  type="checkbox"
                                  onChangeHandler={(e) => {
                                    setPurchaseAddressFilled(true);
                                    props.setFieldValue(
                                      BRANCH_CONSTANTS.BILLING_ADDRESS,
                                      {
                                        address_line_1:
                                          props.values.address.address_line_1,
                                        address_line_2:
                                          props.values.address.address_line_2,
                                        city: props.values.address.city,
                                        state: props.values.address.state,
                                        country: props.values.address.country,
                                        pincode: props.values.address.pincode,
                                      }
                                    );
                                  }}
                                />
                                Same as Branch Address
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    {/* Branch Bank Details: */}
                    <div className="col-md-12">
                      <Card>
                        <CardHeader>
                          <h3 className="card-title">
                            {BRANCH_CONSTANTS.CARD_TITLE_3}
                          </h3>
                        </CardHeader>
                        <CardBody>
                          <div className="row">
                            <div className="col-md-6">
                              <CustomInput
                                name={BRANCH_CONSTANTS.BANK_ACCOUNT_NUMBER}
                                label={
                                  BRANCH_CONSTANTS.BANK_ACCOUNT_NUMBER_LABEL
                                }
                                type={FORM_CONSTANTS.NUMBER}
                                placeholder={
                                  BRANCH_CONSTANTS.BANK_ACCOUNT_NUMBER_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.BANK_ACCOUNT_NUMBER}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-6">
                              <CustomInput
                                name={BRANCH_CONSTANTS.BANK_ACCOUNT_HOLDER_NAME}
                                label={
                                  BRANCH_CONSTANTS.BANK_ACCOUNT_HOLDER_NAME_LABEL
                                }
                                type={FORM_CONSTANTS.TEXT}
                                placeholder={
                                  BRANCH_CONSTANTS.BANK_ACCOUNT_HOLDER_NAME_PLACEHOLDER
                                }
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.BANK_ACCOUNT_HOLDER_NAME}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {/* <MultiSelect
                          select={false}
                          label={BRANCH_CONSTANTS.BANK_NAME_LABEL}
                          isRequired={true}
                          name={BRANCH_CONSTANTS.BANK_NAME}
                          options={bankData.map((bank) => ({
                            value: bank.id,
                            label: bank.bank_name,
                          }))}
                        /> */}
                              <CustomInput
                                label={BRANCH_CONSTANTS.BANK_NAME_LABEL}
                                name={BRANCH_CONSTANTS.BANK_NAME}
                                placeholder={
                                  BRANCH_CONSTANTS.BANK_NAME_PLACEHOLDER
                                }
                                type={FORM_CONSTANTS.TEXT}
                              />
                              <ErrorMessage
                                name={BRANCH_CONSTANTS.BANK_NAME}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    {/* Submit Button */}
                    <div className="col-xl-4 col-md-6">
                      <Button
                        loading={branchStatus === STATUSES.LOADING}
                        type={BUTTON_CONSTANTS.SUBMIT}
                        text={FORM_CONSTANTS.SUBMIT}
                        btnClassNames={"btn btn-primary align-self-center w-25"}
                        onClickHandler={() => console.log(props.errors)}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
      {confirmModal && ecommerce_store_details && (
        <ConfirmModal
          modal={confirmModal}
          setModal={setConfirmModal}
          title="Change Store Confirmation"
          message={`You are about to change the current ecommerce store: "${ecommerce_store_details?.store_name}". Do you wish to proceed?`}
          confirmClick={() => {
            PropsRef.current.setFieldValue(BRANCH_CONSTANTS.IS_ECOMMERCE, [
              BANK_CONSTANTS.UPI_AVAILABLE_OPTIONS[0],
            ]);
            setConfirmModal(false);
          }}
          rejectClick={() => {
            setConfirmModal(false);
          }}
        />
      )}
    </div>
  );
};

export { BranchForm };
