import {
  Accordion,
  Button,
  FontIcon,
  Table,
  TableBody,
} from "components/core-components";
import React, { useMemo, useRef, useState } from "react";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  ICartItem,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  STATUSES,
  checkMasterPassword,
  displayValue,
  filterCartItems,
  flattenArray,
  formatModules,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { POSCarItems } from "./POSCarItems";
import { Loader } from "components/Loader";
import {
  RemoveProduct,
  RemoveProductGroup,
  ResetWholeCart,
  setProductGroupDetails,
} from "store/POS";
import {
  faAdd,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { AddProductToGroup } from "./AddProductToGroup";
import { MasterPasswordModal } from "./MasterPasswordModal";
import { ConfirmModal } from "components/ConfirmModal";

/**
 * Renders the POS Cart component.
 *
 * @param {React.RefObject<any>} focusRef - The reference to the focus element.
 * @returns {JSX.Element} The rendered POS Cart component.
 */

interface IProps {
  focusRef: React.MutableRefObject<any>;
  batchModal: boolean;
  setBatchModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const POSCart = ({ focusRef, batchModal, setBatchModal }: IProps) => {
  const dispatch = useAppDispatch();

  const {
    auth: { permissions },
    branch: { selectedStore },
    common: { portrait, master_password_verified },
    cart: { cart, status: cartStatus },
    module: { modules },
  } = useAppSelector((state) => state.root);

  const [activeBatchItem] = useState(null);

  const [masterPasswordModal, setMasterPasswordModal] = useState({
    state: false,
    productGrpID: null,
  });

  const [addToProductGroup, setAddToProductGroup] = useState(false);

  const isMobile = window.mobileAndTabletCheck();

  const addToProductGrpSearchRef = useRef(null);

  const [confirmModal, setConfirmModal] = useState<boolean>(
    GENERAL_CONSTANTS.FALSE
  );

  const [dataToDelete, setDataToDelete] = useState(null);

  const RESPONSIVE_HEADERS = useMemo(() => {
    if (isMobile) {
      return [
        ...POS_CART_CONSTANTS.POS_CART_HEADERS.slice(0, 1),
        {
          title: "Product Details",
          colSpan: 2,
          className: "text-sm align-middle",
        },
        {
          title: "Quantity",
          colSpan: 2,
          className: "text-sm align-middle text-center",
        },
        ...POS_CART_CONSTANTS.POS_CART_HEADERS.slice(4),
      ];
    }
    return POS_CART_CONSTANTS.POS_CART_HEADERS;
  }, [isMobile, selectedStore]);

  const hasProductGroups = useMemo(() => {
    return filterCartItems(cart.cartItems).some(
      (item) => item[0] !== "undefined"
    );
  }, [cart.cartItems?.length]);

  const findSubModuleId = useMemo(() => {
    const moduleData = flattenArray(formatModules(modules));

    return (
      moduleData &&
      moduleData?.length > 0 &&
      moduleData?.find((item) => item.module_name === "Modify POS Entry")
        ?.module
    );
  }, [modules?.length]);

  const canEditProductGroup = useMemo(
    () =>
      permissions.find((item) => item.module_name === "Modify POS Entry")
        ?.perm_authorize,
    [permissions?.length]
  );

  const updateProductGroupPrice = (items: ICartItem[]) => {
    return items.reduce((acc, item) => acc + item.mrp, 0);
  };

  const updateProductGroupTax = (items: ICartItem[]) => {
    return items.reduce((acc, item) => acc + item.tax_applied, 0);
  };

  const updateProductGroupUnitCost = (items: ICartItem[]) => {
    return items.reduce(
      (acc, item) => acc + to2Decimal(item.payable_amount / item.quantity),
      0
    );
  };

  const updateProductGroupNetAmount = (items: ICartItem[]) => {
    return items.reduce((acc, item) => acc + item.payable_amount, 0);
  };

  const expandProductGroup = (grpIndex) => {
    const button = document?.querySelector(
      `button[data-bs-target='#item${grpIndex}']`
    );

    const accordionBody = document.getElementById(`item${grpIndex}`);

    if (button) {
      button?.classList.remove("collapsed");
      button.setAttribute("aria-expanded", "true");
    } else {
      console.error(`Button with name "button${grpIndex}" not found!`);
    }

    if (accordionBody) {
      accordionBody?.classList.add("show");
      accordionBody?.classList?.remove("collapse");
    } else {
      console.error(`Accordion with name "item${grpIndex}" not found!`);
    }
  };

  const displayItems = () => {
    if (cart.cartItems && cart.cartItems.length > 0) {
      const filteredCart = filterCartItems(cart.cartItems);

      const filterItems = filteredCart.map((item: any, productGroupIndex) => {
        const productGroupDetails = item[1][0].productGroup;

        const productGroupCount = Math.max(
          ...item[1].map((item) => item.count),
          1
        );

        const canEditProductsInProductGroup =
          canEditProductGroup ||
          checkMasterPassword({
            master_password_verified,
            type: "Modify POS Entry",
            extra_id: productGroupDetails.id,
          });

        return item[0] !== "undefined"
          ? {
              id: productGroupIndex,
              // To display product group details
              title: (
                <div className="col-12 py-3 text-sm d-flex flex-row align-items-center">
                  <div
                    className={`${
                      isMobile
                        ? `${portrait ? "col-5" : "col-2"} gap-2`
                        : "col-3"
                    } d-flex flex-row align-items-center p-0`}
                  >
                    <div className="col-1 text-start">
                      <span>{productGroupIndex + 1}</span>
                    </div>
                    <div
                      className={`d-flex flex-${
                        isMobile
                          ? `column ${portrait ? "col-4 p-0" : "col-12"} gap-1`
                          : "row col-11"
                      } text-wrap`}
                    >
                      <div
                        className={`${
                          isMobile
                            ? `text-bold ${portrait ? "col-12" : "col-6"}`
                            : "col-6"
                        } text-start text-wrap p-0`}
                      >
                        {productGroupDetails.product_group_code}
                      </div>
                      <div
                        className={`${
                          isMobile
                            ? `${portrait ? "col-12" : "col-6"}`
                            : "col-6"
                        } text-start text-wrap p-0`}
                      >
                        {productGroupDetails.product_group_name}
                      </div>
                    </div>
                    <div className="d-md-none col-3 px-0 d-flex flex-row justify-content-between align-items-center">
                      <div className="d-flex flex-row input-group-sm">
                        <input
                          disabled={true}
                          className="form-control text-center mx-2"
                          type={FORM_CONSTANTS.NUMBER}
                          min={1}
                          step={0.01}
                          value={productGroupCount}
                        />
                      </div>
                    </div>
                    <div className="d-md-none col-3">
                      {POS_CONSTANTS.RUPEE_SIGN}
                      {displayValue(
                        to2Decimal(updateProductGroupPrice(item[1]))
                      )}
                    </div>
                  </div>
                  <div
                    className={`${
                      isMobile && portrait ? `col-1` : "col-5"
                    } d-flex flex-row text-center align-items-center px-0`}
                  >
                    {!portrait && (
                      <>
                        <div className="col-3 px-3 col-md-7 d-flex flex-row justify-content-between align-items-center">
                          <div className="d-flex flex-row input-group-sm">
                            <input
                              disabled={true}
                              className="form-control text-center mx-2"
                              type={FORM_CONSTANTS.NUMBER}
                              min={1}
                              step={0.01}
                              value={productGroupCount}
                            />
                          </div>
                          {!portrait && (
                            <div className="input-group-append">
                              <div className="input-group-text text-sm px-2 mx-1">
                                Grp
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-2 text-start p-0">
                          {POS_CONSTANTS.RUPEE_SIGN}
                          {displayValue(
                            to2Decimal(updateProductGroupPrice(item[1]))
                          )}
                        </div>
                      </>
                    )}
                    <div className="col-12 col-md-2 offset-1 text-end text-md-center">
                      {GENERAL_CONSTANTS.NA}
                    </div>
                  </div>
                  <div
                    className={`${
                      isMobile ? "col-6" : "col-4"
                    } d-flex flex-row text-center align-items-center px-0`}
                  >
                    <div className="col-4 text-end px-3">
                      {POS_CONSTANTS.RUPEE_SIGN}
                      {displayValue(to2Decimal(updateProductGroupTax(item[1])))}
                    </div>
                    <div className="col-3 text-start pl-4">
                      {POS_CONSTANTS.RUPEE_SIGN}
                      {displayValue(
                        to2Decimal(updateProductGroupUnitCost(item[1]))
                      )}
                    </div>
                    <div
                      className={`col-3 text-start ${
                        isMobile && !portrait ? "px-0" : "px-4"
                      }`}
                    >
                      {POS_CONSTANTS.RUPEE_SIGN}
                      {displayValue(
                        to2Decimal(updateProductGroupNetAmount(item[1]))
                      )}
                    </div>
                    <div className="col-2 d-flex gap-2">
                      {canEditProductsInProductGroup && (
                        <Button
                          text={<FontIcon icon={faAdd} iconSize="xs" />}
                          btnClassNames="btn btn-primary btn-sm text-center"
                          type={BUTTON_CONSTANTS.BUTTON}
                          onClickHandler={() => {
                            if (
                              canEditProductGroup ||
                              checkMasterPassword({
                                master_password_verified,
                                type: "Modify POS Entry",
                                extra_id: productGroupDetails.id,
                              })
                            ) {
                              dispatch(
                                setProductGroupDetails({
                                  ...productGroupDetails,
                                  custom: true,
                                  productGroupCount: productGroupCount || 1,
                                })
                              );
                              setAddToProductGroup(true);
                              expandProductGroup(productGroupIndex);
                            } else {
                              setMasterPasswordModal({
                                state: true,
                                productGrpID: productGroupDetails.id,
                              });
                            }
                          }}
                        />
                      )}
                      {!canEditProductsInProductGroup && (
                        <Button
                          text={<FontIcon icon={faPenToSquare} iconSize="xs" />}
                          btnClassNames="btn btn-info btn-sm text-center"
                          type={BUTTON_CONSTANTS.BUTTON}
                          onClickHandler={() => {
                            if (canEditProductGroup) {
                              expandProductGroup(productGroupIndex);
                            } else {
                              setMasterPasswordModal({
                                state: true,
                                productGrpID: productGroupDetails.id,
                              });
                            }
                          }}
                        />
                      )}
                      <Button
                        text={<FontIcon icon={faTrash} iconSize="xs" />}
                        btnClassNames="btn btn-danger btn-sm text-center"
                        type={BUTTON_CONSTANTS.BUTTON}
                        onClickHandler={() => {
                          setDataToDelete({
                            productGrpId: productGroupDetails?.id,
                          });
                          setConfirmModal(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ),
              // To display product group items (products)
              body: item[1].map((item: ICartItem, index: number) => (
                <POSCarItems
                  focusRef={focusRef}
                  setConfirmModal={setConfirmModal}
                  setDataToDelete={setDataToDelete}
                  cartItem={item}
                  index={index}
                  key={index}
                  productGroupLength={0}
                  canEdit={canEditProductsInProductGroup}
                />
              )),
            }
          : null;
      });

      return cartStatus === STATUSES.LOADING ? (
        <tr>
          <td colSpan={12}>
            <Loader />
          </td>
        </tr>
      ) : (
        <>
          {/* Product groups */}
          {hasProductGroups && (
            <tr className="text-sm">
              <td colSpan={12} className="p-0">
                <Accordion
                  accordionItems={filterItems}
                  activeItem={activeBatchItem}
                  id={"item"}
                  compact={true}
                />
              </td>
            </tr>
          )}
          {/* Products  */}
          {filteredCart.map((item: any) => {
            if (item[0] === "undefined") {
              return item[1].map((item: ICartItem, index: number) => (
                <POSCarItems
                  setConfirmModal={setConfirmModal}
                  setDataToDelete={setDataToDelete}
                  focusRef={focusRef}
                  cartItem={item}
                  index={index}
                  key={index}
                  productGroupLength={filterItems.length}
                  canEdit={true}
                />
              ));
            }
          })}
        </>
      );
    } else {
      return (
        <tr>
          <td colSpan={13} className="text-center">
            {POS_CONSTANTS.NOT_ITEMS_MESSAGE}
          </td>
        </tr>
      );
    }
  };

  return (
    <Table>
      <thead className="table-dark">
        <tr>
          {RESPONSIVE_HEADERS?.map((header, index) => (
            <th
              key={index}
              colSpan={header.colSpan}
              className={header.className}
            >
              {header.title}
            </th>
          ))}
        </tr>
      </thead>
      <TableBody>{displayItems()}</TableBody>
      {addToProductGroup && (
        <AddProductToGroup
          focusRef={addToProductGrpSearchRef}
          batchModal={batchModal}
          modal={addToProductGroup}
          setBatchModal={setBatchModal}
          setModal={setAddToProductGroup}
        />
      )}
      {masterPasswordModal.state && (
        <MasterPasswordModal
          modal={masterPasswordModal}
          setModal={setMasterPasswordModal}
          extra_data={{ id: masterPasswordModal.productGrpID }}
          module={"Modify POS Entry"}
          store_id={selectedStore}
          extra_id={null}
          module_name="Manage Product Groups"
          sub_module_id={findSubModuleId}
          permission={"authorize"}
          onSuccess={() => {}}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          modal={confirmModal}
          setModal={setConfirmModal}
          title=""
          message={POS_CART_CONSTANTS.CONFIRM_DELETE_MESSAGE}
          confirmClick={() => {
            if (cart.cartItems.length > 1) {
              if (dataToDelete.productGrpId) {
                dispatch(RemoveProductGroup(dataToDelete.productGrpId));
              } else {
                dispatch(
                  RemoveProduct(
                    dataToDelete.originalIndex,
                    dataToDelete.productGroupLength === 0
                      ? dataToDelete.productGroup.id
                      : undefined
                  )
                );
              }
            } else {
              dispatch(ResetWholeCart());
            }
            setConfirmModal(GENERAL_CONSTANTS.FALSE);
          }}
        />
      )}
    </Table>
  );
};

export { POSCart };
