import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, CardBody, IconButton } from "components/core-components";
import React, { useEffect, useState } from "react";
import {
  AddDiscount,
  RemoveDiscount,
  UpdateTaxApplied,
  clearCartDiscount,
  setCartRemarks,
  setRoundOff,
} from "store/POS";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  ICartItem,
  IDiscount,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  STATUSES,
  TOAST_CONSTANTS,
  Toast,
  calculateAllValues,
  displayValue,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { ExtraActions } from "./ExtraActions";
import { RedeemCreditNoteModal } from "./RedeemCreditNoteModal";

interface IProps {
  focusRef: React.MutableRefObject<any>;
  printOptionsModal: boolean;
  setPrintOptionsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const BillDetails = ({
  focusRef,
  printOptionsModal,
  setPrintOptionsModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  const {
    auth: { permissions: permissionData },
    cart: { cart },
    common: { status: commonStatus },
    creditNote: { status: creditNoteStatus },
    order: { status: orderStatus },
    holdBill: { status: holdBillStatus },
  } = useAppSelector((state) => state.root);

  const returnPermissions = permissionData.find(
    (p) => p.module_name === "Manage Returns/Refunds"
  )?.perm_view;

  const orderInProgress =
    holdBillStatus === STATUSES.LOADING ||
    orderStatus === STATUSES.LOADING ||
    creditNoteStatus === STATUSES.LOADING ||
    commonStatus.type === POS_CART_CONSTANTS.WHATSAPP_BILL_LOADING_STATE ||
    commonStatus.type === POS_CART_CONSTANTS.PRINT_LAST_BILL_LOADING_STATE;

  //Calculates all the necessary values for the cart
  const {
    total_quantity,
    total_tax,
    final_discount,
    final_amount_to_pay,
    total_payable,
    initial_net_payable,
  } = calculateAllValues(cart);

  const creditNote =
    cart.credit_note.applyCredit > 0 ? Number(cart.credit_note.applyCredit) : 0;

  const [redeemCreditModal, setRedeemCreditModal] = useState<boolean>(
    GENERAL_CONSTANTS.FALSE
  );

  const creditNoteApplied = cart.credit_note.applyCredit > 0;

  //Calculates the discount for the given discount object
  const calculate_discount_for_custom_discount = (discount) => {
    if (discount.discount_type === GENERAL_CONSTANTS.PERCENTAGE) {
      return to2Decimal(
        (Math.round(initial_net_payable) * discount.discount_value) / 100
      );
    } else {
      return to2Decimal(discount.discount_value);
    }
  };

  //calculates the current discount applied to the cart
  const currentCartDiscount = () =>
    cart.discount.discount_value > 0
      ? cart.discount.discount_type === GENERAL_CONSTANTS.PERCENTAGE
        ? to2Decimal((initial_net_payable * cart.discount.discount_value) / 100)
        : to2Decimal(cart.discount.discount_value)
      : 0;

  const cartItemsInCart = cart.cartItems.length > 0;

  // Function to set/change the discount type
  const setDiscountType = (applyTo: ICartItem | false) => {
    let switchDiscount = cart.discount;

    switchDiscount = {
      ...switchDiscount,
      discount_type:
        cart.discount.discount_type !== GENERAL_CONSTANTS.FLAT
          ? GENERAL_CONSTANTS.FLAT
          : GENERAL_CONSTANTS.PERCENTAGE,
    };
    if (
      to2Decimal(
        final_amount_to_pay -
          creditNote -
          calculate_discount_for_custom_discount(switchDiscount)
      ) > 1
    ) {
      dispatch(
        UpdateTaxApplied(calculate_discount_for_custom_discount(switchDiscount))
      );
      dispatch(AddDiscount(applyTo, switchDiscount));
    } else {
      dispatch(clearCartDiscount());
      dispatch(UpdateTaxApplied(0));
      Toast(POS_CART_CONSTANTS.DISCOUNT_ERROR_MESSAGE, TOAST_CONSTANTS.ERROR);
    }
  };

  // Function to validate the discount if correct then apply it to the cart if not the throw an error
  const validateDiscount = (
    applyTo: ICartItem | false,
    validDiscount: boolean,
    newDiscount: IDiscount
  ) => {
    const current_discount = calculate_discount_for_custom_discount(
      newDiscount
    );
    // If the discount is percentage and the current discount is flat or vice versa then remove the current discount and apply the new one
    if (
      (newDiscount.discount_type === GENERAL_CONSTANTS.FLAT &&
        cart.discount.discount_type &&
        cart.discount.discount_type === GENERAL_CONSTANTS.FLAT) ||
      (newDiscount.discount_type === GENERAL_CONSTANTS.PERCENTAGE &&
        cart.discount.discount_type &&
        cart.discount.discount_type !== GENERAL_CONSTANTS.FLAT)
    ) {
      // If the discount is 0 then remove the discount from the cart
      if (current_discount === 0) {
        dispatch(UpdateTaxApplied(current_discount));
        applyTo
          ? dispatch(RemoveDiscount(applyTo))
          : dispatch(clearCartDiscount());
      } else {
        // If the discount is not 0 then apply the discount to the cart
        if (validDiscount) {
          dispatch(UpdateTaxApplied(current_discount));
          dispatch(AddDiscount(applyTo, newDiscount));
        } else {
          // If the discount is not valid then throw an error
          Toast(
            POS_CART_CONSTANTS.DISCOUNT_ERROR_MESSAGE,
            TOAST_CONSTANTS.ERROR
          );
          dispatch(UpdateTaxApplied(0));
          dispatch(clearCartDiscount());
        }
      }
    }
  };

  useEffect(() => {
    // If the credit note is applied OR final_amount_to_pay updates then calculate the round off
    dispatch(
      setRoundOff(
        -to2Decimal(
          Number(
            (
              final_amount_to_pay -
              creditNote -
              Math.round(final_amount_to_pay - creditNote)
            ).toFixed(2)
          )
        )
      )
    );

    return () => {
      dispatch(setRoundOff(0));
    };
  }, [final_amount_to_pay, cart.credit_note.applyCredit]);

  return (
    <Card>
      <CardBody bodyClassNames="d-flex flex-column px-3 py-3">
        <div className={`row my-2 order-0`}>
          {/* This is the input field for the bill remarks */}
          <div className={`col-12`}>
            <input
              disabled={orderInProgress}
              maxLength={120}
              placeholder={POS_CART_CONSTANTS.BILL_REMARKS_PLACEHOLDER}
              className="form-control"
              value={cart.remarks}
              onChange={(e) => {
                dispatch(setCartRemarks(e.target.value));
              }}
            />
          </div>
        </div>
        <div className={`row order-1`}>
          <div className="col-md-5 d-flex flex-row align-items-center text-sm text-center mt-2">
            {/* Total number of items in cart */}
            <div className="col-md-3 col-4">
              <h6 id="total_items">{displayValue(total_quantity)}</h6>
              <h6 className="text-muted text-sm ">
                {POS_CART_CONSTANTS.TOTAL_ITEMS_LABEL}
              </h6>
            </div>
            {/* Total Price without credit note */}
            <div className="col-md-3 col-4">
              <h6 id="total_mrp">
                {POS_CONSTANTS.RUPEE_SIGN}
                {displayValue(total_payable)}
              </h6>
              <h6 className="text-muted text-sm ">
                {POS_CART_CONSTANTS.TOTAL_MRP_LABEL}
              </h6>
            </div>
            {/* Input for bill Discount */}
            <div className="col-md-6 col-4">
              <div className="row">
                <div className="col-12 input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text text-bg-dark p-0">
                      <Button
                        testId="bill-discount-toggle"
                        text={`${
                          cart.discount.discount_type &&
                          cart.discount.discount_type ===
                            GENERAL_CONSTANTS.PERCENTAGE
                            ? GENERAL_CONSTANTS.PERCENTAGE_SYMBOL
                            : GENERAL_CONSTANTS.FLAT_SYMBOL
                        }`}
                        btnClassNames="btn text-white btn-transparent p-2 mx-1 border-0"
                        isDisabled={!cartItemsInCart || orderInProgress}
                        type={BUTTON_CONSTANTS.BUTTON}
                        onClickHandler={() => {
                          setDiscountType(false);
                          // setDiscountType(
                          //   { ...cart.discount },
                          //   (switchDiscount) => {
                          //     return (
                          //       to2Decimal(
                          //         final_amount_to_pay -
                          //           creditNote -
                          //           calculate_discount_for_custom_discount(
                          //             switchDiscount
                          //           )
                          //       ) > 1
                          //     );
                          //   },
                          //   (switchDiscount) => {
                          //     dispatch(
                          //       UpdateTaxApplied(
                          //         calculate_discount_for_custom_discount(
                          //           switchDiscount
                          //         )
                          //       )
                          //     );
                          //     dispatch(AddDiscount(false, switchDiscount));
                          //   },
                          //   () => {
                          //     dispatch(clearCartDiscount());
                          //     dispatch(UpdateTaxApplied(0));
                          //   }
                          // );
                        }}
                      />
                    </div>
                  </div>
                  <input
                    id="bill-discount"
                    type={FORM_CONSTANTS.NUMBER}
                    step={0.01}
                    className="form-control"
                    placeholder={POS_CART_CONSTANTS.DISCOUNT_PLACEHOLDER}
                    disabled={!cartItemsInCart || orderInProgress}
                    value={cart.discount.discount_value}
                    onChange={(e) => {
                      const new_discount = {
                        ...cart.discount,
                        discount_value: Number(e.target.value),
                      };
                      validateDiscount(
                        false,
                        to2Decimal(
                          final_amount_to_pay -
                            creditNote +
                            cart.round_off +
                            currentCartDiscount() -
                            calculate_discount_for_custom_discount(new_discount)
                        ) >= 0,
                        new_discount
                      );
                      // validateDiscount(
                      //   { ...cart.discount },
                      //   {
                      //     ...cart.discount,
                      //     discount_value: Number(e.target.value),
                      //   },
                      //   (newDiscount) => {
                      //     return (
                      //       to2Decimal(
                      //         final_amount_to_pay -
                      //           creditNote +
                      //           cart.round_off +
                      //           currentCartDiscount() -
                      //           calculate_discount_for_custom_discount(
                      //             newDiscount
                      //           )
                      //       ) >= 0
                      //     );
                      //   },
                      //   (newDiscount) => {
                      //     dispatch(
                      //       UpdateTaxApplied(
                      //         calculate_discount_for_custom_discount(
                      //           newDiscount
                      //         )
                      //       )
                      //     );
                      //     dispatch(AddDiscount(false, newDiscount));
                      //   },
                      //   () => {
                      //     dispatch(UpdateTaxApplied(0));
                      //     dispatch(clearCartDiscount());
                      //   }
                      // );
                    }}
                  />
                  {/* <div className="input-group-append">
                    <div className="input-group-text text-bg-dark p-0">
                      <Button
                        text={<FontIcon icon={faTags} />}
                        isDisabled={!cartItemsInCart}
                        btnClassNames="btn text-white btn-transparent p-2 mx-1 border-0"
                        type="button"
                        onClickHandler={() => {
                          setDiscountModal(GENERAL_CONSTANTS.TRUE);
                        }}
                      />
                      {discountModal && (
                        <AddDiscountModal
                          currentDiscount={discount}
                          setCurrentDiscount={setDiscount}
                          applyDiscountTo={GENERAL_CONSTANTS.FALSE}
                          modal={discountModal}
                          setModal={setDiscountModal}
                        />
                      )}
                    </div>
                  </div> */}
                </div>
                <h6 className="col-12 text-muted text-sm">
                  {POS_CART_CONSTANTS.DISCOUNT_LABEL}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-row align-items-center text-sm text-center mt-2">
            {/* Total discount applied on the cart */}
            <div className="col-4">
              <h6 id="total_discount">
                {POS_CONSTANTS.RUPEE_SIGN}
                {displayValue(final_discount)}
              </h6>
              <h6 className="text-muted text-sm">
                {POS_CART_CONSTANTS.TOTAL_DISCOUNT_LABEL}
              </h6>
            </div>
            {/* Total tax applied on cart */}
            <div className="col-4">
              <h6 id="total_tax_applied">
                {POS_CONSTANTS.RUPEE_SIGN}
                {displayValue(total_tax)}
              </h6>
              <h6 className="text-muted text-sm ">
                {POS_CART_CONSTANTS.TAX_AMOUNT_LABEL}
              </h6>
            </div>
            <div className="col-4 d-flex flex-column justify-content-center align-items-center text-sm text-center mt-1">
              {/* Round Off */}
              <div className="row">
                <div className="input-group ">
                  <div className="input-group-prepend ">
                    <div className="input-group-text text-bg-dark text-sm">
                      {POS_CONSTANTS.RUPEE_SIGN}
                    </div>
                  </div>
                  <input
                    id="round_off"
                    type={FORM_CONSTANTS.NUMBER}
                    disabled={GENERAL_CONSTANTS.TRUE}
                    step={0.01}
                    value={cart.round_off}
                    className="form-control"
                  />
                </div>
              </div>
              <h6 className="row text-sm text-muted">
                {POS_CART_CONSTANTS.ROUND_OFF_LABEL}
              </h6>
              {/* <h6 className="text-muted text-sm mt-1">Round Off</h6> */}
            </div>
          </div>
          <div className="col-md-3 d-flex flex-row align-items-center text-sm text-center mt-2">
            <div className="col-7 d-flex flex-column justify-content-center align-items-center text-sm text-center mt-1">
              {/* credit NOte  */}
              {creditNoteApplied ? (
                <>
                  <Button
                    testId="redeem_credit_note"
                    isDisabled={orderInProgress || !returnPermissions}
                    type={BUTTON_CONSTANTS.BUTTON}
                    text={`${POS_CONSTANTS.RUPEE_SIGN}${cart.credit_note.applyCredit}`}
                    btnClassNames="btn btn-dark btn-xs p-1 text-sm w-100"
                    onClickHandler={() => {
                      setRedeemCreditModal(GENERAL_CONSTANTS.TRUE);
                    }}
                  />
                  <h6 className="text-muted text-sm ">
                    {POS_CART_CONSTANTS.CREDIT_LABEL}
                  </h6>
                </>
              ) : (
                <IconButton
                  testId="redeem_credit_note"
                  icon={faMoneyBill}
                  isDisabled={
                    !cartItemsInCart || orderInProgress || !returnPermissions
                  }
                  btnClassNames="btn btn-dark btn-sm mb-4"
                  onClickHandler={() => {
                    setRedeemCreditModal(GENERAL_CONSTANTS.TRUE);
                  }}
                  btnText={POS_CART_CONSTANTS.REDEEM_CREDIT_LABEL}
                  btnTextSize="text-sm"
                />
              )}
            </div>
            {/* Net Amount */}
            <div className="col-5 text-sm text-center mt-3">
              <h6
                id="final_net_payable_amount"
                className="text-success text-bold text-lg text-end"
              >
                {POS_CONSTANTS.RUPEE_SIGN}
                {displayValue(
                  to2Decimal(final_amount_to_pay - creditNote + cart.round_off)
                )}
              </h6>
              <h6 className="text-muted text-sm text-end">
                {POS_CART_CONSTANTS.NET_AMOUNT_LABEL}
              </h6>
            </div>
          </div>
        </div>
        <div className="row order-2">
          {/* Buttons for Hold/Advance Order/Pay Later/Save & Print  */}
          <ExtraActions
            focusRef={focusRef}
            printOptionsModal={printOptionsModal}
            setPrintOptionsModal={setPrintOptionsModal}
          />
        </div>
        {/* Modal For redeeming credit note */}
        {redeemCreditModal && returnPermissions && (
          <RedeemCreditNoteModal
            modal={redeemCreditModal}
            setModal={setRedeemCreditModal}
          />
        )}
      </CardBody>
    </Card>
  );
};

export { BillDetails };
