import React, { memo, useEffect } from "react";

import {
  Checkbox,
  CustomInput,
  MultiSelect,
  Table,
  TableBody,
  TableHeader,
} from "components";
import {
  FORM_CONSTANTS,
  PRODUCTS_CONSTANTS,
  STATUSES,
  Toast,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { ErrorMessage, Field, FieldArray } from "formik";
import { getDiscountTypes } from "store/ManageProducts/ManageDiscounts/discountTypeSlice";
import { boolean } from "yup";

const ProductPricingDetailsForm = ({ props, batch, batchIndex }) => {
  const dispatch = useAppDispatch();

  const {
    branch: { branch: branchData },
    discountType: {
      discountType: discountTypeData,
      status: discountTypeStatus,
    },
  } = useAppSelector((state) => state.root);

  const calculateDiscount = (mrp, discount, discountType) => {
    return discountType === 4 ? mrp - (mrp * discount) / 100 : mrp - discount;
  };

  const calculateSellingPrice = (
    mrp: number,
    sellingDiscount: number,
    discountType: number,
    ceil: boolean
  ) => {
    if (discountType) {
      if (ceil) {
        return Math.ceil(calculateDiscount(mrp, sellingDiscount, discountType));
      } else {
        return Math.floor(
          calculateDiscount(mrp, sellingDiscount, discountType)
        );
      }
    } else {
      return mrp;
    }
  };

  const pricingCols = FORM_CONSTANTS.PRICING_COLS;

  interface handlePricechangeProps {
    index: number;
    ceil?: boolean;
  }
  const handlePriceChange = ({ index, ceil }: handlePricechangeProps) => {
    const discount_value = props.values.price?.[batchIndex].store_prices?.[
      index
    ]?.selling_discount_type
      ? calculateSellingPrice(
          props.values.price[batchIndex].store_prices[index].mrp,
          props.values.price?.[batchIndex]?.store_prices?.[index]
            ?.selling_discount,
          props.values.price?.[batchIndex]?.store_prices?.[index]
            ?.selling_discount_type?.[0]?.value,
          ceil ? ceil : props.values.price[batchIndex].store_prices[index].ceil
        )
      : props.values.price[batchIndex].store_prices[index].mrp;

    if (discount_value <= 0) {
      Toast("Selling price cannot be negative", "error");
      props.setFieldValue(
        `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_SELLING_DISCOUNT}`,
        0
      );
      props.setFieldValue(
        `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_DISCOUNT_TYPE}`,
        props.values.price?.[batchIndex]?.store_prices?.[index]
          ?.selling_discount_type
      );
      props.setFieldValue(
        `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_SELLING_PRICE}`,
        props.values.price[batchIndex].store_prices[index].mrp
      );
    } else {
      props.setFieldValue(
        `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_SELLING_PRICE}`,
        discount_value
      );
    }
  };

  return (
    <div className="col-md-12 table-responsive">
      <FieldArray
        name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}`}
        render={(arrayHelpers) => {
          const bulkSellingPrice = calculateSellingPrice(
            props.values?.price?.[batchIndex]?.bulk_mrp,
            props.values?.price?.[batchIndex]?.bulk_selling_discount,
            props.values?.price?.[batchIndex]?.bulk_selling_discount_type?.[0]
              ?.value,
            props.values?.price?.[batchIndex]?.bulk_ceil
          );

          const newPrice = branchData.map((branch, index) => ({
            ...props.values.price[batchIndex].store_prices[index],
            store: branch.store_name,
            mrp: props.values?.price?.[batchIndex]?.bulk_mrp,
            selling_discount:
              props.values?.price?.[batchIndex]?.bulk_selling_discount,
            selling_price: bulkSellingPrice,
            minimum_selling_quantity:
              props.values?.price?.[batchIndex]?.bulk_minimum_selling_quantity,
            selling_discount_type: [
              props.values?.price?.[batchIndex].bulk_selling_discount_type &&
              props.values?.price?.[batchIndex].bulk_selling_discount_type
                .length > 0
                ? props.values?.price?.[batchIndex]
                    .bulk_selling_discount_type[0]
                : null,
            ],
            ceil: props.values?.price?.[batchIndex]?.bulk_ceil,
          }));

          return (
            <Table>
              <TableHeader cols={pricingCols} />
              <TableBody>
                <tr>
                  <td className="col-2 align-middle text-center">All</td>
                  <td className="col-2 ">
                    <Field
                      as={CustomInput}
                      name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_mrp`}
                      type={FORM_CONSTANTS.NUMBER}
                      placeholder={
                        PRODUCTS_CONSTANTS.PRODUCT_PRICING_MRP_PLACEHOLDER
                      }
                      onBlurHandler={() => {
                        props.setFieldValue(
                          `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}`,
                          newPrice
                        );
                      }}
                    />
                    <ErrorMessage
                      name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_mrp`}
                      component={FORM_CONSTANTS.ERROR_PARENT}
                      className={FORM_CONSTANTS.ERROR}
                    />
                  </td>
                  <td className="col-4 ">
                    <div className="row">
                      <div className="col-md-8">
                        <MultiSelect
                          name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_selling_discount_type`}
                          onFocusHandler={() => {
                            discountTypeData.length === 0 &&
                              dispatch(getDiscountTypes(true));
                          }}
                          options={discountTypeData.map((item) => ({
                            value: item.id,
                            label: item.type,
                          }))}
                          onBlurHandler={() => {
                            props.setFieldValue(
                              `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}`,
                              newPrice
                            );
                          }}
                          isLoading={discountTypeStatus === STATUSES.LOADING}
                        />
                        <ErrorMessage
                          name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_selling_discount_type`}
                          component={FORM_CONSTANTS.ERROR_PARENT}
                          className={FORM_CONSTANTS.ERROR}
                        />
                      </div>
                      <div className="col-md-4">
                        <Field
                          step="0.01"
                          as={CustomInput}
                          name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_selling_discount`}
                          type={FORM_CONSTANTS.NUMBER}
                          discountType={
                            props.values?.price?.[batchIndex]
                              ?.bulk_selling_discount_type?.[0]?.label
                          }
                          onBlurHandler={() => {
                            const discount_value = props.values.price[
                              batchIndex
                            ].bulk_selling_discount_type
                              ? calculateSellingPrice(
                                  props.values.price[batchIndex].bulk_mrp,
                                  props.values.price[batchIndex]
                                    .bulk_selling_discount,
                                  props.values.price[batchIndex]
                                    .bulk_selling_discount_type[0]?.value,
                                  props.values.price[batchIndex].bulk_ceil
                                )
                              : props.values.price[batchIndex].bulk_mrp;
                            if (discount_value <= 0) {
                              Toast(
                                "Selling price cannot be negative",
                                "error"
                              );
                              props.setFieldValue(
                                `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_selling_discount`,
                                0
                              );
                              props.setFieldValue(
                                `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_selling_discount_type`,
                                props.values.price[batchIndex]
                                  .bulk_selling_discount_type
                              );
                            } else {
                              props.setFieldValue(
                                `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}`,
                                newPrice
                              );
                            }
                          }}
                        />
                        <ErrorMessage
                          name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_selling_discount`}
                          component={FORM_CONSTANTS.ERROR_PARENT}
                          className={FORM_CONSTANTS.ERROR}
                        />
                      </div>
                    </div>
                  </td>
                  <td className="col-2 ">
                    <div className="row">
                      <Field
                        as={CustomInput}
                        isDisabled={true}
                        name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_selling_price`}
                        type={FORM_CONSTANTS.NUMBER}
                        placeholder={
                          PRODUCTS_CONSTANTS.PRODUCT_PRICING_SELLING_PRICE_PLACEHOLDER
                        }
                        value={bulkSellingPrice}
                      />
                      <ErrorMessage
                        name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_selling_price`}
                        component={FORM_CONSTANTS.ERROR_PARENT}
                        className={FORM_CONSTANTS.ERROR}
                      />
                    </div>
                    <div className="row ">
                      <Checkbox
                        name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_ceil`}
                        label="Ceil"
                        isChecked={props.values.price[batchIndex].bulk_ceil}
                        isSwitch={true}
                        onClickHandler={() => {
                          const updatedValues = [
                            ...props.values.price[batchIndex].store_prices,
                          ];

                          updatedValues.forEach((pricingItem, index) => {
                            updatedValues[index].ceil = !props.values.price[
                              batchIndex
                            ].bulk_ceil;
                          });
                          props.setFieldValue(
                            `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_ceil`,
                            !props.values.price[batchIndex].bulk_ceil
                          );
                          props.setFieldValue(
                            `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}`,
                            updatedValues
                          );
                        }}
                        onBlurHandler={() => {
                          props.setFieldValue(
                            `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}`,
                            newPrice
                          );
                        }}
                      />
                      <ErrorMessage
                        name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_ceil`}
                        component={FORM_CONSTANTS.ERROR_PARENT}
                        className={FORM_CONSTANTS.ERROR}
                      />
                    </div>
                  </td>
                  <td className="col-2 ">
                    <Field
                      as={CustomInput}
                      name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_minimum_selling_quantity`}
                      type={FORM_CONSTANTS.NUMBER}
                      placeholder={
                        PRODUCTS_CONSTANTS.PRODUCT_PRICING_MSQ_PLACEHOLDER
                      }
                      onBlurHandler={() => {
                        props.setFieldValue(
                          `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}`,
                          newPrice
                        );
                      }}
                    />
                    <ErrorMessage
                      name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].bulk_minimum_selling_quantity`}
                      component={FORM_CONSTANTS.ERROR_PARENT}
                      className={FORM_CONSTANTS.ERROR}
                    />
                  </td>
                </tr>
                {props.values?.price &&
                  props.values?.price.length > 0 &&
                  props.values?.price?.[batchIndex]?.store_prices &&
                  props.values?.price?.[batchIndex]?.store_prices.length > 0 &&
                  props.values?.price?.[batchIndex]?.store_prices?.map(
                    (pricing, index) => (
                      <tr key={index}>
                        <td>
                          <CustomInput
                            name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_STORE}`}
                            type={FORM_CONSTANTS.TEXT}
                            isDisabled={true}
                          />
                        </td>
                        <td>
                          <Field
                            as={CustomInput}
                            name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_MRP}`}
                            type={FORM_CONSTANTS.NUMBER}
                            placeholder={
                              PRODUCTS_CONSTANTS.PRODUCT_PRICING_MRP_PLACEHOLDER
                            }
                            onBlurHandler={() => {
                              handlePriceChange({ index });
                            }}
                          />
                          <ErrorMessage
                            name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_MRP}`}
                            component={FORM_CONSTANTS.ERROR_PARENT}
                            className={FORM_CONSTANTS.ERROR}
                          />
                        </td>
                        <td>
                          <div className="row">
                            <div className="col-md-8">
                              <MultiSelect
                                name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_DISCOUNT_TYPE}`}
                                onFocusHandler={() => {
                                  discountTypeData.length === 0 &&
                                    dispatch(getDiscountTypes(true));
                                }}
                                options={discountTypeData.map((item) => ({
                                  value: item.id,
                                  label: item.type,
                                }))}
                                isLoading={
                                  discountTypeStatus === STATUSES.LOADING
                                }
                                onBlurHandler={() => {
                                  handlePriceChange({ index });
                                }}
                              />
                              <ErrorMessage
                                name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_DISCOUNT_TYPE}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                            <div className="col-md-4">
                              <Field
                                as={CustomInput}
                                step="0.01"
                                name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_SELLING_DISCOUNT}`}
                                type={FORM_CONSTANTS.NUMBER}
                                discountType={
                                  props.values?.price?.[batchIndex]
                                    ?.store_prices?.[index]
                                    ?.selling_discount_type?.[0]?.label
                                }
                                onBlurHandler={() => {
                                  handlePriceChange({ index });
                                }}
                              />
                              <ErrorMessage
                                name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_SELLING_DISCOUNT}`}
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="row">
                            <Field
                              as={CustomInput}
                              isDisabled={true}
                              name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_SELLING_PRICE}`}
                              type={FORM_CONSTANTS.NUMBER}
                              placeholder={
                                PRODUCTS_CONSTANTS.PRODUCT_PRICING_SELLING_PRICE_PLACEHOLDER
                              }
                            />
                            <ErrorMessage
                              name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_SELLING_PRICE}`}
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                          <div className="row ">
                            <Checkbox
                              name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_CEIL}`}
                              label={
                                PRODUCTS_CONSTANTS.PRODUCT_PRICING_CEIL_PLACEHOLDER
                              }
                              isChecked={
                                props.values.price[batchIndex].store_prices[
                                  index
                                ]?.ceil
                              }
                              isSwitch={true}
                              onClickHandler={() => {
                                props.setFieldValue(
                                  `${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_CEIL}`,
                                  !props.values.price[batchIndex].store_prices[
                                    index
                                  ]?.ceil
                                );
                              }}
                              onBlurHandler={() => {
                                handlePriceChange({ index });
                              }}
                            />
                            <ErrorMessage
                              name="ceil"
                              component={FORM_CONSTANTS.ERROR_PARENT}
                              className={FORM_CONSTANTS.ERROR}
                            />
                          </div>
                        </td>
                        <td>
                          <Field
                            as={CustomInput}
                            name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_MSQ}`}
                            type={FORM_CONSTANTS.NUMBER}
                            placeholder={
                              PRODUCTS_CONSTANTS.PRODUCT_PRICING_MSQ_PLACEHOLDER
                            }
                          />
                          <ErrorMessage
                            name={`${PRODUCTS_CONSTANTS.PRODUCT_PRICING}[${batchIndex}].${PRODUCTS_CONSTANTS.PRODUCT_STORE_PRICING}[${index}].${PRODUCTS_CONSTANTS.PRODUCT_PRICING_MSQ}`}
                            component={FORM_CONSTANTS.ERROR_PARENT}
                            className={FORM_CONSTANTS.ERROR}
                          />
                        </td>
                      </tr>
                    )
                  )}
              </TableBody>
            </Table>
          );
        }}
      />
    </div>
  );
};

export default memo(ProductPricingDetailsForm);
