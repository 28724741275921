import {
  faAdd,
  faBars,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  ContentHeader,
  CustomInput,
  FileUpload,
  FontIcon,
  IconButton,
  MultiSelect,
  SelectStatus,
  SEODetailsform,
  Summernote,
  TextArea,
  WidgetDetailModal,
} from "components";
import { ScrollToFieldError } from "components/ScollToFieldError";
import { ErrorMessage, FieldArray, Form, Formik, FormikProps } from "formik";
import { WidgetsContentDetails } from "pages/Ecommerce/Manage Widgets";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { uploadImage } from "store/commonSlice";
import {
  addNewCMSSubCategory,
  fetchAvaryaEcommerceProducts,
  fetchCMSCategories,
  fetchCMSSubCategories,
  resetCategoryState,
  resetSubCategoryState,
  setCMSCategoryToEdit,
  setCMSSubCategoryToEdit,
  updateExistingCMSSubCategory,
} from "store/Ecommerce";
import { getSubCategories } from "store/ManageProducts/productSubCategorySlice";
import {
  BUTTON_CONSTANTS,
  createPreviewImg,
  debounce,
  ECOMMERCE_SUB_CATEGORY_CONSTANTS,
  EcommerceSubCategorySchema,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  isValidImage,
  processWidgetData,
  ROLE_CONSTANTS,
  S3_FOLDER_CONSTANTS,
  ScrollToTop,
  SEO_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";

const SubCategoryForm = () => {
  const {
    root: {
      productSubCategory: {
        productSubCategory: subCategoryData,
        subCategoryToEdit: erpCategory,
        status: sub_category_status,
      },
    },
    ecommerce: {
      category: {
        category: selectedCMSCategory,
        categories: CMSCategories,
        status: CMSCategoryStatus,
      },
      subCategory: {
        sub_category: CMSSubCategory,
        status: CMSSubCategoryStatus,
      },
    },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const [query, setQuery] = useState("");

  const [sorting, setSorting] = useState(false);

  const PropsRef = useRef<FormikProps<any>>(null);

  const initialValues = {
    product_category: [],
    product_sub_category: "",
    icon: "",
    icon_alt: "",
    display_name: "",
    short_summary: "",

    is_active: [
      {
        value: 1,
        label: GENERAL_CONSTANTS.ACTIVE,
      },
    ],

    widgets: [],

    slugHelper: "",
    meta_title: "",
    meta_description: "",
    meta_slug: "",
    meta_keywords: "",
  };

  const SetEditValues = (dataToUSe) => {
    const subCategoryToEdit = {
      product_category: [
        {
          value: dataToUSe?.product_category?.id,
          label: dataToUSe?.product_category?.display_name,
        },
      ],

      product_sub_category: [
        {
          value: dataToUSe?.product_sub_category?.id,
          label: dataToUSe?.product_sub_category?.sub_category_name,
        },
      ],

      display_name: dataToUSe?.display_name,
      short_summary: dataToUSe?.short_summary,
      icon: dataToUSe?.icon,
      icon_alt: dataToUSe?.icon_alt,

      is_active: [
        {
          value: dataToUSe?.is_active,
          label: dataToUSe?.is_active
            ? GENERAL_CONSTANTS.ACTIVE
            : GENERAL_CONSTANTS.INACTIVE,
        },
      ],

      widgets:
        dataToUSe?.widgets && dataToUSe?.widgets?.length > 0
          ? dataToUSe?.widgets
              ?.map((widget) => {
                const widgetItem = {
                  ...widget,
                  properties: widget.content,
                  is_active: [
                    {
                      value: widget.is_active,
                      label: widget.is_active
                        ? GENERAL_CONSTANTS.ACTIVE
                        : GENERAL_CONSTANTS.INACTIVE,
                    },
                  ],
                };
                delete widgetItem["content"];
                return widgetItem;
              })
              .sort((a, b) => a.priority - b.priority)
          : [],

      slugHelper: `${dataToUSe?.product_category?.meta_slug}/`,

      meta_title: dataToUSe?.meta_title,
      meta_description: dataToUSe?.meta_description,
      meta_slug: dataToUSe?.meta_slug,
      meta_keywords: dataToUSe?.meta_keywords,
    };

    return JSON.parse(JSON.stringify(subCategoryToEdit));
  };

  const getCategoryOptions = useMemo(() => {
    return CMSCategories.map((category: any) => ({
      id: category.id,
      label: category.display_name,
      value: category.product_category?.id,
    }));
  }, [CMSCategories.length]);

  const getSubCategoryOptions = useMemo(() => {
    return subCategoryData.map((sub_category) => ({
      label: sub_category.sub_category_name,
      value: sub_category.id,
    }));
  }, [subCategoryData.length]);

  const searchValues = (value) => {
    dispatch(
      getSubCategories(
        false,
        1,
        value,
        PropsRef.current?.values?.product_category?.[0]
          ? PropsRef.current?.values?.product_category?.[0]?.value
          : null
      )
    );
  };

  const optimizeSearch = useCallback(debounce(searchValues), []);

  const onCategoryChange = (value, actions) => {
    PropsRef.current?.setFieldValue(
      ECOMMERCE_SUB_CATEGORY_CONSTANTS.SUB_CATEGORY,
      []
    );

    PropsRef.current?.setFieldValue(
      ECOMMERCE_SUB_CATEGORY_CONSTANTS.DISPLAY_NAME,
      ""
    );

    PropsRef.current?.setFieldValue(ECOMMERCE_SUB_CATEGORY_CONSTANTS.ALT, "");

    PropsRef.current?.setFieldValue(SEO_CONSTANTS.TITLE, "");

    PropsRef.current?.setFieldValue(SEO_CONSTANTS.SLUG, "");
    if (actions.action === "clear") {
      PropsRef.current?.setFieldValue(
        ECOMMERCE_SUB_CATEGORY_CONSTANTS.CATEGORY,
        []
      );

      dispatch(setCMSCategoryToEdit(null));
      return;
    }

    const selected_option = Array.isArray(value) ? value : [value];

    PropsRef.current?.setFieldValue(
      ECOMMERCE_SUB_CATEGORY_CONSTANTS.CATEGORY,
      selected_option
    );

    dispatch(
      fetchCMSCategories({
        id: selected_option?.[0]?.id,
      })
    );

    dispatch(getSubCategories(false, 1, "", selected_option?.[0]?.value, true));
  };

  const onSubCategoryChange = (value, actions) => {
    if (actions.action === "clear") {
      PropsRef.current?.setFieldValue(
        ECOMMERCE_SUB_CATEGORY_CONSTANTS.DISPLAY_NAME,
        ""
      );
      PropsRef.current?.setFieldValue(ECOMMERCE_SUB_CATEGORY_CONSTANTS.ALT, "");
      PropsRef.current?.setFieldValue(SEO_CONSTANTS.TITLE, "");

      PropsRef.current?.setFieldValue(SEO_CONSTANTS.SLUG, "");

      PropsRef.current?.setFieldValue(
        ECOMMERCE_SUB_CATEGORY_CONSTANTS.SUB_CATEGORY,
        []
      );
      return;
    }

    const selectedOption = Array.isArray(value) ? value : [value];

    PropsRef.current?.setFieldValue(
      ECOMMERCE_SUB_CATEGORY_CONSTANTS.DISPLAY_NAME,
      selectedOption?.[0]?.label
    );

    PropsRef.current?.setFieldValue(
      SEO_CONSTANTS.TITLE,
      selectedOption?.[0]?.label
    );

    PropsRef.current?.setFieldValue(
      ECOMMERCE_SUB_CATEGORY_CONSTANTS.ALT,
      selectedOption?.[0]?.label.replace(/ /g, "-").toLowerCase()
    );

    PropsRef.current?.setFieldValue(
      ECOMMERCE_SUB_CATEGORY_CONSTANTS.SUB_CATEGORY,
      selectedOption
    );

    PropsRef.current?.setFieldValue(
      "meta_slug",
      selectedOption?.[0]?.label?.toLowerCase()?.replace(/ /g, "-")
    );
  };

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      ...values,
      icon: (values?.icon?.preview || values?.icon || "").replace(
        /^https?:\/\/[^/]+\//,
        ""
      ),
      product_category: values.product_category?.[0]?.id,
      meta_slug: values.meta_slug,
      // (selectedCMSCategory?.meta_slug
      //   ? `/${selectedCMSCategory?.meta_slug
      //       ?.replace(/ /g, "-")
      //       .toLowerCase()}/`
      //   : "") + values.meta_slug,
      product_sub_category: values.product_sub_category?.[0]?.value,
      is_active: Number(values?.is_active?.[0]?.value),
      widgets: processWidgetData(values?.widgets),
    };

    if (id) {
      dispatch(
        updateExistingCMSSubCategory({
          id: Number(id),
          subCategoryParams: { id: Number(id), ...dataToSend },
          actions: actions,
          navigate: navigate,
        })
      );
    } else {
      dispatch(
        addNewCMSSubCategory({
          subCategoryParams: dataToSend,
          actions: actions,
          navigate: navigate,
        })
      );
    }
  };

  useEffect(() => {
    ScrollToTop();

    if (id) {
      dispatch(
        fetchCMSSubCategories({
          id: Number(id),
        })
      );
    }

    return () => {
      dispatch(resetCategoryState());
      dispatch(resetSubCategoryState());
      dispatch(setCMSSubCategoryToEdit(null));
      dispatch(setCMSCategoryToEdit(null));
    };
  }, []);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader
          pageHeader={`${
            id ? ROLE_CONSTANTS.EDIT_LABEL : ROLE_CONSTANTS.ADD_LABEL
          } ${ECOMMERCE_SUB_CATEGORY_CONSTANTS.ADD_HEADER}`}
        />
        <div className="col-12">
          <Formik
            enableReinitialize={!sorting}
            initialValues={id ? SetEditValues(CMSSubCategory) : initialValues}
            validationSchema={EcommerceSubCategorySchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              PropsRef.current = props;
              const updateImage = (e, fieldName, value) => {
                props.setFieldValue(
                  fieldName,
                  Object.assign(e?.target?.files?.[0], {
                    preview: value,
                  })
                );
              };
              return (
                <Form>
                  <ScrollToFieldError />
                  <div className="row">
                    <div className="col-8">
                      <div className="row">
                        <div className="col-12">
                          <Card>
                            <CardHeader>
                              <div className="d-flex justify-content-between">
                                <h4 className="card-title">
                                  {
                                    ECOMMERCE_SUB_CATEGORY_CONSTANTS.GENERAL_HEADER
                                  }
                                </h4>
                              </div>
                            </CardHeader>
                            <CardBody>
                              <div className="row">
                                <div className="col-md-6">
                                  <MultiSelect
                                    showNoOptionMsg={true}
                                    select={false}
                                    disabled={id !== undefined}
                                    name={
                                      ECOMMERCE_SUB_CATEGORY_CONSTANTS.CATEGORY
                                    }
                                    label="Category"
                                    options={getCategoryOptions}
                                    placeholder="Select category"
                                    isLoading={
                                      CMSCategoryStatus === STATUSES.LOADING
                                    }
                                    onFocusHandler={() => {
                                      dispatch(
                                        fetchCMSCategories({
                                          active: true,
                                        })
                                      );
                                    }}
                                    onChangeHandler={onCategoryChange}
                                  />
                                  <ErrorMessage
                                    name={
                                      ECOMMERCE_SUB_CATEGORY_CONSTANTS.CATEGORY
                                    }
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <MultiSelect
                                    showNoOptionMsg={true}
                                    select={false}
                                    name={
                                      ECOMMERCE_SUB_CATEGORY_CONSTANTS.SUB_CATEGORY
                                    }
                                    label={
                                      ECOMMERCE_SUB_CATEGORY_CONSTANTS.SUB_CATEGORY_LABEL
                                    }
                                    disabled={
                                      props.values?.product_category?.length ===
                                        0 ||
                                      props.values?.product_category?.[0] ===
                                        null ||
                                      id !== undefined
                                    }
                                    options={getSubCategoryOptions}
                                    placeholder={
                                      ECOMMERCE_SUB_CATEGORY_CONSTANTS.SUB_CATEGORY_PLACEHOLDER
                                    }
                                    isLoading={
                                      sub_category_status === STATUSES.LOADING
                                    }
                                    // onFocusHandler={() => {
                                    //   dispatch(
                                    //     getSubCategories(
                                    //       false,
                                    //       1,
                                    //       "",
                                    //       props.values?.product_category?.[0]
                                    //         ? props.values
                                    //             ?.product_category?.[0]?.value
                                    //         : null
                                    //     )
                                    //   );
                                    //   setQuery("");
                                    // }}
                                    onInputChangeHandler={(value) => {
                                      value && setQuery(value);
                                    }}
                                    onKeyDownHandler={(e) => {
                                      if (e.key === "Enter") {
                                        optimizeSearch(query);
                                      }
                                    }}
                                    onChangeHandler={onSubCategoryChange}
                                  />
                                  <ErrorMessage
                                    name={
                                      ECOMMERCE_SUB_CATEGORY_CONSTANTS.SUB_CATEGORY
                                    }
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <CustomInput
                                    isRequired={true}
                                    type={FORM_CONSTANTS.TEXT}
                                    name={
                                      ECOMMERCE_SUB_CATEGORY_CONSTANTS.DISPLAY_NAME
                                    }
                                    label={
                                      ECOMMERCE_SUB_CATEGORY_CONSTANTS.DISPLAY_NAME_LABEL
                                    }
                                    placeholder={
                                      ECOMMERCE_SUB_CATEGORY_CONSTANTS.DISPLAY_NAME_PLACEHOLDER
                                    }
                                    value={props.values.display_name}
                                    onChangeHandler={(e) => {
                                      props.setFieldValue(
                                        ECOMMERCE_SUB_CATEGORY_CONSTANTS.ALT,
                                        e.target.value
                                          .replace(/ /g, "-")
                                          .toLowerCase()
                                      );
                                      props.setFieldValue(
                                        ECOMMERCE_SUB_CATEGORY_CONSTANTS.DISPLAY_NAME,
                                        e.target.value
                                      );
                                      props.setFieldValue(
                                        SEO_CONSTANTS.TITLE,
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    name={
                                      ECOMMERCE_SUB_CATEGORY_CONSTANTS.DISPLAY_NAME
                                    }
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <SelectStatus
                                    props={props}
                                    name={"is_active"}
                                    label={"Status"}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <FileUpload
                                    isDisabled={
                                      props.values?.product_sub_category
                                        ?.length === 0 ||
                                      props.values
                                        ?.product_sub_category?.[0] === null
                                    }
                                    value={null}
                                    name={ECOMMERCE_SUB_CATEGORY_CONSTANTS.ICON}
                                    label={
                                      ECOMMERCE_SUB_CATEGORY_CONSTANTS.ICON_LABEL
                                    }
                                    hideInput={
                                      (
                                        props.values?.icon?.preview ||
                                        props.values?.icon ||
                                        ""
                                      ).length > 0
                                    }
                                    preview={
                                      props.values?.icon?.preview ||
                                      props.values?.icon ||
                                      ""
                                    }
                                    multipleUpload={false}
                                    onChange={(e) => {
                                      if (isValidImage(e)) {
                                        dispatch(
                                          uploadImage({
                                            params: {
                                              folder_name:
                                                S3_FOLDER_CONSTANTS.SUB_CATEGORY_ICON,
                                              file_name:
                                                e?.target?.files?.[0]?.name,
                                              file: e?.target?.files?.[0],
                                            },
                                            updatePropsValues: (value) =>
                                              updateImage(
                                                e,
                                                ECOMMERCE_SUB_CATEGORY_CONSTANTS.ICON,
                                                value
                                              ),
                                          })
                                        );
                                      }
                                    }}
                                    deleteImage={() => {
                                      props.setFieldValue(
                                        ECOMMERCE_SUB_CATEGORY_CONSTANTS.ICON,
                                        ""
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    name={ECOMMERCE_SUB_CATEGORY_CONSTANTS.ICON}
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <CustomInput
                                    isDisabled={
                                      props.values?.product_sub_category
                                        ?.length === 0 ||
                                      props.values
                                        ?.product_sub_category?.[0] === null
                                    }
                                    value={props.values.alt}
                                    type={FORM_CONSTANTS.TEXT}
                                    name={ECOMMERCE_SUB_CATEGORY_CONSTANTS.ALT}
                                    label={
                                      ECOMMERCE_SUB_CATEGORY_CONSTANTS.ALT_LABEL
                                    }
                                    placeholder={
                                      ECOMMERCE_SUB_CATEGORY_CONSTANTS.ALT_PLACEHOLDER
                                    }
                                  />
                                  <ErrorMessage
                                    name={ECOMMERCE_SUB_CATEGORY_CONSTANTS.ALT}
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <TextArea
                                    rows={3}
                                    name={"short_summary"}
                                    label={"Short summary"}
                                    placeholder={
                                      "Enter short summary for the sub-category"
                                    }
                                  />
                                  <ErrorMessage
                                    name={"short_summary"}
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <WidgetsContentDetails
                            props={props}
                            setSorting={setSorting}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Button
                            loading={
                              CMSSubCategoryStatus === STATUSES.LOADING &&
                              props.isSubmitting
                            }
                            type={BUTTON_CONSTANTS.BUTTON}
                            text={FORM_CONSTANTS.SUBMIT}
                            btnClassNames={
                              "btn btn-primary align-self-center w-25"
                            }
                            onClickHandler={() => {
                              props.submitForm();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="row">
                        <SEODetailsform
                          compact={true}
                          slugHelper={
                            (selectedCMSCategory &&
                            selectedCMSCategory?.meta_slug
                              ? `${selectedCMSCategory?.meta_slug
                                  ?.replace(/ /g, "-")
                                  .toLowerCase()}/`
                              : props?.values?.slugHelper) || ""
                          }
                          props={props}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export { SubCategoryForm as Ecommerce_SubCategoryForm };
