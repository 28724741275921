import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  Container,
  ContentHeader,
  Dropdown,
  MultiSelect,
  PendingPayLaterList,
  Search,
  SelectStoreModal,
} from "components";
import { Form, Formik, FormikProps } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  getBranches,
  setSelectedStore,
} from "store/ManageBranches/branchSlice";
import { FilterCreditNotes, FilterOrder, resetOrderState } from "store/POS";
import {
  ADVANCE_ORDERS_CONSTANTS,
  Branch,
  CASHIER_CONSTANTS,
  financialYear,
  findStore,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  getItemWithExpiry,
  ORDER_CONSTANTS,
  POS_MODAL_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";

const CashierSearch = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    common: { current_page },
    branch: { branch: stores, selectedStore },
  } = useAppSelector((state) => state.root);

  const [storeModal, setStoreModal] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(GENERAL_CONSTANTS.EMPTY_STRING);

  const [payLaterQuery, setPayLaterQuery] = useState(
    GENERAL_CONSTANTS.EMPTY_STRING
  );

  const PropsRef = useRef<FormikProps<any>>(null);

  const Pay_Later_PropsRef = useRef<
    FormikProps<{
      start_date: string;
      end_date: string;
    }>
  >();

  const currentStore: Branch = useMemo(() => {
    if (selectedStore) {
      return findStore(selectedStore, stores);
    }
    return GENERAL_CONSTANTS.EMPTY_STRING;
  }, [selectedStore, stores]);

  const getStoreOptions = useMemo(() => {
    return stores && stores.length > 0
      ? stores.map((item) => ({
          value: item.id,
          label: item.store_name,
        }))
      : [];
  }, [stores]);

  const generateInvoiceCode = (order_type, code) => {
    if (order_type) {
      return `${CASHIER_CONSTANTS.ADVANCE_ORDER_CODE}/${
        currentStore?.store_code
      }/${financialYear(new Date())}/${code}`;
    } else {
      return `${currentStore?.store_code}/${financialYear(new Date())}/${code}`;
    }
  };

  const fetchOrderDetails = useCallback(
    ({ order_type, query, selectedStore }: any) => {
      if (query !== GENERAL_CONSTANTS.EMPTY_STRING) {
        if (PropsRef.current?.values?.order_type?.[0]?.value === 1) {
          dispatch(
            FilterCreditNotes({
              store: selectedStore,
              type: ADVANCE_ORDERS_CONSTANTS.CREDIT_NOTE_TYPE,
              pageNo: 1,
              cashier: true,
              code: generateInvoiceCode(
                PropsRef.current?.values?.order_type?.[0]?.value,
                query
              ),
              navigate,
            })
          );
        } else {
          dispatch(
            FilterOrder({
              store: selectedStore,
              cashier: true,
              code: generateInvoiceCode(
                PropsRef.current?.values?.order_type?.[0]?.value,
                query
              ),
              navigate,
              status: GENERAL_CONSTANTS.PENDING,
              pageNo: 1,
              query: GENERAL_CONSTANTS.EMPTY_STRING,
            })
          );
        }
      }
    },
    [selectedStore, PropsRef.current?.values, query]
  );

  const pay_later_pending_update_page = (
    pageNo: number,
    store,
    orderQuery = payLaterQuery,
    start_Date: string = Pay_Later_PropsRef.current?.values?.start_date,
    end_Date: string = Pay_Later_PropsRef.current?.values?.end_date
  ) => {
    // default filter options
    const filterOptions = {
      pageNo,
      query: orderQuery,
      type: 5,
      store: store,
      status: GENERAL_CONSTANTS.PENDING,
    };

    // if the start date and end date are not empty, add them to the filter options
    start_Date && (filterOptions[ORDER_CONSTANTS.START_DATE] = start_Date);
    end_Date && (filterOptions[ORDER_CONSTANTS.END_DATE] = end_Date);

    if (orderQuery) {
      // if the order query is not a store code, filter the orders by the query
      dispatch(
        FilterOrder({
          ...filterOptions,
          query: orderQuery,
        })
      );
    } else if (store) {
      // if the store is selected, filter the orders by the store
      dispatch(FilterOrder(filterOptions));
    }
  };

  useEffect(() => {
    pay_later_pending_update_page(
      current_page,
      selectedStore,
      payLaterQuery,
      Pay_Later_PropsRef.current?.values.start_date,
      Pay_Later_PropsRef.current?.values.end_date
    );
    dispatch(getBranches({ active: true, edit: { setStoreModal } }));
    return () => {
      dispatch(resetOrderState());
    };
  }, [dispatch, current_page]);

  return (
    <div className="content-wrapper row m-0 p-0 text-sm">
      {!selectedStore && (
        <SelectStoreModal
          modal={storeModal}
          setModal={setStoreModal}
          update={(value) => {
            PropsRef.current?.setFieldValue("store", value);
            pay_later_pending_update_page(
              1,
              Number(value),
              payLaterQuery,
              Pay_Later_PropsRef.current?.values.start_date,
              Pay_Later_PropsRef.current?.values.end_date
            );
          }}
        />
      )}
      <div className="content">
        <div className="col-12 d-flex flex-column">
          <Container>
            <ContentHeader pageHeader={CASHIER_CONSTANTS.CASHIER_HEADER} />
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    store: [
                      {
                        value: currentStore?.id,
                        label: currentStore?.store_name,
                      },
                    ],
                    order_type: CASHIER_CONSTANTS.FILTER_OPTION[0],
                    code: GENERAL_CONSTANTS.EMPTY_STRING,
                    query: GENERAL_CONSTANTS.EMPTY_STRING,
                  }}
                  onSubmit={(values) => {}}
                >
                  {(props) => {
                    PropsRef.current = props;
                    return (
                      <Form>
                        <div className="d-flex flex-column flex-md-row align-items-center">
                          <div className="col-md-3">
                            <MultiSelect
                              select={false}
                              name="order_type"
                              label={CASHIER_CONSTANTS.FILTER_LABEL}
                              options={CASHIER_CONSTANTS.FILTER_OPTION}
                              onChangeHandler={(e: any) => {
                                // if (
                                //   e?.value !== GENERAL_CONSTANTS.EMPTY_STRING
                                // ) {
                                //   fetchOrderDetails({
                                //     order_type: e?.value,
                                //     query: query,
                                //     selectedStore:
                                //       props?.values?.store?.[0]?.value,
                                //   });
                                // }
                                props.setFieldValue(
                                  CASHIER_CONSTANTS.ORDER_TYPE,
                                  [e]
                                );
                              }}
                            />
                          </div>
                          <div className="col-md-3">
                            <MultiSelect
                              select={false}
                              name={CASHIER_CONSTANTS.STORE}
                              label={POS_MODAL_CONSTANTS.SELECT_STORE}
                              options={getStoreOptions}
                              value={props.values.store}
                              onChangeHandler={(e: any) => {
                                e &&
                                  e.value &&
                                  dispatch(setSelectedStore(Number(e.value)));
                                pay_later_pending_update_page(
                                  1,
                                  Number(e.value),
                                  payLaterQuery,
                                  Pay_Later_PropsRef.current?.values.start_date,
                                  Pay_Later_PropsRef.current?.values.end_date
                                );
                                props.setFieldValue(CASHIER_CONSTANTS.STORE, [
                                  e,
                                ]);
                              }}
                            />
                          </div>
                          <div className="col-md-6 mt-3">
                            <Search
                              type={FORM_CONSTANTS.NUMBER}
                              prepend={generateInvoiceCode(
                                props.values?.order_type?.[0]?.value,
                                GENERAL_CONSTANTS.EMPTY_STRING
                              )}
                              query={props.values.query}
                              placeholder={GENERAL_CONSTANTS.SEARCH}
                              setQuery={setQuery}
                              searchByQuery={(e: any) => {
                                if (
                                  e > 0 &&
                                  e !== GENERAL_CONSTANTS.EMPTY_STRING
                                ) {
                                  setQuery(e);
                                  fetchOrderDetails({
                                    order_type:
                                      props?.values?.order_type?.[0]?.value,
                                    query: e,
                                    selectedStore:
                                      props?.values?.store?.[0]?.value,
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row mt-2">
                          <div className="col-md-2">
                            <Button
                              text={CASHIER_CONSTANTS.VIEW_ALL_ORDERS}
                              btnClassNames="btn btn-sm btn-dark w-100"
                              onClickHandler={() => {
                                navigate("/admin/sales/cashier/pending-orders");
                              }}
                            />
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
            <ContentHeader
              pageHeader={CASHIER_CONSTANTS.PENDING_PAY_LATER_HEADER}
              noBread={true}
            />
            <PendingPayLaterList
              update_page={pay_later_pending_update_page}
              PropsRef={Pay_Later_PropsRef}
              query={payLaterQuery}
              setQuery={setPayLaterQuery}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export { CashierSearch };
