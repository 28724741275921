import { faAdd, faSubtract, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, FontIcon } from "components/core-components";
import React, { useState } from "react";
import {
  AddDiscount,
  RemoveDiscount,
  RemoveProduct,
  ResetWholeCart,
  UpdateProductQuantity,
  clearCartDiscount,
} from "store/POS/cartSlice";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  ICartItem,
  IDiscount,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  RESPONSIVE_CONSTANTS,
  TOAST_CONSTANTS,
  Toast,
  checkValuesForDiscount,
  displayValue,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { ConfirmModal } from "components/ConfirmModal";

interface IProps {
  focusRef: any;
  cartItem: ICartItem;
  setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDataToDelete: React.Dispatch<React.SetStateAction<any>>;
  index: number;
  productGroupLength?: number;
  canEdit?: boolean;
}

/**
 * Renders a table row for a single item in the POS cart.
 *
 * @param {Object} props - The component props.
 * @param {React.RefObject} props.focusRef - The reference to the focus element.
 * @param {ICartItem} props.cartItem - The cart item object.
 * @param {number} props.index - The index of the cart item.
 * @param {number} props.productGroupLength - The length of the product group.
 * @returns {JSX.Element} The rendered component.
 */

const POSCarItems = ({
  focusRef,
  cartItem,
  index,
  setConfirmModal,
  setDataToDelete,
  productGroupLength,
  canEdit = false,
}: IProps) => {
  const dispatch = useAppDispatch();

  const {
    cart: { cart },
    common: { screenSize, portrait },
  } = useAppSelector((state) => state.root);

  const setDiscountType = (applyTo: ICartItem, index) => {
    let switchDiscount = applyTo.discount;

    switchDiscount = {
      ...switchDiscount,
      discount_type:
        cartItem.discount.discount_type !== GENERAL_CONSTANTS.FLAT
          ? GENERAL_CONSTANTS.FLAT
          : GENERAL_CONSTANTS.PERCENTAGE,
    };
    if (checkValuesForDiscount(cart, switchDiscount, applyTo)) {
      dispatch(AddDiscount(applyTo, switchDiscount, index));
    } else {
      dispatch(RemoveDiscount(applyTo, index));
      Toast(POS_CART_CONSTANTS.DISCOUNT_ERROR_MESSAGE, TOAST_CONSTANTS.ERROR);
    }
  };

  const validateDiscount = (
    applyTo: ICartItem,
    validDiscount: boolean,
    newDiscount: IDiscount,
    index
  ) => {
    if (
      (newDiscount.discount_type === GENERAL_CONSTANTS.FLAT &&
        cartItem.discount.discount_type &&
        cartItem.discount.discount_type === GENERAL_CONSTANTS.FLAT) ||
      (newDiscount.discount_type === GENERAL_CONSTANTS.PERCENTAGE &&
        cartItem.discount.discount_type &&
        cartItem.discount.discount_type !== GENERAL_CONSTANTS.FLAT)
    ) {
      if (newDiscount.discount_value === 0) {
        applyTo
          ? dispatch(RemoveDiscount(applyTo, index))
          : dispatch(clearCartDiscount());
      } else {
        if (validDiscount) {
          dispatch(AddDiscount(applyTo, newDiscount, index));
        } else {
          dispatch(RemoveDiscount(applyTo, index));
          Toast(
            POS_CART_CONSTANTS.DISCOUNT_ERROR_MESSAGE,
            TOAST_CONSTANTS.ERROR
          );
        }
      }
    }
  };

  return (
    <tr
      id={`cartItem-${index}`}
      className={`text-sm ${productGroupLength === 0 ? "col-12" : ""}`}
      key={index}
      onClick={(e: any) => {
        if (
          e &&
          e.target &&
          typeof e.target.className === GENERAL_CONSTANTS.STRING
        ) {
          if (
            e.target.className.includes("select__option") ||
            e.target.className.includes("select__input") ||
            e.target.className.includes("form-control")
          ) {
            e && e.target && e?.target?.focus();
          } else {
            focusRef.current.focus();
          }
        }
      }}
    >
      <td colSpan={1} className="align-middle text-center">
        {productGroupLength !== 0 ? productGroupLength + index : index + 1}
      </td>
      {!window.mobileAndTabletCheck() ? (
        <>
          <td
            colSpan={1}
            className={`align-middle text-wrap ${
              productGroupLength === 0 ? "col-1" : ""
            }`}
          >
            {cartItem.product_code}
          </td>
          <td
            colSpan={2}
            className={`align-middle text-wrap flex-grow-1 ${
              productGroupLength === 0 ? "col-1" : ""
            }`}
          >
            {cartItem.print_name}
          </td>
        </>
      ) : (
        <td colSpan={2}>
          <div className="d-flex flex-column gap-1 ">
            <div className="d-flex flex-row">
              <div className="text-wrap text-bold">{cartItem.product_code}</div>
            </div>
            <div className="d-flex flex-row">
              <div className="text-wrap">{cartItem.print_name}</div>
            </div>
          </div>
        </td>
      )}
      <td
        colSpan={window.mobileAndTabletCheck() ? 2 : 1}
        className="align-middle text-center p-0"
      >
        <div className="d-flex flex-column justify-content-center flex-md-row col-12 input-group-sm">
          {cartItem.meddler && cartItem.productGroup.id === 0 && (
            <div
              className={`input-group-prepend text-center ${
                portrait ? "w-100" : ""
              }`}
            >
              <div
                className={`input-group-text text-center ${
                  portrait ? "w-100" : ""
                }`}
              >
                <h6 className="w-100 p-0 m-0">{cartItem.count}</h6>
              </div>
            </div>
          )}
          {!cartItem.meddler && canEdit && (
            <div
              className={`input-group-btn text-center ${
                portrait ? "order-2" : "order-0"
              }`}
            >
              <Button
                testId={`subtract-${index}`}
                text={<FontIcon icon={faSubtract} iconSize="xs" />}
                btnClassNames={`btn btn-dark btn-${
                  screenSize?.width > RESPONSIVE_CONSTANTS.MEDIUM ? "sm" : "xs"
                } ${portrait ? "w-100 rounded-bottom-0" : "ml-2"}`}
                type={BUTTON_CONSTANTS.BUTTON}
                onClickHandler={(e) => {
                  if (e.detail !== 0) {
                    dispatch(
                      UpdateProductQuantity(
                        cartItem,
                        -1,
                        GENERAL_CONSTANTS.FALSE,
                        GENERAL_CONSTANTS.FALSE,
                        cartItem.originalIndex
                      )
                    );
                  }
                }}
              />
            </div>
          )}
          <input
            id={`quantity-${index}`}
            tabIndex={index + 4}
            className={`form-control text-center order-1 ${
              portrait ? "w-100" : "mx-2"
            }`}
            type={FORM_CONSTANTS.NUMBER}
            min={0}
            step={0.001}
            value={cartItem.quantity}
            disabled={!canEdit}
            onChange={(e) => {
              dispatch(
                UpdateProductQuantity(
                  cartItem,
                  Number(e.target.value) - cartItem.quantity,
                  cartItem.meddler,
                  GENERAL_CONSTANTS.FALSE,
                  cartItem.originalIndex
                )
              );
            }}
          />
          {!cartItem.meddler && canEdit && (
            <div
              className={`input-group-btn ${portrait ? "order-0" : "order-2"}`}
            >
              <Button
                testId={`add-${index}`}
                text={<FontIcon icon={faAdd} iconSize="xs" />}
                btnClassNames={`btn btn-dark btn-${
                  screenSize?.width > RESPONSIVE_CONSTANTS.MEDIUM ? "sm" : "xs"
                } ${portrait ? "w-100" : "mr-2"}`}
                type={BUTTON_CONSTANTS.BUTTON}
                onClickHandler={(e) => {
                  if (e.detail !== 0) {
                    dispatch(
                      UpdateProductQuantity(
                        cartItem,
                        1,
                        GENERAL_CONSTANTS.FALSE,
                        GENERAL_CONSTANTS.FALSE,
                        cartItem.originalIndex
                      )
                    );
                  }
                }}
              />
            </div>
          )}
          {!portrait && cartItem.uom && (
            <div className="input-group-append order-3">
              <div className="input-group-text">{cartItem?.uom?.uom_code}</div>
            </div>
          )}
        </div>
      </td>
      <td colSpan={1} className="align-middle text-center" id={`mrp-${index}`}>
        {POS_CONSTANTS.RUPEE_SIGN}
        {displayValue(to2Decimal(cartItem.mrp))}
      </td>
      <td colSpan={2} className="align-middle text-center">
        <div
          className={`d-flex flex-row col-12 input-group-sm ${
            portrait ? "mx-3" : ""
          }`}
        >
          <div className="input-group-prepend">
            <div className="input-group-text text-bg-dark p-0">
              <Button
                testId={`discount-toggle-${index}`}
                text={`${
                  cartItem.discount.discount_type &&
                  cartItem.discount.discount_type ===
                    GENERAL_CONSTANTS.PERCENTAGE
                    ? GENERAL_CONSTANTS.PERCENTAGE_SYMBOL
                    : GENERAL_CONSTANTS.FLAT_SYMBOL
                }`}
                btnClassNames={`btn text-white btn-transparent btn-${
                  screenSize?.width > RESPONSIVE_CONSTANTS.MEDIUM ? "sm" : "xs"
                } border-0`}
                type="button"
                onClickHandler={() => {
                  dispatch(clearCartDiscount());
                  setDiscountType(cartItem, cartItem.originalIndex);
                }}
              />
            </div>
          </div>
          <input
            id={`discount-${index}`}
            tabIndex={index + 4}
            type={FORM_CONSTANTS.NUMBER}
            step={0.001}
            min={0}
            className="form-control"
            placeholder="Enter Discount"
            value={cartItem.discount.discount_value}
            onChange={(e) => {
              dispatch(clearCartDiscount());
              validateDiscount(
                cartItem,
                checkValuesForDiscount(
                  cart,
                  {
                    ...cartItem.discount,
                    discount_value: Number(e.target.value),
                  },
                  cartItem
                ),
                {
                  ...cartItem.discount,
                  discount_value: Number(e.target.value),
                },
                cartItem.originalIndex
              );
            }}
            onBlur={() => {
              (index === 0 || cart.cartItems.length - 1 === index) &&
                focusRef.current.focus();
            }}
          />
        </div>
      </td>
      <td
        colSpan={1}
        className="align-middle text-center"
        id={`tax_applied-${index}`}
      >
        {POS_CONSTANTS.RUPEE_SIGN}
        {displayValue(to2Decimal(cartItem.tax_applied))}
      </td>
      <td
        colSpan={1}
        className="align-middle text-center"
        id={`unit_cost-${index}`}
      >
        {POS_CONSTANTS.RUPEE_SIGN}
        {cartItem.quantity
          ? displayValue(
              to2Decimal(cartItem.payable_amount / cartItem.quantity)
            )
          : 0}
      </td>
      <td
        colSpan={1}
        className="align-middle text-center"
        id={`net_payable_amount-${index}`}
      >
        {POS_CONSTANTS.RUPEE_SIGN}
        {displayValue(to2Decimal(cartItem.payable_amount))}
      </td>
      <td colSpan={1} className="align-middle text-center">
        <Button
          testId={`remove-${index}`}
          text={<FontIcon icon={faTrash} iconSize="xs" />}
          btnClassNames="btn btn-danger btn-sm text-center"
          isDisabled={!canEdit}
          type={BUTTON_CONSTANTS.BUTTON}
          onClickHandler={() => {
            setConfirmModal(true);
            setDataToDelete({
              ...cartItem,
              productGroupLength: productGroupLength,
            });
          }}
        />
      </td>
    </tr>
  );
};

export { POSCarItems };
