import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  FontIcon,
  IconButton,
  Loader,
  MultiSelect,
} from "components";
import { ErrorMessage, FieldArray } from "formik";
import React, { useEffect, useMemo, useState } from "react";

import {
  SITE_MANAGEMENT_CONSTANTS,
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  useAppSelector,
  STATUSES,
  capitalizeFirstLetter,
} from "utils";

const EmailDetails = ({
  props,
  arrayHelperRef,
  setCurrentItemToDelete,
  setConfirmModal,
}) => {
  const {
    root: {
      auth: { permissions },
    },
    ecommerce: {
      siteManagement: { email_settings, status },
    },
  } = useAppSelector((state) => state);

  const [showPassword, setShowPassword] = useState<Record<string, boolean>>({});

  const handleSetShowPassword = (index: number) => {
    setShowPassword((prev) => {
      return {
        [index]: !prev[index],
      };
    });
  };

  const siteManagementPermission = useMemo(
    () =>
      permissions?.find(
        (perm) => perm.module_name === "Manage Site Management"
      ),
    [permissions?.length]
  );

  const getUniqueConfigType = useMemo(() => {
    return (
      email_settings &&
      email_settings?.config_type
        ?.filter((item) => {
          return props.values.email_settings?.some((val) => {
            return (
              val.email_config_type?.[0]?.value &&
              val.email_config_type?.[0]?.value !== item
            );
          });
        })
        ?.map((item) => ({
          value: item,
          label: capitalizeFirstLetter(item),
        }))
    );
  }, [email_settings?.data?.length, props.values.email_settings?.length]);

  const canAddEmail = useMemo(() => {
    return (
      email_settings?.config_type?.length !==
      props.values.email_settings?.length
    );
  }, [email_settings?.data?.length, props.values.email_settings?.length]);

  return (
    <div className="col-12">
      <Card>
        <CardHeader>
          <div className="row align-items-center">
            <div className="col-4">
              <h3 className="card-title">
                {SITE_MANAGEMENT_CONSTANTS.EMAIL_HEADER}
              </h3>
            </div>
            <div className="col-8 text-end">
              <IconButton
                icon={faAdd}
                isDisabled={!canAddEmail}
                btnClassNames="btn btn-outline-primary"
                btnText={SITE_MANAGEMENT_CONSTANTS.EMAIL_HEADER}
                onClickHandler={() => {
                  arrayHelperRef?.push({
                    new: true,
                    email_host: "",
                    email_port: "",
                    email_username: "",
                    email_password: "",
                    email_config_type: "",
                  });
                }}
                type={BUTTON_CONSTANTS.BUTTON}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <FieldArray
            name="email_settings"
            render={(arrayHelpers) => {
              arrayHelperRef = arrayHelpers;
              return status === STATUSES.LOADING && !props.isSubmitting ? (
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              ) : (
                props.values?.email_settings &&
                  props.values?.email_settings?.length > 0 &&
                  props.values?.email_settings?.map((email, index) => (
                    <div key={index}>
                      <div className="row align-items-top">
                        <Card>
                          <CardHeader>
                            <div className="card-title">
                              {`${email?.email_username || index + 1}`}
                            </div>
                            <div className="card-tools">
                              <Button
                                type={BUTTON_CONSTANTS.BUTTON}
                                isDisabled={
                                  !siteManagementPermission?.perm_delete ||
                                  props.values?.email_settings?.length === 1
                                }
                                text={<FontIcon icon={faTrash} />}
                                btnClassNames={"btn btn-danger btn-sm mt-2"}
                                onClickHandler={() => {
                                  if (email?.id) {
                                    setConfirmModal(true);
                                    setCurrentItemToDelete({
                                      id: email.id,
                                      removeIndex: () => {
                                        arrayHelpers.remove(index);
                                      },
                                      type: SITE_MANAGEMENT_CONSTANTS.TABS[1],
                                    });
                                  } else {
                                    arrayHelpers.remove(index);
                                  }
                                }}
                              />
                            </div>
                          </CardHeader>
                          <CardBody>
                            <div className="row">
                              <div className="col-6">
                                <CustomInput
                                  isDisabled={email?.id}
                                  type={FORM_CONSTANTS.TEXT}
                                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.USERNAME}`}
                                  label={
                                    SITE_MANAGEMENT_CONSTANTS.USERNAME_LABEL
                                  }
                                  placeholder={
                                    SITE_MANAGEMENT_CONSTANTS.USERNAME_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.USERNAME}`}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div className="col-6">
                                <CustomInput
                                  isDisabled={email?.id}
                                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.PASSWORD}`}
                                  label={
                                    SITE_MANAGEMENT_CONSTANTS.PASSWORD_LABEL
                                  }
                                  placeholder={
                                    SITE_MANAGEMENT_CONSTANTS.PASSWORD_PLACEHOLDER
                                  }
                                  isRequired={true}
                                  isPass={true}
                                  setShowPass={() =>
                                    handleSetShowPassword(index)
                                  }
                                  showPass={showPassword[index]}
                                  type={
                                    showPassword[index]
                                      ? FORM_CONSTANTS.TEXT
                                      : FORM_CONSTANTS.PASSWORD
                                  }
                                  value={email?.password}
                                />
                                <ErrorMessage
                                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.PASSWORD}`}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <CustomInput
                                  isDisabled={email?.id}
                                  type={FORM_CONSTANTS.TEXT}
                                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.HOST}`}
                                  label={SITE_MANAGEMENT_CONSTANTS.HOST_LABEL}
                                  placeholder={
                                    SITE_MANAGEMENT_CONSTANTS.HOST_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.HOST}`}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div className="col-md-4">
                                <CustomInput
                                  isDisabled={email?.id}
                                  type={FORM_CONSTANTS.TEXT}
                                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.PORT}`}
                                  label={SITE_MANAGEMENT_CONSTANTS.PORT_LABEL}
                                  placeholder={
                                    SITE_MANAGEMENT_CONSTANTS.PORT_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.PORT}`}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                              <div className="col-md-4">
                                <MultiSelect
                                  disabled={email?.id}
                                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.CONFIG_TYPE}`}
                                  label={
                                    SITE_MANAGEMENT_CONSTANTS.CONFIG_TYPE_LABEL
                                  }
                                  options={getUniqueConfigType}
                                  placeholder={
                                    SITE_MANAGEMENT_CONSTANTS.CONFIG_TYPE_PLACEHOLDER
                                  }
                                />
                                <ErrorMessage
                                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${index}.${SITE_MANAGEMENT_CONSTANTS.CONFIG_TYPE}`}
                                  component={FORM_CONSTANTS.ERROR_PARENT}
                                  className={FORM_CONSTANTS.ERROR}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  ))
              );
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export { EmailDetails };
