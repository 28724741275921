import { Button, Card, CardBody, Modal } from "components/core-components";
import React from "react";
import { PrintCreditNote, PrintLastBill } from "store/POS";
import {
  BUTTON_CONSTANTS,
  GENERAL_CONSTANTS,
  makePDFWindow,
  POS_CART_CONSTANTS,
  POS_MODAL_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PrintOptionsModal = ({ modal, setModal }: IProps) => {
  const dispatch = useAppDispatch();

  const {
    common: { status },
    order: { printOptions },
  } = useAppSelector((state) => state.root);

  const bill = printOptions.find(
    (option) => option.type === POS_CART_CONSTANTS.BILL_CONTENT
  );

  const balanceCreditNote = printOptions.find(
    (option) => option.type === POS_CART_CONSTANTS.BALANCE_CREDIT_CONTENT
  );

  return (
    <Modal
      modalClassNames="w-100"
      modalSize={GENERAL_CONSTANTS.LARGE}
      title={POS_MODAL_CONSTANTS.PRINT}
      showModal={modal}
      onClickHandler={() => {
        setModal(GENERAL_CONSTANTS.FALSE);
      }}
    >
      <Card>
        <CardBody>
          <div className="d-flex flex-row">
            <div className="col-12">
              <div className="row">
                <div className="col-6 text-sm text-center p-1">
                  <Button
                    text={POS_CART_CONSTANTS.BILL_LABEL}
                    type={BUTTON_CONSTANTS.BUTTON}
                    loading={
                      status.state === STATUSES.LOADING &&
                      status.type ===
                        POS_CART_CONSTANTS.PRINT_LAST_BILL_LOADING_STATE
                    }
                    btnClassNames="btn btn-dark btn-primary text-sm w-100"
                    onClickHandler={() => {
                      dispatch(PrintLastBill({ bill_id: bill.content }));
                      // makePDFWindow(bill.content.result.bill);
                    }}
                  />
                </div>
                <div className="col-6 text-sm text-center p-1">
                  <Button
                    text={POS_CART_CONSTANTS.BALANCE_CREDIT}
                    type={BUTTON_CONSTANTS.BUTTON}
                    loading={
                      status.state === STATUSES.LOADING &&
                      status.type ===
                        POS_CART_CONSTANTS.PRINT_CREDIT_NOTE_LOADING_STATE
                    }
                    btnClassNames="btn btn-dark btn-primary text-sm w-100"
                    onClickHandler={() => {
                      dispatch(PrintCreditNote(balanceCreditNote.content));
                      // makePDFWindow(
                      //   balanceCreditNote.content.result.credit_note
                      // );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Modal>
  );
};

export { PrintOptionsModal };
