import { refreshAuthToken } from "apis/restApis";
import axios, {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosInstance,
} from "axios";
import { capitalizeFirstLetter, Toast } from "./helper";
import { ROUTES, TOAST_CONSTANTS } from "./constants";

// Type definitions for better type safety
interface ErrorResponse {
  message?: string;
  result?: Record<string, string[]>;
  error?: string;
}

// Centralized error message handling
const handleErrorMessage = (message: string) => {
  Toast(message, TOAST_CONSTANTS.ERROR);
  return Promise.reject(message);
};

// Handle validation errors from 400 responses
const handleValidationErrors = (errorResponse: ErrorResponse) => {
  if (errorResponse?.result && Object.keys(errorResponse.result).length > 0) {
    const firstError = Object.values(errorResponse.result)[0]?.[0];
    return handleErrorMessage(capitalizeFirstLetter(firstError));
  }
  return handleErrorMessage(errorResponse.message || "Bad Request");
};

// Handle auth token refresh
const handleTokenRefresh = async (error: AxiosError) => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    return handleSessionExpiration();
  }

  try {
    const response: any = await refreshAuthToken({
      refresh_token: refreshToken,
    });
    const { access_token, refresh_token } = response.result;

    localStorage.setItem("accessToken", access_token);
    localStorage.setItem("refreshToken", refresh_token);

    if (error.config) {
      error.config.headers.Authorization = `Bearer ${access_token}`;
      return axios(error.config);
    }
  } catch (refreshError) {
    if (
      refreshError ===
      "Invalid refresh token provided. Please obtain a new refresh token and try again"
    ) {
      return handleSessionExpiration();
    }
    return Promise.reject(refreshError);
  }
};

// Handle session expiration
const handleSessionExpiration = () => {
  handleErrorMessage("Session Expired");
  localStorage.clear();
  setTimeout(() => {
    window.location.href = ROUTES.LOGIN;
  }, 1000);
  return Promise.reject("Session Expired");
};

// HTTP error status map
const HTTP_ERROR_HANDLERS = {
  400: (error: AxiosError) =>
    handleValidationErrors(error.response?.data as ErrorResponse),
  401: handleTokenRefresh,
  403: (error: AxiosError) =>
    handleErrorMessage(
      (error.response?.data as ErrorResponse)?.error ?? "Forbidden"
    ),
  404: () => handleErrorMessage("Page Not Found"),
  417: (error: AxiosError) => Promise.reject(error.response?.data),
  500: () => handleErrorMessage("Something went wrong on the server"),
  502: () => handleErrorMessage("Bad Gateway"),
  504: () => handleErrorMessage("Request Timeout"),
};

// Request interceptor
const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  return config;
};

// Response interceptor
const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

// Error interceptor
const onResponseError = async (error: AxiosError) => {
  // Handle network errors
  if (error.code === "ERR_NETWORK" && error.message === "Network Error") {
    return handleErrorMessage("Please check your internet connection");
  }

  // Handle canceled requests
  if (error.name === "CanceledError" && error.code === "ERR_CANCELED") {
    return Promise.reject(error);
  }

  // Handle HTTP errors
  if (error.response) {
    const handler =
      HTTP_ERROR_HANDLERS[
        error.response.status as keyof typeof HTTP_ERROR_HANDLERS
      ];
    if (handler) {
      return handler(error);
    }
    return handleErrorMessage(
      `Unexpected HTTP Error: ${error.response.status}`
    );
  }

  // Handle non-string response data
  if (error.response?.data && typeof error.response.data !== "string") {
    return Promise.reject(error.response.data);
  }

  return Promise.reject(error);
};

// Create and configure Axios instance
const createAxiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use(onRequest, (error) =>
    Promise.reject(error)
  );
  instance.interceptors.response.use(onResponse, onResponseError);

  return instance;
};

export const setupInterceptorsTo = createAxiosInstance();
