import * as Yup from "yup";
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig } from "./helper";
import { SITE_MANAGEMENT_CONSTANTS } from "./constants";
import { TWidgetProperty } from "./types";

//Login Schema
export const LoginSchema = Yup.object().shape({
  login_email: Yup.string()
    .email("Please enter valid email")
    .required("Email is required"),
  login_password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
});

export type TLogin = Yup.InferType<typeof LoginSchema>;

//Role Schema
export const RoleSchema = Yup.object().shape({
  role_name: Yup.string()
    .min(3, "Role Name must be at least 3 characters long")
    .max(50, "Role Name can be at most 50 characters long")
    .required("Role Name is required"),
  is_active: Yup.array().required("Status is required"),
  permissions: Yup.array()
    .of(
      Yup.object().shape({
        module: Yup.number(),
        module_name: Yup.string(),
        perm_view: Yup.boolean(),
        perm_add: Yup.boolean(),
        perm_edit: Yup.boolean(),
        perm_delete: Yup.boolean(),
        perm_authorize: Yup.boolean(),
      })
    )
    .test(
      "at-least-one-permission",
      "Atleast One Permission is required",
      (value) => {
        return value?.some((permission) => {
          return (
            permission.perm_view ||
            permission.perm_add ||
            permission.perm_edit ||
            permission.perm_delete ||
            permission.perm_authorize
          );
        });
      }
    ),
});

//User Schema
export const FetchEmployeeSchema = (employeeId: number) => {
  return Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().when("username", (values, schema) => {
      return employeeId
        ? schema.nullable()
        : schema
            .min(8, "Password must be at least 8 characters long")
            .required("Password is required");
    }),
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    role: Yup.array().required("Role is required"),
    stores: Yup.array().required("Atleast One Store is required"),

    date_joined: Yup.date().nullable(),
    mobile_number: Yup.number().nullable(),
    pan: Yup.string(),
    address: Yup.object()
      .shape({
        address_line_1: Yup.string(),
        address_line_2: Yup.string(),
        city: Yup.array(),
        state: Yup.array(),
        country: Yup.array(),
        pincode: Yup.number(),
      })
      .nullable(),
    bank_name: Yup.string(),
    bank_branch: Yup.string(),
    ifsc_code: Yup.string(),
    account_no: Yup.number(),
    account_holder: Yup.string(),
    salary: Yup.number(),
    commissions: Yup.number(),
    extra_wages: Yup.number(),
    comments: Yup.string(),
    // scannedDocuments: Yup.string(),
    // departments: Yup.array(),
  });
};

//Bank Schema
export const BankSchema = Yup.object().shape({
  bank_name: Yup.string().required("Bank Name is required"),
  ifsc_code: Yup.string().required("IFSC Code is required"),
  account_holder_name: Yup.string(),
  account_number: Yup.string(),
  is_upi_available: Yup.array().nullable(),

  branch_name: Yup.string(),
  opening_credit_balance: Yup.string(),
  opening_debit_balance: Yup.string(),

  bank_address: Yup.string(),
  bank_city: Yup.array().nullable(),
  bank_state: Yup.array().nullable(),
  bank_country: Yup.array().nullable(),
  bank_pincode: Yup.number(),

  is_active: Yup.array().nullable(),
});

//Customer Schema
export const CustomerSchema = Yup.object().shape({
  designation: Yup.array().nullable(),
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email address"),
  contact_number: Yup.number()
    .required("Contact Number is required")
    .test(
      "len",
      "Contact number must be 10 Digits ",
      (val) => val.toString().length === 10
    ),
  company_name: Yup.string(),
  remarks: Yup.string(),
  whatsapp_number: Yup.number(),
  dob: Yup.date().nullable(),
  anniversary: Yup.date().nullable(),
  address: Yup.object().shape({
    address_line_1: Yup.string(),
    address_line_2: Yup.string(),
    city: Yup.array(),
    state: Yup.array(),
    country: Yup.array(),
    pincode: Yup.number(),
  }),
  is_active: Yup.array(),
});
export const QuickCustomerSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // email: Yup.string().email("Invalid email address"),
  contact_number: Yup.number()
    .required("Contact Number is required")
    .test(
      "len",
      "Contact number must be 10 Digits ",
      (val) => val.toString().length === 10
    ),
  // whatsapp_number: Yup.number(),
  // dob: Yup.date().nullable(),
  // anniversary: Yup.date().nullable(),
  address: Yup.object()
    .shape({
      address_line_1: Yup.string(),
      address_line_2: Yup.string(),
      city: Yup.array(),
      state: Yup.array(),
      country: Yup.array(),
      pincode: Yup.number(),
    })
    .nullable(),
});

//Product Schema
export const ProductSchema = (isAvaryaProduct: boolean) => {
  return Yup.object().shape({
    print_name: Yup.string().required("Print Name is required"),
    product_code: Yup.string().nullable(),
    new_product_code: Yup.string().nullable(),
    expiration_days: isAvaryaProduct
      ? Yup.number().required("Expiration days is required")
      : Yup.number().nullable(),
    barcode_type: Yup.array().min(1, "Barcode Type is required"),
    category: Yup.array().min(1, "Category is required"),
    sub_category: Yup.array().min(1, "Sub-Category is required"),
    brand: Yup.array().min(1, "Brand is required"),
    hsn_code: Yup.array().min(1, "HSN Code is required"),
    net_weight: Yup.number().required("Net Weight is required"),
    selling_uom: Yup.array().min(1, "Selling UOM is required"),
    uom: Yup.array().required("Net Weight UOM is required"),
    tax: Yup.array().min(1, "Tax is required"),
    jain_friendly: Yup.array().min(1, "Jain Friendly is required"),
    taste: isAvaryaProduct
      ? Yup.array().min(1, "Taste is required")
      : Yup.array().nullable(),

    price: Yup.array()
      .of(
        Yup.object().shape({
          batch_name: Yup.string(),
          store_prices: Yup.array().of(
            Yup.object().shape({
              store: Yup.string(),
              mrp: Yup.number(),
              selling_discount: Yup.number(),
              selling_price: Yup.number(),
              discount_type: Yup.array(),
              ceil: Yup.boolean(),
              msq: Yup.number(),
            })
          ),
        })
      )
      .nullable(),

    ingredients:
      //  isAvaryaProduct
      //   ? Yup.string().required("Ingredients are required")
      //   :
      Yup.string().nullable(),
    description: Yup.string().nullable(),

    // barcode_mapping: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       barcode: Yup.string(),
    //       barcodeType: Yup.array(),
    //     })
    //   )
    //   .nullable(),

    nutrition_details: Yup.array()
      .of(
        Yup.object().shape({
          nutrient: Yup.array().nullable(),
          nutrition_numeric_value: Yup.number(),
          uom: Yup.array(),
        })
      )
      .nullable(),

    msq: Yup.array()
      .of(
        Yup.object().shape({
          store: Yup.array(),
          days: Yup.object().shape({
            Mon: Yup.number(),
            Tue: Yup.number(),
            Wed: Yup.number(),
            Thu: Yup.number(),
            Fri: Yup.number(),
            Sat: Yup.number(),
            Sun: Yup.number(),
          }),
        })
      )
      .nullable(),
  });
};

// export const ProductListingSchema = ProductSchema.shape({

// })

//Product Group Schema
export const ProductGroupSchema = Yup.object().shape({
  print_hamper_name: Yup.string().required("Print Name is required"),
  hamper_name: Yup.string().required("Hamper Name is required"),
  // hamper_code: Yup.string().required("Hamper Code is required"),
  category: Yup.array().min(1, "Category is required"),
  sub_category: Yup.array().min(1, "Sub Category is required"),
  hsn: Yup.array().min(1, "HSN Code is required"),
  barcodeType: Yup.array().min(1, "Barcode Type is required"),
  is_active: Yup.array(),

  products: Yup.array()
    .of(
      Yup.object().shape({
        product: Yup.string(),
        product_id: Yup.number(),
        batch_id: Yup.number(),
        quantity: Yup.number(),
        new: Yup.boolean(),
      })
    )
    .min(1, "Atleast One Product is required"),
  price: Yup.array()
    .of(
      Yup.object().shape({
        batch_name: Yup.string(),
        store_prices: Yup.array().of(
          Yup.object().shape({
            store: Yup.string(),
            mrp: Yup.number(),
            selling_discount: Yup.number(),
            selling_price: Yup.number(),
            discount_type: Yup.array(),
            ceil: Yup.boolean(),
          })
        ),
      })
    )
    .nullable(),

  barcodes: Yup.array()
    .of(
      Yup.object().shape({
        barcode: Yup.string(),
      })
    )
    .nullable(),

  minimum_selling_quantity: Yup.array()
    .of(
      Yup.object().shape({
        store: Yup.string(),
        Mon: Yup.number(),
        Tue: Yup.number(),
        Wed: Yup.number(),
        Thu: Yup.number(),
        Fri: Yup.number(),
        Sat: Yup.number(),
        Sun: Yup.number(),
      })
    )
    .nullable(),

  description: Yup.string().nullable(),
});

//Product Category Schema
export const ProductCategorySchema = Yup.object().shape({
  // department: Yup.array(),
  category_name: Yup.string().required("Category Name is required"),
  category_code: Yup.string().nullable(),
  description: Yup.string().nullable(),
  is_active: Yup.array(),
});

//Product Sub Category Schema
export const ProductSubCategorySchema = Yup.object().shape({
  categories: Yup.array().min(1, "Category is required"),
  sub_category_name: Yup.string().required("Sub Category Name is required"),
  sub_category_code: Yup.string().nullable(),
  description: Yup.string().nullable(),
  is_active: Yup.array(),
});

//Product Brands Schema
export const ProductBrandsSchema = Yup.object().shape({
  brand_name: Yup.string().required("Brand Name is required"),
  brand_code: Yup.string().nullable(),
  brand_description: Yup.string().nullable(),
  is_active: Yup.array(),
});

//Product Tax Schema
export const ProductTaxSchema = Yup.object().shape({
  tax_name: Yup.string().required("Tax name is required"),
  tax_rate: Yup.number().required("Tax rate is required"),
  // hsn_codes: Yup.array(),
  is_active: Yup.array(),
});

//HSN Code Schema
export const HSNCodeSchema = Yup.object().shape({
  hsn_name: Yup.string().required("HSN Name is required"),
  hsn_code: Yup.string().nullable(),
  // product_category: Yup.array(),
  is_active: Yup.array(),
});

//UOM Schema
export const UOMSchema = Yup.object().shape({
  uom_name: Yup.string().required("UOM Name is required"),
  is_active: Yup.array(),
  uom_code: Yup.string().nullable(),
  uom_no_decimal_places: Yup.number().nullable(),
});

//Branch Schema
export const BranchSchema = Yup.object().shape({
  outlet_type: Yup.array().min(1, "Select Outlet Type"),
  store_name: Yup.string().required("Please enter branch name"),
  store_code: Yup.string().required("Please enter branch code"),
  contact_name: Yup.string().required("Please enter contact person name"),
  contact_number: Yup.number().required("Please enter contact person number"),
  whatsapp_number: Yup.number().nullable(),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter branch email"),

  gstn: Yup.string().required("Please enter GSTN"),
  pan: Yup.string().required("Please enter PAN"),
  fssai_number: Yup.number().required("Please FSSAI Number"),

  address: Yup.object().shape({
    address_line_1: Yup.string().required("Please enter address line 1"),
    address_line_2: Yup.string().required("Please enter address line 1"),
    city: Yup.array().min(1, "Select City"),
    state: Yup.array().min(1, "Select State"),
    country: Yup.array().min(1, "Select Country"),
    pincode: Yup.number().required("Please enter pincode"),
  }),

  purchase_billing_address: Yup.object().shape({
    address_line_1: Yup.string().required("Please enter address line 1"),
    address_line_2: Yup.string().required("Please enter address line 2"),
    city: Yup.array().min(1, "Select City"),
    state: Yup.array().min(1, "Select State"),
    country: Yup.array().min(1, "Select Country"),
    pincode: Yup.number().required("Please enter pincode"),
  }),

  bank_name: Yup.string(),
  account_no: Yup.number(),
  account_holder_name: Yup.string(),

  is_ecommerce: Yup.array().min(1, "Please select an option"),
  markup: Yup.number().nullable().min(0),
  store_price_copy: Yup.array().nullable(),

  is_active: Yup.array(),

  embeded_map_link: Yup.string().when("show_on_ecommerce", (value, schema) => {
    return value && value?.[0]?.[0]?.value === 1
      ? schema.required(
          "Embeded map link is required when Show on Ecommerce is selected"
        )
      : schema.nullable();
  }),

  store_gmap: Yup.string().when("show_on_ecommerce", (value, schema) => {
    return value && value?.[0]?.[0]?.value === 1
      ? schema.required(
          "Store GMAP link is required when Show on Ecommerce is selected"
        )
      : schema.nullable();
  }),

  address_locality: Yup.string().when("show_on_ecommerce", (value, schema) => {
    console.log(value?.[0]?.[0]?.value);
    return value && value?.[0]?.[0]?.value === 1
      ? schema.required(
          "Address locality is required when Show on Ecommerce is selected"
        )
      : schema.nullable();
  }),

  latitude: Yup.number().when("show_on_ecommerce", (value, schema) => {
    return value && value?.[0]?.[0]?.value === 1
      ? schema
          .required("Latitude is required when Show on Ecommerce is selected")
          .min(-90, "Latitude must be at least -90")
          .max(90, "Latitude must be at most 90")
          .typeError("Latitude must be a valid number")
      : schema.nullable();
  }),

  longitude: Yup.number().when("show_on_ecommerce", (value, schema) => {
    return value && value?.[0]?.[0]?.value === 1
      ? schema
          .required("Longitude is required when Show on Ecommerce is selected")
          .min(-180, "Longitude must be at least -180")
          .max(180, "Longitude must be at most 180")
          .typeError("Longitude must be a valid number")
      : schema.nullable();
  }),

  menu_link: Yup.string().nullable(),

  new_barcode: Yup.array().min(1, "Please select an option"),
  advance_order_notification_enabled: Yup.array().min(
    1,
    "Please select an option"
  ),
  show_on_ecommerce: Yup.array().min(1, "Please select an option"),
});

//Store Department Schema
export const StoreDeptSchema = Yup.object().shape({
  department_name: Yup.string(),
  department_head_name: Yup.string(),
  department_head_contact_number: Yup.number(),
  department_head_email: Yup.string().email("Invalid email address"),
});

//Payment Terms Schema
export const PaymentTermsSchema = Yup.object().shape({
  term_type: Yup.array().required("Term Type is required"),
  term_name: Yup.string().required("Term Name is required"),
  term_days_after_delivery: Yup.number()
    .typeError("Must be a number")
    .nullable(),
  is_active: Yup.array(),
});

//Payment Modes Schema
export const PaymentModeSchema = Yup.object().shape({
  payment_mode: Yup.string().required("Payment Mode is required"),
  is_active: Yup.array(),
});

//vendor Schema
export const VendorSchema = Yup.object().shape({
  name: Yup.string(),
  company_name: Yup.string(),
  email: Yup.string().email("Invalid email address"),
  phone_numbers: Yup.array().of(
    Yup.object().shape({
      phone_number: Yup.string(),
    })
  ),
  whatsapp_number: Yup.number(),
  supplier_type: Yup.array(),
  remarks: Yup.string(),
  apply_tDS: Yup.array(),
  payment_mode: Yup.array(),
  payment_terms: Yup.array(),
  gstn: Yup.string(),
  pan: Yup.string(),
  contact_person_name: Yup.string(),
  contact_person_email: Yup.string().email("Invalid email address"),
  contact_person_number: Yup.number(),
  commission: Yup.number(),
  delivery_policy: Yup.array(),

  bank_details: Yup.object().shape({
    bank_name: Yup.array(),
    account_number: Yup.string(),
    ifsc_code: Yup.string(),
    branch_name: Yup.string(),
    account_type: Yup.array(),
  }),

  address: Yup.array().of(
    Yup.object().shape({
      address_type: Yup.array(),
      address_line_1: Yup.string(),
      address_line_2: Yup.string(),
      city: Yup.array(),
      state: Yup.array(),
      country: Yup.array(),
      pincode: Yup.number(),
    })
  ),

  stores: Yup.array().of(
    Yup.object().shape({
      store: Yup.array(),
      products: Yup.array().of(
        Yup.object().shape({
          product_name: Yup.array(),
          purchase_price: Yup.number(),
          landing_cost: Yup.number(),
          purchase_tax_included: Yup.array(),
          // turn_around_time: Yup.object().shape({
          //   min_days: Yup.number(),
          //   max_days: Yup.number(),
          // }),
          uom: Yup.array(),
          gst_included: Yup.array(),
          moq: Yup.number(),
          order_delivery: Yup.object().shape({
            preferred_ordering_days: Yup.array(),
            preferred_delivery_days: Yup.array(),
            // is_active: Yup.array(),
          }),
          alias: Yup.array().of(
            Yup.object().shape({
              product_alias_name: Yup.string(),
              // is_active: Yup.array(),
            })
          ),
        })
      ),
    })
  ),

  is_active: Yup.array(),
});

export type TVendor = Yup.InferType<typeof VendorSchema>;

export const VendorProductSchema = Yup.object().shape({
  store: Yup.array(),
  products: Yup.array().of(
    Yup.object().shape({
      product_name: Yup.array(),
      purchase_price: Yup.number(),
      landing_cost: Yup.number(),
      purchase_tax_included: Yup.array(),
      turn_around_time: Yup.object().shape({
        min_days: Yup.number(),
        max_days: Yup.number(),
      }),
      moq: Yup.number(),
      order_delivery: Yup.object().shape({
        preferred_ordering_days: Yup.array(),
        preferred_delivery_days: Yup.array(),
        // is_active: Yup.array(),
      }),
      alias: Yup.array().of(
        Yup.object().shape({
          product_alias_name: Yup.string(),
          // is_active: Yup.array(),
        })
      ),
    })
  ),
});

//Discount Schema
export const DiscountSchema = Yup.object().shape({
  discount_applicable: Yup.array(),
  discount_auto_apply: Yup.array(),
  discount_code: Yup.string(),
  exclude_products_with_discount: Yup.array(),
  discount_type: Yup.array(),
  discount_value: Yup.number(),
  category: Yup.array(),
  sub_category: Yup.array(),
  brand: Yup.array(),
  product: Yup.array(),
  discount_branches: Yup.array(),
  minimum_requirement_type: Yup.array(),
  minimum_range: Yup.number(),
  maximum_range: Yup.number(),
  customer_eligibility: Yup.array(),
  coupon_usage: Yup.array(),
  coupon_usage_time: Yup.number(),
  coupon_start_date: Yup.date(),
  coupon_end_date: Yup.date(),
  is_active: Yup.array(),
});

export const LabelSchema = Yup.object().shape({
  template: Yup.array().required("Template Name is required"),
  print_type: Yup.array().required("Printer Type is required"),
  size: Yup.array().required("Size is required"),
  printers: Yup.array().required("Printer is required"),
  is_active: Yup.array(),
});

export const PrinterSchema = Yup.object().shape({
  printer_name: Yup.string().required("Printer Name is required"),
  target: Yup.string(),
  series_name: Yup.string(),
  language: Yup.string(),
  is_active: Yup.array(),
});

export const BarcodeGeneratorSchema = Yup.object().shape({
  store: Yup.array(),
  product: Yup.array().min(1, "Product is required"),
  label: Yup.array().min(1, "Label is required"),
});

export const POSMultiplePaymentSchema = Yup.object().shape({
  payment_mode: Yup.array(),
  amount: Yup.number(),
});

export const AdvanceOrderSchema = Yup.object().shape({
  advance_amount: Yup.number().min(0).required("Advance Amount is required"),
  delivery_type: Yup.array().required("Delivery Type is required"),
  delivery_date: Yup.date().required("Delivery Date is required"),
  delivery_time: Yup.string().required("Delivery Time is required"),
  remarks: Yup.string(),
});

export const PayLaterSchema = Yup.object().shape({
  guaranteer_person: Yup.array().required("Guaranteer Person is required"),
  delivery_type: Yup.array().required("Delivery Type is required"),
  address: Yup.object()
    .shape({
      address_line_1: Yup.string(),
      address_line_2: Yup.string(),
      city: Yup.array(),
      state: Yup.array(),
      country: Yup.array(),
      pincode: Yup.number(),
    })
    .when("delivery_type", (values, schema) => {
      return values[0].value === "delivery"
        ? schema.required("Address is required")
        : schema.nullable();
    }),
  delivery_date: Yup.date().required("Delivery Date is required"),
  delivery_time: Yup.string().required("Delivery Time is required"),
  remarks: Yup.string(),
});

export const RedeemCreditNoteSchema = Yup.object().shape({
  creditNote: Yup.array().required("Please scan a credit note"),
  applyCredit: Yup.number().required("Please enter the amount to redeem"),
});

export const KPIReportSchema = Yup.object().shape({
  start_date: Yup.date().required("Start Date is required"),
  end_date: Yup.date().required("End Date is required"),
  selected_store: Yup.array().required("Store is required"),
});

const SEOSchema = Yup.object().shape({
  meta_title: Yup.string().nullable(),
  meta_description: Yup.string().nullable(),
  meta_slug: Yup.string().required("Slug is required"),
  meta_keywords: Yup.string().nullable(),
});

export const SingleImageSchema = Yup.mixed<File>()
  .test("fileSize", "File size must be less than 3MB", (value) =>
    checkIfFilesAreTooBig([value])
  )
  .test("fileFormat", "Only png/jpeg/jpg/gif files are allowed", (value) =>
    checkIfFilesAreCorrectType([value], ["png", "jpeg", "jpg", "gif"])
  )
  .required("Image is required");

const MultipleImageSchema = Yup.array()
  .of(Yup.mixed<File>())
  .test("fileSize", "File size must be less than 3MB", (value) =>
    checkIfFilesAreTooBig(value)
  )
  .test("fileFormat", "Only png/jpeg/jpg/gif files are allowed", (value) =>
    checkIfFilesAreCorrectType(value, ["png", "jpeg", "jpg", "gif"])
  )
  .required("Image is required");

export const EcommerceProductSchema = SEOSchema.shape({
  id: Yup.string().nullable(),
  brand: Yup.array().nullable(),
  product: Yup.array().required("Product is required"),
  product_name: Yup.string().required("Product Name is required"),
  // disclaimer: Yup.string().required("Disclaimer is required"),
  description: Yup.string().required("Description is required"),

  media: Yup.array()
    .of(
      Yup.object().shape({
        file: Yup.string(),
        alt: Yup.string().required("Alt Text is required"),
        priority: Yup.number().required("Priority is required"),
      })
    )
    .min(1, "Atleast One Image is required"),

  weight_variations: Yup.array()
    .of(
      Yup.object().shape({
        weight: Yup.number().required("Weight is required"),
      })
    )
    .min(1, "Atleast One Weight Variation is required"),

  is_active: Yup.array(),
});

export type TEcommerceProduct = Yup.InferType<typeof EcommerceProductSchema>;

export const EcommerceCategorySchema = SEOSchema.shape({
  id: Yup.string().nullable(),
  product_category: Yup.array().min(1, "Category is required"),
  icon: Yup.string().nullable(),
  icon_alt: Yup.string().nullable(),
  display_name: Yup.string().required("Display Name is required"),
  short_summary: Yup.string().nullable(),

  widgets: Yup.array().min(1, "Atleast One Widget is required"),

  is_active: Yup.array(),
});

export type TEcommerceCategory = Yup.InferType<typeof EcommerceCategorySchema>;

export const EcommerceSubCategorySchema = SEOSchema.shape({
  id: Yup.string().nullable(),
  product_sub_category: Yup.array().min(1, "Sub Category is required"),
  icon: Yup.string().nullable(),
  icon_alt: Yup.string().nullable(),
  display_name: Yup.string().required("Display Name is required"),
  short_summary: Yup.string().nullable(),

  widgets: Yup.array().min(1, "Atleast One Widget is required"),

  is_active: Yup.array(),
});

export type TEcommerceSubCategory = Yup.InferType<
  typeof EcommerceSubCategorySchema
>;

export const BlogSchema = SEOSchema.shape({
  blog_title: Yup.string().required("Blog title is required"),
  blog_category: Yup.array().required("Category is required"),
  blog_tags: Yup.array(),
  blog_content: Yup.string().required("Content is required"),
  blog_image: SingleImageSchema,
  // .test("fileFormat", "Only png/jpeg/jpg/gif files are allowed", (value) => {
  //   if (value) {
  //     const supportedFormats = ["png", "jpeg", "jpg", "gif"];
  //     return supportedFormats.includes(value.name.split(".").pop());
  //   }
  //   return true;
  // })
  // .test("fileSize", "File size must be less than 3MB", (value) => {
  //   if (value) {
  //     return value.size <= 3145728;
  //   }
  //   return true;
  // }),

  is_active: Yup.array(),
});

export type TBlog = Yup.InferType<typeof BlogSchema>;

export const TasteSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  taste_name: Yup.string().required("Taste Name is required"),
  is_active: Yup.array(),
});

export type TTaste = Yup.InferType<typeof TasteSchema>;

export const PagesSchema = SEOSchema.shape({
  id: Yup.string().nullable(),
  page_name: Yup.string().required("Page Name is required"),
  // header_image: SingleImageSchema,

  widgets: Yup.array().min(1, "Atleast One Widget is required"),

  is_active: Yup.array(),
});

export type TPage = Yup.InferType<typeof PagesSchema>;

export const TestimonialSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  name: Yup.string().required("Name is required"),
  // designation: Yup.string().nullable(),
  // company_name: Yup.string().nullable(),
  title: Yup.array().required("Title is required"),

  date: Yup.date().nullable(),
  priority: Yup.number().required("Priority is required"),
  review: Yup.string().required("Review is required"),
  // testimonial_image: Yup.mixed<File>().nullable(),
  // testimonial_description: Yup.string().nullable(),

  is_active: Yup.array(),
});

export type TTestimonial = Yup.InferType<typeof TestimonialSchema>;

export const MenuItemSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  menu: Yup.string().nullable(),
  mega_menu: Yup.string().nullable(),
  menu_type: Yup.array().nullable(),
  menu_item: Yup.array().nullable(),
  menu_item_title: Yup.string().required("Title is required"),
  url: Yup.string().required("URL is required"),
  parent: Yup.string().nullable(),
  priority: Yup.number().nullable(),
  is_mega_menu: Yup.array().nullable(),

  is_active: Yup.array(),
});

export const MenuSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  menu_title: Yup.string().required("Menu Title is required"),
  menu_items: Yup.array()
    .of(
      MenuItemSchema.shape({
        children: Yup.array().of(MenuItemSchema),
      })
    )
    .min(1, "Atleast One Menu Item is required"),
  is_active: Yup.array(),
});

export type TMenuItem = Yup.InferType<typeof MenuItemSchema>;
export type TMenu = Yup.InferType<typeof MenuSchema>;

export const FAQSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  question: Yup.string().required("Question is required"),
  // tags: Yup.array().nullable(),
  answer: Yup.string().required("Answer is required"),

  is_active: Yup.array(),
});

export type TFAQ = Yup.InferType<typeof FAQSchema>;

export const EmailTemplateSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  name: Yup.string().required("Template Name is required"),
  content: Yup.string().required("Content is required"),
  // type: Yup.array().nullable(),
  // sub_type: Yup.array().nullable(),

  is_active: Yup.array(),
});

export type TEmailTemplate = Yup.InferType<typeof EmailTemplateSchema>;

export const SiteDetailsSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  site_name: Yup.string().required("Site Name is required"),
  site_url: Yup.string().required("Site URL is required"),
  site_contact_number: Yup.number().required("Site Contact Number is required"),
  site_whatsapp_number: Yup.number().required(
    "Site Whatsapp Number is required"
  ),
  site_contact_email: Yup.string().email("Invalid email address"),

  icon: Yup.string().nullable(),
  favicon: Yup.string().nullable(),
  site_header_logo_link: Yup.string().nullable(),
  site_footer_logo_link: Yup.string().nullable(),

  publish_website: Yup.array().min(1, "Please select an option"),
  sitemap_enabled: Yup.array().min(1, "Please select an option"),
  follow_index_enabled: Yup.array().min(1, "Please select an option"),
  robots_enabled: Yup.array().min(1, "Please select an option"),
  front_page: Yup.array().required("Front Page is required"),
  menu_link: Yup.string().required("Please enter menu link"),

  address: Yup.string().required("Please enter address"),
  address_link: Yup.string().required("Please enter address link"),

  google_analytics: Yup.string().nullable(),
  facebook_pixel: Yup.string().nullable(),

  facebook: Yup.string().nullable(),
  twitter: Yup.string().nullable(),
  instagram: Yup.string().nullable(),
  youtube: Yup.string().nullable(),
});

export type TSiteDetails = Yup.InferType<typeof SiteDetailsSchema>;

export const SiteShippingSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  shipping_name: Yup.string().required("Shipping Name is required"),
  shipping_key: Yup.string().required("Shipping Key is required"),
});

export const ShippingDetailsSchema = Yup.object().shape({
  shipping_details: Yup.array()
    .of(SiteShippingSchema)
    .min(1, "Atleast One Shipping Details is required"),
});

export type TShippingDetails = Yup.InferType<typeof SiteShippingSchema>;

export const SitePaymentSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  payment_name: Yup.string().required("Shipping Name is required"),
  payment_key: Yup.string().required("Shipping Key is required"),
});

export const PaymentDetailsSchema = Yup.object().shape({
  payment_details: Yup.array()
    .of(SitePaymentSchema)
    .min(1, "Atleast One Payment Details is required"),
});

export type TPaymentDetails = Yup.InferType<typeof SitePaymentSchema>;

export const SiteSMSSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  sms_name: Yup.string().required("SMS Name is required"),
  sms_key: Yup.string().required("SMS Key is required"),
});

export const SMSDetailsSchema = Yup.object().shape({
  sms_details: Yup.array()
    .of(SiteSMSSchema)
    .min(1, "Atleast One SMS Details is required"),
});

export type TSMSDetails = Yup.InferType<typeof SiteSMSSchema>;

export const SiteEmailSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  email_username: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  email_password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .nullable(),
  email_config_type: Yup.array().min(
    1,
    "Please select config type is required"
  ),
  email_host: Yup.string(),
  email_port: Yup.number(),
});

export const EmailDetailsSchema = Yup.object().shape({
  email_settings: Yup.array()
    .of(SiteEmailSchema)
    .min(1, "Atleast One Email Details is required"),
});

export type TEmailDetails = Yup.InferType<typeof SiteEmailSchema>;

export const SiteMenuSchema = Yup.object().shape({
  header_menu: Yup.array().min(1, "Header Menu is required"),
  footer_menu: Yup.array().min(1, "Footer Menu is required"),
  // mega_menu: Yup.array().min(1, "Mega Menu is required"),
});

export const MenuDetailsSchema = Yup.object().shape({
  menu_details: SiteMenuSchema,
});

export type TMenuDetails = Yup.InferType<typeof SiteMenuSchema>;

export const SiteManagementSchema = (activeTab: string) => {
  switch (activeTab) {
    case SITE_MANAGEMENT_CONSTANTS.TABS[0]:
      return SiteDetailsSchema;
    case SITE_MANAGEMENT_CONSTANTS.TABS[1]:
      return EmailDetailsSchema;
    case SITE_MANAGEMENT_CONSTANTS.TABS[2]:
      return SMSDetailsSchema;
    case SITE_MANAGEMENT_CONSTANTS.TABS[3]:
      return PaymentDetailsSchema;
    case SITE_MANAGEMENT_CONSTANTS.TABS[4]:
      return ShippingDetailsSchema;
    case SITE_MANAGEMENT_CONSTANTS.TABS[5]:
      return MenuDetailsSchema;
  }
};

export const WidgetContentSchema = Yup.object().shape({
  widget: Yup.string().nullable(),
  widget_display_name: Yup.string().required("Widget Name is required"),
  properties: Yup.array(),
  is_active: Yup.array().required("Status is required"),
});

export const WidgetSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  widget_name: Yup.string().required("Widget Name is required"),
  widget_type: Yup.string().required("Widget Type is required"),
  widget_template: Yup.string().nullable(),
  configuration_options: Yup.string().required(
    "Configuration Options is required"
  ),
  description: Yup.string().nullable(),
  is_active: Yup.array().required("Status is required"),
});

export type TWidget = Yup.InferType<typeof WidgetSchema>;

export const UpdateEcommerceOrderSchema = Yup.object().shape({
  order_status: Yup.array().min(1, "Order Status is required"),
  order_notes: Yup.string().nullable(),
  shipping_carrier: Yup.string().nullable(),
  tracking_number: Yup.string().nullable(),
  shipping_date: Yup.string().nullable(),
  delivery_date: Yup.string().nullable(),
});

export const MasterPasswrodSchema = ({ otpSent }: { otpSent: boolean }) => {
  return Yup.object().shape({
    selected_employee: Yup.array().min(
      1,
      "Please select the employee to authorize"
    ),
    otp: otpSent
      ? Yup.string()
          .min(6, "OTP should be 6 digits")
          .max(6, "OTP should be 6 digits")
          .required("OTP is required")
      : Yup.number().nullable(),
  });
};
